import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Swiper} from "antd-mobile";
import FlexBox from "../../../commen/FlexBox";
import {getShopDetail} from "../../../services";
import {useNavigate, useParams} from "react-router";
import {useModal, useRefresh, useUserInfo} from "../../../hooks";
import NoExModal from "../giftExhange/components/NoExModal";
import NoAddressModal from "../giftExhange/components/NoAddressModal";
import ConfirmAddressModal from "../giftExhange/components/ConfirmAddressModal";
import {useLocation} from "react-router-dom";
import {getToken} from "../../../utils/token";
const Container = styled.div`
    background-color: #F6F6F6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5rem;
    position: relative;
	margin-top: 28px;
`
const InfoBox = styled.div`
    width: 18.5rem;
    background: #FFFFFF;
    border-radius: 0.21rem;
    padding: 1rem;
    margin-top: 1rem;
`

const Title = styled.div`
    font-size: 1.25rem;
    color: #030C29;
`
const ItemTitle = styled.div`
    font-size: 0.81rem;
    color: #091023;
`
const Tips = styled.div`
    height: 0.73rem;
    background: #F5F5F5;
    border-radius: 0.21rem;
    font-size: 0.5rem;
    color: #828388;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.125rem 0.25rem;
    margin-top: 0.5rem;
    min-width: 4rem;
    max-width: 6rem;
`
const Price = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
`
const GTitle = styled.div`
    font-size: 0.81rem;
    color: #828388;
`
const CoinIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
`
const ImgBox = styled.div`
    width: 18.5rem;
    background: #FFFFFF;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.25rem;
`
const DetailImg = styled.img`
    width: 20rem;
    margin-bottom: 0.125rem;
`

const BottomBox = styled.div`
    width: 22.5rem;
    height: 3.42rem;
    background: #FFFFFF;
    border: 0.19px solid #F3F3F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;

`
const KfIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`
const BgBtn = styled.div`
    width: 6rem;
    height: 2.25rem;
    background: #7A57EB;
    border: 0.19px solid #7A57EB;
    border-radius: 1.25rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 1rem;
	font-size:0.875rem;
`
const BackBtn = styled.img`
    width: 2rem;
    height: 2rem;
    opacity: 0.8;
    border-radius: 2rem;
    object-fit: cover;
    position: fixed;
    top: 2.65rem;
    left: 1rem;
    z-index: 9;
`

const ZXBtn = styled.div`
    width: 6rem;
    height: 2.25rem;
    background: #7A57EB;
    border: 0.19px solid #7A57EB;
    border-radius: 1.25rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 1rem;
    font-size:0.875rem;
`

const StoreDetails = () => {
	const [data, setData] = useState({})
	const {id} = useParams()
	const {setOpenModal, setModalContent, setPosition} = useModal()
	const {userInfo} = useUserInfo()
	const navigate = useNavigate()
	const {refresh,setRefresh} = useRefresh()
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const xkToken = searchParams.get('xkToken')
	useEffect(() => {
		getShopDetail({id: id,__token:xkToken?xkToken:getToken()}).then(res => {
			if (res.data.isSucc) {
				setData(res.data.res.item)
			}
		})
	}, [refresh])
	
	
	function exchange() {
		setPosition('center');
		setOpenModal(true);
		if (data.stock === 0) {
			return setModalContent(<NoExModal text={'库存不足,请等待补充'}/>)
		}
		if (userInfo.coin < data.price) {
			return setModalContent(<NoExModal text={'点卡数量不足，无法兑换该物品'}/>)
		}
		if (!userInfo.default_address) {
			return setModalContent(<NoAddressModal/>)
		}
		// if (userInfo.have_firstCover) {
		return setModalContent(<ConfirmAddressModal data={data}/>)
		// }
		// return setModalContent(<ExchangeModal data={data}/>)
	}
	
	const call = (phone) => {
		window.location.href = "tel:" + phone;
	}
	return (
		<Container>
			<BackBtn onClick={() => {
				navigate('/store',{replace:true})
			}} src={require('../../../assets/images/exchangelogs/wzz_btn_back.png')}/>
			
			<Swiper
				loop
				autoplay
				onIndexChange={i => {
				}}
			>
				{data.covers?.map((item, index) => (
					<Swiper.Item key={index}>
						<div style={{position: "relative", width: "22.5rem", height: "15rem", objectFit: "cover"}}>
							<img
								style={{width: "22.5rem", height: "15rem",objectFit:"cover"}}
								
								src={item.url}
								alt={''}
							/>
						</div>
					</Swiper.Item>
				))}
			</Swiper>
			{data.classType === 1 &&
			<InfoBox>
				<ItemTitle>{data.name}</ItemTitle>
				<FlexBox style={{justifyContent: "flex-start"}}>
					{data.tags?.map((item, index) => {
						return (
							<Tips style={{marginRight: "0.25rem"}} key={index}>{item}</Tips>
						)
					})}
				</FlexBox>
				
				<FlexBox style={{justifyContent: "flex-start", marginTop: "0.76rem"}}>
					<GTitle>兑换：</GTitle>
					<CoinIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
					<Price>{data.price}</Price>
				</FlexBox>
				{/*<FlexBox style={{justifyContent: "flex-start", marginTop: "0.52rem"}}>*/}
				{/*	<GTitle>购买：</GTitle>*/}
				{/*	<Price>¥ {data.rprice}</Price>*/}
				{/*</FlexBox>*/}
			</InfoBox>}
			{data.classType === 2 &&
			<InfoBox>
				<ItemTitle>{data.name}</ItemTitle>
				{data.lables?.map((item, index) => {
					return (
						<Tips key={index}>{item}</Tips>
					)
				})}
				{data.rprice !== 0 ? <Price>参考价格: ¥ {data.rprice}万+</Price>:<Price>暂无价格</Price>}
				
			</InfoBox>}
			{
				data.intos?.length > 0 &&
				<ImgBox>
					<div style={{fontSize: "0.62rem", color: "#828388", height: "2.18rem"}}>-- 详情 --</div>
					{data.intos?.map((item, index) => {
						return (
							<DetailImg src={item.url} key={index}/>
						)
					})}
				
				</ImgBox>
			}
			
			<BottomBox>
				{data.classType === 1 &&
				<div onClick={() => {
					call(18080821692)
				}} style={{marginLeft: "1rem"}}>
					<KfIcon src={require('../../../assets/images/exchangelogs/wzz_icon_service.png')}/>
					<div style={{fontSize: "0.5rem", color: "#828388"}}>客服</div>
				</div>}
				{
					data.classType === 2 &&
					<FlexBox style={{justifyContent: "flex-start", marginLeft: "1rem"}}>
						<KfIcon src={require('../../../assets/images/exchangelogs/wzz_icon_phone.png')}/>
						<div style={{marginLeft: "0.35rem"}}>
							<div style={{color: "#828388", fontSize: "0.5rem"}}>咨询电话：</div>
							<div style={{
								color: "#828388",
								fontSize: "0.68rem",
								marginTop: "0.125rem"
							}}>{data.phone}</div>
						</div>
					</FlexBox>
				}
				{data.classType === 1 &&
				<FlexBox style={{marginRight: "1rem"}}>
					<BgBtn onClick={exchange} color={'#7A57EB'}
					       bg={require('../../../assets/images/exchangelogs/wzz_btn_type_1.png')}>立即兑换</BgBtn>
					{/*<BgBtn color={'#FFFFFF'} style={{filter: 'grayscale(100%)'}}*/}
					{/*       bg={require('../../../assets/images/exchangelogs/wzz_btn_type_2.png')}>立即购买</BgBtn>*/}
				</FlexBox>}
				{data.classType === 2 && <ZXBtn onClick={() => {
					call(data.phone)
				}}>立即咨询</ZXBtn>}
			</BottomBox>
		</Container>
	);
};

export default StoreDetails;
