import React from 'react';
import styled from "styled-components";
import {copyText} from "../../../../utils";
import {useModal} from "../../../../hooks";

const Container = styled.div`
    width: 16.5rem;
	height: 15rem;
    background: #FFFFFF;
    border-radius: 0.875rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
    padding: 1.25rem;

`
const Title = styled.div`
    font-size: 1.25rem;
    color: #181818;
`
const LinkText = styled.div`
    font-size: 0.875rem;
    color: #181818;
	margin-top: 4.18rem;
`
const CopyBtn = styled.div`
    width: 16.13rem;
    height: 2.575rem;
    background: #7A57EB;
    border-radius: 1.25rem;
	font-size: 0.875rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin-top:5.25rem;
	:hover {
		filter: opacity(0.8);
	}
`
const ShareLinkModal = ({shareCode}) => {
	const {setOpenModal} =useModal()
    return (
        <Container>
	        <Title>分享链接</Title>
	        <LinkText>https://www.xktruck.com/index.html?shareCode={shareCode}</LinkText>
	        <CopyBtn onClick={() => {copyText('https://www.xktruck.com/index.html?shareCode='+shareCode);setOpenModal(false)}}>复制链接</CopyBtn>
        </Container>
    );
};

export default ShareLinkModal;
