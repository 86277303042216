import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useModal} from "../../../../hooks";

const Container = styled.div`
    background-color: #FFFFFF;
	width: 22.5rem;
`
const Item = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #F8F8F8;
	padding: 0.5rem 1rem;
`
const Text = styled.div`
    font-size: 0.93rem;
    color: #000000;
`
const CheckIcon = styled.img`
	width: 0.94rem;
	height: 0.65rem;
`
const FilterModal = ({callback,defaultType}) => {
	const filterData = ['全部','收入','消费']
	const [selectIndex,setSelectIndex] = useState(defaultType?defaultType:0)
	const {openModal} = useModal()
	useEffect(() => {
		callback(selectIndex)
	},[openModal])
    return (
        <Container>
	        {filterData.map((item,index) => {
				return (
					<Item onClick={() => {setSelectIndex(index);}}  key={index}>
						<Text>{item}</Text>
						{selectIndex === index && <CheckIcon src={require('../../../../assets/images/mine/gr_icon_extend_select.png')}/>}
						
					</Item>
				)
	        })}
        </Container>
    );
};

export default FilterModal;

