import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import AddItem from "./components/AddItem";
import FlexBox from "../../../commen/FlexBox";
import {useNavigate} from "react-router";
import {getAddressList} from "../../../services";

const Container = styled.div`
    margin: 0 1rem;
    padding-top:  5.4rem;
`
const AddBtn = styled.div`
    margin: 0 1rem;
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 18.043rem;
    height: 2.583rem;
    border-radius: 1.3rem;
    font-size: 0.93rem;
    text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 1.813rem;
`
const BtnIcon = styled.img`
	width: 0.73rem;
	height: 0.73rem;
`

const Address = () => {
	const navigate = useNavigate()
	const [list,setList] = useState([])
	useEffect(() => {
		getAddressList().then(res => {
			if (res.data.isSucc){
				
				let resList = res.data.res.list.sort((a, b) => {
					if (a.isDefault && !b.isDefault) {
						return -1; // a排在前面
					} else if (!a.isDefault && b.isDefault) {
						return 1; // b排在前面
					} else {
						return 0; // 保持原来的顺序
					}
				});
				setList(resList)
			}
		})
	},[])
    return (
        <Container>
	        {list.map((item,index) => {
				return (
					<AddItem key={index}  data={item} onClick={() => navigate('/mine/edit_address',{state:{data:item}})}/>
				)
	        })}
	        <FlexBox onClick={() => navigate('/mine/edit_address',{state:{data:null}})}>
		        <AddBtn>
			        <BtnIcon src={require('../../../assets/images/mine/gr_btn_add_location.png')}/>
			        <div style={{marginLeft:"0.52rem"}}>新增收货地址</div>
		        </AddBtn>
	        </FlexBox>
	        
        </Container>
    );
};

export default Address;
