import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useNavigate} from "react-router";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const ItemContainer = styled.div`
	background-color: #FFFFFF;
	padding: 1rem 1.5rem;
	height: 2.5rem;
	display: flex;
	width: 20.5rem;
`
const ItemIcon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
`
const ItemText = styled.div`
	font-size: 0.875rem;
	color: #181818;
	margin-left: 1.5rem;
`
const ArrowIcon = styled.img`
	width: 0.33rem;
	height: 0.58rem;
`
const MessageBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
    max-width: 1.72rem;
	min-width: 1rem;
	height: 1rem;
	background-color: #E43C3C;
	color: #FFFFFF;
	font-size: 0.75rem;
	border-radius: 1rem;
	padding:0 0.125rem;
	margin-right: 0.5rem;
`
const Message = () => {
	const navigate = useNavigate()
    return (
        <Container>
	        <ItemContainer  onClick={() => navigate('/mine/message_details',{state:{type:0}})}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_zan.png")}/>
				        <ItemText>收到的赞</ItemText>
			        </FlexBox>
			        <FlexBox style={{justifyContent:'flex-end'}}>
				        <MessageBox>5</MessageBox>
				        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
			        </FlexBox>
		        </FlexBox>
	        </ItemContainer>
	        <ItemContainer style={{borderTop:"1px solid #F5F5F5"}}  onClick={() => navigate('/mine/message_details',{state:{type:1}})}>
		        <FlexBox style={{justifyContent:"space-between",flex:1,}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_huifu.png")}/>
				        <ItemText>回复我的</ItemText>
			        </FlexBox>
			        <FlexBox style={{justifyContent:'flex-end'}}>
				        <MessageBox>5</MessageBox>
				        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
			        </FlexBox>
		        </FlexBox>
	        </ItemContainer>
        </Container>
    );
};

export default Message;
