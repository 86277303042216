import React, {useRef} from 'react';
import styled from "styled-components";
import {copyText} from "../../../../utils";
import {useModal} from "../../../../hooks";
import SharePosterImg from "../../../../assets/images/mine/gr_img_share_photo.png"
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import upload from "../../../../utils/upload";
import {Toast} from "antd-mobile";
const Container = styled.div`
    width: 16.79rem;
	height: 24.436rem;
    background: #FFFFFF;
    border-radius: 0.875rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
    padding: 1rem;
`
const Poster = styled.div`
    width: 16.79rem;
    height: 20.793rem;
	background-image: url(${SharePosterImg});
	background-size: cover;
    position: relative;

`
const Title = styled.div`
    font-size: 1.25rem;
    color: #181818;
`
const LinkText = styled.div`
    font-size: 0.5rem;
    color: #FFFFFF;
	position: absolute;
	left: 1.35rem;
	bottom: 0.75rem;
`
const CopyBtn = styled.div`
    width: 16.13rem;
    height: 2.575rem;
    background: #7A57EB;
    border-radius: 1.25rem;
	font-size: 0.875rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin-top:1.25rem;
	:hover {
		filter: opacity(0.8);
	}
`
const SharePosterModal = ({shareCode}) => {
	const {setOpenModal} =useModal()
	const posterRef = useRef()
	const handleSavePoster = () => {
		Toast.show({icon:'loading',content:"正在生成海报..."})
		html2canvas(posterRef.current).then((canvas) => {
			canvas.toBlob(async (blob) => {
				// 创建一个File对象
				const file = new File([blob], 'xk_app_share_poster.png', { type: 'image/png' });
				const res =  await upload(file,() => {},'img',)
				setOpenModal(false)
				Toast.show({icon:'success',content:"成功生成海报，等待保存到相册，请稍后到相册查看"})
				window.location.href = "uniwebview://saveToAlbum?url=" + res.url
			}, 'image/png');
		});
	};
	return (
		<Container>
			<Poster ref={posterRef}>
				<QRCode style={{position:"absolute",bottom:"2.125rem",left:"1.7rem",width:"3.75rem",height:"3.75rem"}}
				        value={'https://www.xktruck.com/index.html?shareCode='+shareCode} />
				<LinkText>邀请码: {shareCode}</LinkText>
			</Poster>
			
			<CopyBtn onClick={handleSavePoster}>保存海报</CopyBtn>
		</Container>
	);
};

export default SharePosterModal;
