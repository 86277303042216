import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import ELItem from "./components/ELItem";
import {exChangeLog} from "../../../services";
import CheckTab from "../../community/forum/components/CheckTab";
import {useWindowSize} from "../../../utils/useWindowSize";
import TuckTab from "../tuckHome/components/TuckTab";
import {useRefresh} from "../../../hooks";


const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 6.5rem;
    background-color: #F8F8F8;
    padding-bottom: 3rem;
`
const Btn = styled.div`
    background-color: #FFFFFF;
    width: 6.5rem;
    height: 1.793rem;
    color: ${props => props.state ? "#003541" : "#30D2D7"};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
`

const ExchangeLogs = () => {
	const [list, setList] = useState([])
	const [selectIndex, setSelectIndex] = useState(0)
	const {height} = useWindowSize()
	const {refresh, setRefresh} = useRefresh()
	useEffect(() => {
		exChangeLog({filterType: selectIndex}).then(res => {
			if (res.data.isSucc) {
				setList(res.data.res.list)
			}
		})
	}, [selectIndex,refresh])
	const checkData = ['全部', '未寄送', '已寄送','退款']
	
	return (
		<Container style={{minHeight: height - 60}}>
			<TuckTab defaultIndex={selectIndex} data={checkData} style={{
				position: "fixed",
				top: "3rem",
				justifyContent: "space-between",
				left: 0,
				padding: "0 3.25rem",
				width: "16rem",
				marginTop:28
			}} callback={(index) => {
				setSelectIndex(index);
			}}/>
			
			<div style={{marginTop: "1.25rem"}}>
				{
					list.map((item, index) => {
						return (
							<ELItem  data={item} key={index}/>
						)
					})
				}
			</div>
		
		</Container>
	);
};

export default ExchangeLogs;
