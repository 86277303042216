import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavBtn, useRefresh, useUserInfo} from "../../../hooks";
import {editTuckInfo, editUserInfo, getUserInfo} from "../../../services";
import {Toast} from "antd-mobile";
import {useNavigate} from "react-router";
import {validateDrivingLicenseNo} from "../../../utils";

const Container = styled.div`
    padding-top:  5.4rem;
`
const ItemContainer = styled.div`
	background-color: #FFFFFF;
	padding: 0.5rem 1.04rem;
	height: 2.5rem;
	display: flex;
	width: 20.5rem;
	position: relative;
`
const Input = styled.input`
    background-color: #FFFFFF;
	border: none;
	outline: none;
	font-size: 1rem;
	flex: 1;
`
const Count = styled.div`
    font-size: 0.88rem;
    color: #AAAAAA;
	position: absolute;
	bottom: -1.5rem;
	right: 1rem;
`
const Btn = styled.div`
    font-size: 0.81rem;
    color: #7A57EB;
`
const ChangeDriversLicense = () => {
	const {setNavBarBtn} = useNavBtn()
	const navigate = useNavigate()
	const {userInfo} = useUserInfo()
	const {refresh,setRefresh} = useRefresh()
	const [vehicle,setVehicle] = useState({})
	const [value,setValue] = useState('')
	
	useEffect(() => {
		let v= userInfo.vehicle
		v.version = userInfo.version
		setVehicle(v)
		setValue(userInfo.vehicle.vehicle_driverlicense)
	},[userInfo,refresh])
	useEffect(() => {
		setNavBarBtn({color:"#7A57EB",text:'保存',action:editDriverLicense})
		return () => {
			setNavBarBtn(null)
		}
	},[value])
	
	const editDriverLicense = () => {
		if (value === ''){
			return Toast.show({icon:'fail',content:'请输入你的驾驶证档案编号'})
		}
		if (!validateDrivingLicenseNo(value)){
			return Toast.show({icon:'fail',content:'请检查你的驾驶证档案编号'})
		}
		if (vehicle.vehicle_driverlicense === value){
			return navigate(-1)
		}
		editTuckInfo({info:{...vehicle,vehicle_driverlicense:value}}).then(res => {
			if (res.data.isSucc){
				setRefresh(!refresh)
				Toast.show({icon:'success',content:'修改成功'})
				navigate(-1)
			}else {
				if (res.data.err.code === '50017'){
					setRefresh(!refresh)
					return Toast.show({icon:'fail',content:'您的信息已过期,请重试'})
				}
				Toast.show({icon:'fail',content:JSON.stringify(res.data.err)})
			}
		})
	}
	
	return (
		<Container>
			<ItemContainer>
				<Input maxLength={12} value={value} onChange={(e) => setValue(e.target.value)} placeholder={'请输入你的驾驶证档案编号'} type={'text'}/>
				<Count>{`${value.length}/12`}</Count>
			</ItemContainer>
		</Container>
	);
};

export default ChangeDriversLicense;
