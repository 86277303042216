import React from 'react';
import styled from "styled-components";
import Bg from "../../../../assets/images/exchangelogs/wzz_bg_exchange_over.png"
import FlexBox from "../../../../commen/FlexBox";
import {useModal} from "../../../../hooks";


const Container = styled.div`
    width: 18.793rem;
    height: 21.936rem;
    background-image: url(${Bg});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Tip = styled.div`
    font-size: 0.876rem;
    color: #011012;
    position: absolute;
    top: 1.575rem;
    left: 2.75rem;
`

const Btn = styled.div`
    width: 10.98rem;
    height: 2.583rem;
    background: rgba(18, 30, 52, 0);
    border: 0.19px solid #FFFFFF;
    border-radius: 1.3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #FFFFFF
`

const CarBg = styled.div`
    width: 10.283rem;
    height: 6.98rem;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 3.33rem;
    border-radius: 0.42rem;
`
const CarImage = styled.img`
    width: 9.383rem;
    height: 6.31rem;
`
const Text = styled.div`
    font-size: 0.875rem;
    color: #FFFFFF;
    max-width: 8rem;
    word-break: break-all;
    word-wrap: break-word;
    text-align: center;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical;
`

const SuccessModal = ({data}) => {
	const {setOpenModal} = useModal()
	return (
		<Container>
			<CarBg>
				<CarImage src={data.icon}/>
			</CarBg>
			<Text style={{marginTop: "0.7rem"}}>{data.name}</Text>
			<FlexBox style={{justifyContent: "center", marginTop: '1.75rem'}}>
				<Btn onClick={() => {
					setOpenModal(false)
				}}>{"确认"}</Btn>
			</FlexBox>
		</Container>
	);
};

export default SuccessModal;
