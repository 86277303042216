import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Image, ImageViewer, Toast} from 'antd-mobile';
import {useLocation, useNavigate} from 'react-router';
import FlexBox from '../../../../commen/FlexBox';
import {formatTimeText, generateRandomNumber, getStrUrl, getVideoCover, getVideoSrc,} from '../../../../utils';
import styled from "styled-components";
import PlayIcon from "../../../../assets/images/base/play.png";
import Zan1 from "../../../../assets/images/forum/lt_icon_zan_1.png"
import Zan2 from "../../../../assets/images/forum/lt_icon_zan_2.png"
import CommentIcon from "../../../../assets/images/forum/lt_icon_pinglun.png"
import {like, searchTopic, unLike} from "../../../../services/forum";
import {useUserInfo} from "../../../../hooks";
import DefaultAvatar from "../../../../assets/images/mine/gr_icon_head_default.png";
import {useWindowSize} from "../../../../utils/useWindowSize";

const Container = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #030C29;
    padding-bottom: 1.125rem;
    border-bottom: 1px solid #F5F5F5;
    position: relative;
`
const Name = styled.div`
    font-size: 0.75rem;
    margin-left: 0.5rem;
    color: #000000;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-height: 1.25rem;
    display: -webkit-box; //特别显示模式
`

const Time = styled.div`
    font-size: 0.625rem;
    margin-top: 0.125rem;
    margin-left: 0.5rem;
    color: #828388;
`

const Title = styled.div`
    font-size: 1.125rem;
    margin-top: 1rem;
    font-weight: 600;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-height: 1.5rem;
    word-break: break-all;
    word-wrap: break-word;
`
const Text = styled.div`
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: ${props => props.lineclamp}; //行数
    line-clamp: ${props => props.lineclamp};
    -webkit-box-orient: vertical;
    color: #3F4456;
    font-size: 0.875rem;
	max-height: 2rem;
    word-break: break-all;
    word-wrap: break-word;
`
const TopicTip = styled.div`
    font-size: 1rem;
    color: #7A57EB;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 10rem;
	margin-top: 0.5rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem;
    word-break: break-all;
    word-wrap: break-word;
`
const CommentImg = styled.img`
    width: 1.19rem;
    height: 1.065rem;
`
const CountText = styled.div`
    font-size: 0.63rem;
    color: #828388;
    margin-left: 0.25rem;
    min-width: 0.5rem;
`
const PlayImg = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    position: absolute;
    top: 4rem;
    left: 9rem;
    background-color: #FFFFFF;
    z-index: 6;
`

const VoteContainer = styled.div`
    width: 17.12rem;
    background: #F4F6F9;
    border-radius: 0.63rem;
    padding: 1rem;
    position: relative;
`
const VoteItem = styled.div`
    background: #F7F7F7;
    border: 0.19px solid #E1E4E8;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    color: #3F4456;
    font-size: 0.75rem;
`
const VoteTitle = styled.div`
    font-size: 1rem;
    color: #3F4456;
`
const VoteType = styled.div`
    margin-top: 0.25rem;
    font-size: 0.625rem;
    color: #3F4456;
`

const ForumItem = ({data, type, topsId}) => {
	let navigate = useNavigate();
	const {userInfo} = useUserInfo()
	const videoRef = useRef(null)
	const [showPlayIcon, setShowPlayIcon] = useState(videoRef.current?.paused?videoRef.current?.paused:true)
	const [isLike, setIsLike] = useState(data.like_me)
	const [likeCount, setLikeCount] = useState(data.like_num)
	const [showImg, setShowImg] = useState(false)
	const imgViewerRef = useRef(null)
	const {pathname} = useLocation()
	const {height} = useWindowSize()
	useEffect(() => {
		if (data.like_num) {
			setLikeCount(data.like_num)
			setIsLike(data.like_me)
		}
		
	}, [data])
	
	useEffect(() => {
		if (videoRef.current){
			const isPaused = videoRef.current.paused
			setShowPlayIcon(isPaused)
			
		}
	},[videoRef.current,showPlayIcon])
	function zan(data) {
		let count = likeCount
		if (isLike) {
			unLike({id: data.id}).then(res => {
				if (res.data.isSucc) {
					setLikeCount(count - 1)
					setIsLike(!isLike)
				} else {
					Toast.show({icon: 'error', content: res.data.err,})
				}
			})
		} else {
			like({repe_id: data.id}).then(res => {
				if (res.data.isSucc) {
					setLikeCount(count + 1)
					setIsLike(!isLike)
				} else {
					Toast.show({icon: 'error', content: res.data.err,})
				}
			})
		}
		
		
	}
	const videoId = 'video' + generateRandomNumber()
	const playVideo = () => {
		const videoDom = document.getElementById(videoId)
		videoDom.addEventListener('ended', handleVideoEnded);
		videoDom.addEventListener('pause', handleVideoPause); // 添加暂停事件监听
		videoDom.play()
		setShowPlayIcon(false)
	}
	
	const pauseVideo = () => {
		const videoDom = document.getElementById(videoId)
		videoDom.pause()
		setShowPlayIcon(true)
	}
	
	const handleVideoPause = () => {
		setShowPlayIcon(true)
		// 在此处编写其他暂停事件处理代码
	};
	const handleVideoEnded = () => {
		setShowPlayIcon(true)
	}
	
	
	
	function getTotalNum(data) {
		let newArr = []
		for (let i = 0;i<data.length;i++ ){
			let arr = [...data[i].ids]
			newArr.push(arr)
		}
		let flattenedList = newArr.reduce((acc, val) => acc.concat(val), []);
		let uniqueList = [...new Set(flattenedList)];
		return uniqueList.length
	}
	const toVote = async (item) => {
		if (pathname.includes('vote_detail')){
			return;
		}
		if (type==='fromVote'){
			navigate(-1)
		}else {
			let res = await  searchTopic({key: item})
			if (res.data.res.max.title !== item) {
				return Toast.show({icon:'fail',content:"该话题已被删除"})
			}
			navigate('/community/vote_detail/' + item);
		}
	}
	return (
		<Container onClick={(e) => {
			navigate('/community/detail/' + data.id + '/?type=' + (pathname.split('/').includes('vote_detail')?'fromVote':'fromHome') + '&isTopPost=' + (topsId?.includes(data.id)  ?'1':'0'));
			e.stopPropagation()
		}}>
			
			{showImg && <div style={{position:"fixed",backgroundColor:"#000000",inset:0,height:height,zIndex:999}}/>}
			<ImageViewer.Multi
				ref={imgViewerRef}
				images={data.images}
				visible={showImg}
				defaultIndex={1}
				onClose={() => {
					setShowImg(false)
				}}
			/>
			<FlexBox style={{justifyContent: 'flex-start', paddingBottom: '0.5rem',alignItems:"flex-start"}}>
				<Avatar src={(data.head !== 'test' && data.head !== '') ? data.head : DefaultAvatar}
				        style={{'--border-radius': '2rem', '--size': '2rem'}}/>
				<div style={{flex:1}}>
					<Name>{data.author}</Name>
					<Time>{formatTimeText(data.create_time)}</Time>
				</div>
				{topsId && topsId?.includes(data.id) && <div style={{ color: "#7A57EB",marginLeft:"0.5rem"}}>[置顶帖子]</div>}
			</FlexBox>
			<Title>{data.title}</Title>
			{
				data.tags?.map((item, index) =>{
						return (
							<TopicTip onClick={ (e) => {
								e.stopPropagation()
								toVote(item)
							}} key={index}><div># {item}</div></TopicTip>
						)
					
					
				}
				
				)
			}
			{
				(getVideoSrc(data.content).length === 0 && data.images.length === 0 && !data.selectInfo) &&
				<Text lineclamp={1}
				      dangerouslySetInnerHTML={{__html: data.content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}}/>
				
			}
			
			
			{
				getVideoSrc(data.content).length > 0 &&
				<FlexBox onClick={(e) => {if (!showPlayIcon){
					pauseVideo();e.stopPropagation()
				}
				}} style={{position: "relative", width: "19.5rem", borderRadius: "0.75rem", marginTop: "1rem"}}>
					{showPlayIcon && <PlayImg onClick={(e) => {
						playVideo();
						e.stopPropagation()
					}} src={PlayIcon}/>}
					<video ref={videoRef} id={videoId} poster={getVideoCover(data.content)} src={getVideoSrc(data.content)[0]}
					       style={{width: "auto", height: "10rem", borderRadius: "0.75rem",}}/>
				</FlexBox>
				
			}
			{
				getVideoSrc(data.content).length === 0 &&
				<div>
					{
						getStrUrl(data.content).length === 1 && <div>
							{getStrUrl(data.content).map((item, index) => {
								return (
									<Image onClick={(e) => {
										imgViewerRef.current.swipeTo(index);
										setShowImg(true);
										e.stopPropagation()
									}} style={{
										borderRadius: '0.5rem',
										marginTop: '1rem',
										width: "13.543rem",
										height: "9.083rem"
									}} src={item} fit='cover' key={index}/>
								)
							})}
						</div>
					}
					{
						getStrUrl(data.content).length > 1 && <FlexBox style={{justifyContent: "flex-start", marginTop: '1rem',}}>
							{getStrUrl(data.content).map((item, index) => {
								if (index < 3) {
									return (
										<Image onClick={(e) => {
											imgViewerRef.current.swipeTo(index);
											setShowImg(true);
											e.stopPropagation()
										}} style={{
											borderRadius: '0.5rem',
											width: "6.371rem",
											height: "4.274rem",
											marginLeft: index > 0 ? "0.375rem" : 0
										}} src={item} fit='fill' key={index}/>
									)
								}
							})}
						</FlexBox>
					}
				</div>
			}
			{
				(getVideoSrc(data.content).length === 0 && data.images.length === 0 && data.selectInfo) &&
				<div>
					<VoteContainer style={{marginTop: "0.5rem"}}>
						<VoteTitle>{data.selectInfo.title}</VoteTitle>
						<VoteType>{data.selectInfo.optionType === 0 ? '单选' : '多选'} {getTotalNum(data.selectInfo.select)}人参与</VoteType>
						{data.selectInfo.select?.map((item, index) => {
							return (
								<VoteItem onClick={() => {
								
								}} key={index}>
									<div style={{
										position: 'absolute',
										left: "1rem",
										zIndex: 2,
										backgroundColor: item.ids.includes(userInfo.uuid) ? "#B5B5FF" : (data.selectInfo.selectids?.includes(userInfo.uuid) ? "#B3B3B3" : "#F7F7F7"),
										width: `${17.12 * (item.num / getTotalNum(data.selectInfo.select))}rem`,
										height: "2rem",
										borderRadius: "0.75rem"
									}}/>
									<FlexBox style={{zIndex: 5, width: "17.12rem", justifyContent: "space-between"}}>
										<div>{item.name}{item.ids.includes(userInfo.uuid) ? '\t(您已投票)' : ''}</div>
										{data.selectInfo.selectids?.includes(userInfo.uuid) && <div>{item.num}票</div>}
									</FlexBox>
								
								</VoteItem>
							
							
							)
						})}
					</VoteContainer>
				</div>
			}
			<FlexBox style={{justifyContent: "flex-end", marginTop: '1rem'}}>
				<FlexBox onClick={(e) => {
					navigate('/community/detail/' + data.id + '/?type='+ (pathname.split('/').includes('vote_detail')?'fromVote':'fromHome')+ '&isTopPost=' + (topsId === data.id ?'1':'0') );
					e.stopPropagation()
				}}>
					<CommentImg src={CommentIcon}/>
					<CountText>{data.comment_num}</CountText>
				</FlexBox>
				<FlexBox onClick={(e) => {
					zan(data);
					e.stopPropagation()
				}} style={{marginLeft: "1rem"}}>
					<CommentImg src={isLike ? Zan2 : Zan1}/>
					<CountText>{likeCount}</CountText>
				</FlexBox>
			</FlexBox>
			
			{(type === 1 && data.selectInfo) &&
			<div style={{backgroundColor: "#4E545E", borderRadius: "0.75rem", padding: "0.5rem", marginTop: "1rem"}}>
				<FlexBox style={{justifyContent: "space-between"}}>
					<div style={{width: "0.75rem", height: "1.25rem"}}/>
					<div style={{fontSize: "1rem", color: "#FFFFFF"}}>{data?.title}</div>
					
					<FlexBox onClick={(e) => {
						navigate('/detail/' + data.id, {state: {type: 1}});
						e.stopPropagation()
					}}>
						<div style={{fontSize: "1rem", color: "#6da9f3", marginRight: '0.25rem'}}>详情</div>
						<img style={{width: "0.625rem", height: "1rem"}}
						     src={require('../../../../assets/images/forum/blue_arrow.png')} alt=''/>
					</FlexBox>
				</FlexBox>
			
			
			</div>}
		
		
		</Container>
	);
};

export default ForumItem;
