import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavBtn, useRefresh, useUserInfo} from "../../../hooks";
import {editUserInfo} from "../../../services";
import {Toast} from "antd-mobile";
import {useNavigate} from "react-router";
import {checkProhibition, checkProhibitions} from "../../../utils";

const Container = styled.div`
    padding-top:  5.4rem;
`
const ItemContainer = styled.div`
	background-color: #FFFFFF;
	padding: 0.5rem 1.04rem;
	height: 2.5rem;
	display: flex;
	width: 20.5rem;
	position: relative;
`
const Input = styled.input`
    background-color: #FFFFFF;
	border: none;
	outline: none;
	font-size: 1rem;
	flex: 1;
`
const Count = styled.div`
    font-size: 0.88rem;
    color: #AAAAAA;
	position: absolute;
	bottom: -1.5rem;
	right: 1rem;
`
const Btn = styled.div`
    font-size: 0.81rem;
    color: #7A57EB;
`
const ChangeNickname = () => {
	const {setNavBarBtn} = useNavBtn()
	const {userInfo} = useUserInfo()
	const {refresh,setRefresh} = useRefresh()
	const [value,setValue] = useState('')
	const navigate = useNavigate()
	useEffect(() => {
		setValue(userInfo.name)
	},[userInfo,refresh])
	useEffect(() => {
		setNavBarBtn({color:"#7A57EB",text:'保存',action:editNickname})
		return () => {
			setNavBarBtn(null)
		}
	},[value])
	const editNickname = async () => {
		if (value === ''){
			return Toast.show({icon:'fail',content:'请输入你的昵称'})
		}
		let res = await checkProhibition(value)
		if (res.length > 0) {
			return Toast.show({icon:'fail',content:'修改失败,昵称带有违禁词'})
		}
		if (userInfo.name === value){
			return navigate(-1)
		}
		editUserInfo({info:{...userInfo,name:value}}).then(res => {
			if (res.data.isSucc){
				setRefresh(!refresh)
				Toast.show({icon:'success',content:'修改成功'})
				navigate(-1)
			}else {
				if (res.data.err.code === '50017'){
					setRefresh(!refresh)
					return Toast.show({icon:'fail',content:'您的信息已过期,请重试'})
				}
				Toast.show({icon:'fail',content:JSON.stringify(res.data.err)})
			}
		})
	}
	
    return (
        <Container>
	        <ItemContainer>
		        <Input maxLength={12} value={value} onChange={(e) => setValue(e.target.value)} placeholder={'请输入你的昵称'} type={'text'}/>
		        <Count>{`${value.length}/12`}</Count>
	        </ItemContainer>
        </Container>
    );
};

export default ChangeNickname;
