import uploadFile from '../../../utils/upload';
import {Form, ImageUploader, Input, Toast} from 'antd-mobile'
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useWindowSize} from '../../../utils/useWindowSize';
import FlexBox from '../../../commen/FlexBox';
import {addTopic, forumPost, passCreate} from "../../../services/forum";
import styled from "styled-components";
import Editor from "./components/Editor";
import AddVoteModal from "./components/AddVoteModal";
import {useIsPermission, useModal, useUserInfo} from "../../../hooks";
import DeleteImg from '../../../assets/images/forum/lt_icon_post_detele_toupiao.png'
import SelectTopicModal from "./components/SelectTopicModal";
import {checkProhibitions, throttling} from "../../../utils";
import {useLocation, useSearchParams} from "react-router-dom";
import {debounce} from "lodash";

const Btn = styled.div`
    margin: 0 1rem;
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 18.043rem;
    height: 2.583rem;
    border-radius: 1.3rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	cursor: pointer;
`
// let contentText;
// let imgIndex = []
const TopicBtn = styled.div`
    background-color: ${props => props.bgcolor};
    padding: 0.13rem 0.45rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: ${props => props.color};
    border-radius: 0.62rem;
    font-size: 0.75rem;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 2.75rem;
`
const TopicIcon = styled.img`
    width: 0.77rem;
    height: 0.75rem;
    margin-right: 0.33rem;
`
const DeleteIcon = styled.img`
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.82rem;
`
const CheckImg = styled.img`
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.875rem;
`
const CheckText = styled.div`
    font-size: 0.875rem;
    color: #3F4456;
    margin-left: 0.33rem;
`
const VoteBtn = styled.img`
    width: 2.04rem;
    height: 1.66rem;
`
const VoteContainer = styled.div`
    width: 17.12rem;
    background: #F4F6F9;
    border-radius: 0.63rem;
    padding: 1rem;
    position: relative;
`
const VoteItem = styled.div`
    background: #F7F7F7;
    border: 0.19px solid #E1E4E8;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-top: 0.3rem;
    color: #3F4456;
    font-size: 0.75rem;

`
const VoteTitle = styled.div`
    font-size: 1rem;
    color: #3F4456;
`
const VoteType = styled.div`
    margin-top: 0.25rem;
    font-size: 0.625rem;
    color: #3F4456;
`
const RemoveIcon = styled.img`
    width: 0.875rem;
    height: 0.875rem;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
`

const TopicText = styled.div`
    font-size: 0.75rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
`

const Topic = () => {
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const [fileList, setFileList] = useState([]);
	const [poster,setPoster] = useState([])
	const {width, height} = useWindowSize()
	const {setPosition, setModalContent, setOpenModal} = useModal()
	const [voteInfo, setVoteInfo] = useState({})
	const location = useLocation();
	const imgUpRef = useRef(null)
	const searchParams = new URLSearchParams(location.search);
	const tip = searchParams.get('tip')
	const [selectTopic, setSelectTopic] = useState(tip?[tip]:[])
	const navigate = useNavigate()
	const {isPermission,setIsPermission} = useIsPermission()
	const [clicked, setClicked] = useState(false);
	const params = useParams()
	const {userInfo} = useUserInfo()
	
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const submit = async () => {
		if (title === '') {
			setClicked(false)
			return Toast.show({
				icon: 'fail',
				content: '标题或话题名不能为空',
			})
		}
		if (params.type === 'vote' && title.includes('#') ){
			setClicked(false)
			return Toast.show({
				icon: 'fail',
				content: '话题名不能包含#号',
			})
		}
		if (title.length < 1) {
			setClicked(false)
			return Toast.show({
				icon: 'fail',
				content: '标题或话题名不少于1个字符',
			})
		}
		if (params.type === 'vote' && title.length > 20) {
			setClicked(false)
			return Toast.show({
				icon: 'fail',
				content: '话题名不超过20个字符',
			})
		}
		let res = await checkProhibitions(title)
		if (res.length > 0 && userInfo.server_leve === 0 ) {
			setClicked(false)
			return Toast.show({icon: 'fail', content: '提交失败,标题带有违禁词'})
		}
		
		if (content === '') {
			setClicked(false)
			return Toast.show({
				icon: 'fail',
				content: '内容不能为空',
			})
		}
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, 'text/html');
		
		doc.querySelectorAll('img, video').forEach((el) => {
			el.remove();
		});
		
		const result = doc.documentElement.outerHTML;
		
		let r = await checkProhibitions(result + '123').catch(err => {
			setClicked(false)
		})
		if (r !== undefined && r.length > 0 && userInfo.server_leve === 0) {
			setClicked(false)
			return Toast.show({icon: 'fail', content: '提交失败,内容带有违禁词'})
		}
		if (params.type === 'vote') {
			setClicked(false)
			if (poster.length === 0) {
				return Toast.show({
					icon: 'fail',
					content: '请上传封面',
				})
			}
			Toast.show({
				icon: 'loading',
				content: '发布中...',
				duration: 5000
			})
			addTopic({title: title, banner: poster[0].url, content: content}).then(res => {
				if (res.data.isSucc) {
					setTitle('')
					setContent('')
					setFileList([])
					Toast.show({
						icon: 'success',
						content: '发布成功',
					})
					navigate(-1)
				} else {
					setClicked(false)
					Toast.show({
						icon: 'fail',
						content: res.data.err.message,
					})
				}
			})
		} else if (params.type === 'post') {
			let req;
			if (Object.keys(voteInfo).length > 0) {
				req = {
					type: 0,
					title: title,
					content: content,
					images: fileList,
					selectModel: voteInfo,
					tags: selectTopic
				}
			} else {
				req = {type: 0, title: title, content: content, images: fileList, tags: selectTopic}
			}
			setTimeout(() => {
				Toast.show({
					icon: 'loading',
					content: '发布中...',
					duration: 5000
				})
			
				forumPost(req).then(res => {
					if (res.data.isSucc) {
						setTitle('')
						setContent('')
						localStorage.removeItem('list')
						localStorage.removeItem('page')
						localStorage.removeItem('total')
						Toast.show({
							icon: 'success',
							content: '发布成功',
						})
						navigate('/community', {state: {goTop: true, tIndex: 4}, replace: true})
						passCreate({id: res.data.res.id})
					} else {
						setClicked(false)
						Toast.show({
							icon: 'fail',
							content: res.data.err.message,
						})
					}
				})
			}, 100)
		}
		
		
		
	}
	const handleClick = debounce(() => {
		// 处理按钮点击逻辑
		
		
	}, 1000); // 设置等待时间为 1000 毫秒
	return (
		<div style={{position: "relative", minHeight: height, paddingBottom: "4rem",marginTop:"4.85rem"}}>
			<Form style={{marginRight: "0.5rem"}} requiredMarkStyle='text-required' layout='vertical' mode='card'>
				<Form.Item name='title' label={<span
					style={{color: "#000000", fontSize: "1rem"}}>{params.type === 'post' ? "标题" : "话题名称"}</span>}
				           rules={[{required: true, message: params.type === 'post' ? '请输入标题名称(1-50个字)' : '请输入话题名称(1-20个字)'}]}>
					<Input
						style={{"--font-size": "0.875rem", '--color': "#000000"}}
						placeholder={params.type === 'post' ? '请输入标题名称(1-50个字)' : '请输入话题名称(1-20个字)'}
						value={title}
						onChange={val => {
							setTitle(val)
						}}
						minLength={1}
						maxLength={params.type === 'post' ?50:20}
					/>
				</Form.Item>
				
				{(params.type === 'post') &&
				
				<Form.Item name='topic' label={<span style={{color: "#000000", fontSize: "1rem"}}>话题</span>}
				>
					<FlexBox style={{justifyContent: "flex-start"}}>
						<TopicBtn onClick={() => {
							setModalContent(<SelectTopicModal defaultTitle={selectTopic?selectTopic[0]:''}  callback={(data) =>{
								setSelectTopic(data)
							} }/>);
							setPosition('bottom');
							setOpenModal(true)
						}} color={'#030C29'} bgcolor={'#DFDFDF'}>
							<TopicIcon src={require('../../../assets/images/forum/lt_icon_post_add_huati.png')}/>
							<div style={{color: "#000000", fontSize: "0.75rem",}}>话题</div>
						</TopicBtn>
						{ selectTopic && <>
							{selectTopic.map((item, index) => {
								return (
									<FlexBox key={index} style={{justifyContent: "flex-start", marginLeft: '0.5rem'}}>
										<TopicBtn color={'#7A57EB'} bgcolor={'#E7E1FA'}>
											<TopicIcon
												src={require('../../../assets/images/forum/lt_icon_post_huati.png')}/>
											<TopicText>{item}</TopicText>
											<DeleteIcon onClick={() => {
												let newArr = selectTopic.filter(i => i !== item)
												setSelectTopic(newArr)
											}} src={require('../../../assets/images/forum/lt_icon_post_detele_huati.png')}/>
										</TopicBtn>
									</FlexBox>
								)
							})}
						</>}
						
					
					
					</FlexBox>
				</Form.Item>}
				{
					params.type === 'vote' &&
					<Form.Item name={'cover'} label={<span style={{color: "#000000", fontSize: "1rem"}}>{'封面'}</span>}>
						<div  id={'img_up'} onClick={(e) => {
							if (isPermission === 0) {
								e.preventDefault()
								Toast.show({icon:'fail',content:'没有相机与相册权限,请前往应用权限设置授权'})
								e.stopPropagation()
								return setTimeout(() => {
									window.location.href = "uniwebview://useCamera"
								},1500);
							}
						}} >
							<ImageUploader
								nativeElement={imgUpRef}
								style={{width: "11.47rem", height: "4.293rem"}}
								value={poster}
								maxCount={1}
								onChange={setPoster}
								onDelete={(file) => {
									let newImg = poster.filter(item => item.url !== file.url)
									setPoster(newImg)
								}}
								upload={(file) => uploadFile(file, () => {
								})}
								renderItem={(originNode, file, list) => {
									let container = document.getElementsByClassName('adm-image-uploader-cell-image')[0]
									let bContainer = document.getElementsByClassName('adm-image-uploader-cell')[0]
									let deleteIcon = document.getElementsByClassName('adm-image-uploader-cell-delete')[0]
									if (container && bContainer && deleteIcon) {
										container.style.width = "11.47rem"
										container.style.height = "4.293rem"
										container.style.borderRadius = "0.75rem"
										bContainer.style.width = "11.47rem"
										bContainer.style.height = "4.293rem"
										bContainer.style.borderRadius = "0.75rem"
										deleteIcon.style.width = "20px"
										deleteIcon.style.height = "15px"
									}
									
									return (
										<div key={file}>
											{originNode}
										</div>
									);
								}}
							>
								<FlexBox
									style={{
										width: "11.47rem",
										height: "4.293rem",
										background: "#F4F6F9",
										borderRadius: "0.75rem"
									}}>
									<img style={{width: "2.04rem", height: "2.04rem"}}
									     src={require('../../../assets/images/forum/lt_icon_add_huati_img.png')} alt=''/>
								</FlexBox>
							</ImageUploader>
						</div>
						
					</Form.Item>}
				
				<FlexBox style={{justifyContent: "flex-start", marginLeft: "1rem"}}>
					<div style={{color: "#000000", fontSize: "1rem"}}>内容</div>
					<div style={{fontSize: "0.875rem", color: "#999999", marginLeft: 4}}>(必填)</div>
				</FlexBox>
				<div style={{marginLeft: "1rem", marginTop: "0.5rem"}}>
					<Editor type={params.type} callback={(data) => {
						setContent(data.html)
						setFileList(data.url)
					}}/>
				</div>
				
				{params.type === 'post' &&
				<FlexBox style={{marginLeft: "1rem", marginTop: "0.5rem", justifyContent: "flex-start"}}>
					<VoteBtn onClick={() => {
						setPosition('bottom');
						setModalContent(<AddVoteModal callback={(obj) => {
							setVoteInfo(obj)
						}}/>);
						setOpenModal(true)
					}} src={require('../../../assets/images/forum/lt_btn_post_toupiao.png')}/>
					<div style={{color: "#999999", fontSize: "0.875rem"}}>(投票)</div>
				</FlexBox>}
				{
					(Object.keys(voteInfo).length > 0 && params.type === 'post') &&
					<VoteContainer style={{marginLeft: "1rem", marginTop: "0.5rem"}}>
						<RemoveIcon onClick={() => {
							setVoteInfo({})
						}} src={DeleteImg}/>
						<VoteTitle>{voteInfo.title}</VoteTitle>
						<VoteType>{voteInfo.optionType === 0 ? '单选' : '多选'}</VoteType>
						{voteInfo.select.map((item, index) => {
							return (
								<VoteItem key={index}>{item}</VoteItem>
							)
						})}
					</VoteContainer>
				}
			
			</Form>
			<FlexBox style={{position: "fixed", bottom: '1rem', width: width}}>
				<Btn onClick={() => {
					if (!clicked) {
						// 处理点击事件的逻辑
						setClicked(true);
						submit()
						// 将点击状态设置为 true，防止再次触发点击事件
						
					}
				}}>
					提交
				</Btn>
			</FlexBox>
		
		</div>
	)
}
export default Topic
