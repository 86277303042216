import React, {useEffect, useState} from 'react';
import styled, {keyframes} from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {getTaskInfo, openBox, receiveGift, signTask} from "../../../services";
import {useNavigate} from "react-router";
import {useUserInfo} from "../../../hooks";
import {Toast} from "antd-mobile";
import {getToken} from "../../../utils/token";
import CloseChest from "../../../assets/images/mine/gr_icon_quest_chest.png"
import OpenChest from "../../../assets/images/mine/gr_icon_quest_chest_2.png"
const Container = styled.div`
    padding-top:  4.75rem;
    padding-bottom: 5rem;
`
const WBox = styled.div`
    width: 20.5rem;
	background-color: #FFFFFF;
	padding: 0.75rem 1rem;
    margin-top: 0.31rem;
`
const Title = styled.div`
	font-size: 1rem;
    color: #091023;
`
const BoxTitle = styled.div`
    font-size: 0.63rem;
    color: #828388;
`
const QDItem = styled.div`
    width: 2.6rem;
    height: 3.46rem;
    background: ${props => props.bg};
    border: 0.19px solid #F8F8F8;
    border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0.25rem 0;
	
`
const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.1rem;
`
const QDCount = styled.div`
    font-size: 0.63rem;
    color: #000000;
	margin-top: 0.5rem;
`
const QdMask = styled.div`
	background: #5D5D5D;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 3.46rem;
	position: absolute;
	z-index: 3;
	opacity: 0.5;
    border-radius: 0.5rem;
`
const SuccessIcon = styled.img`
    width: 1.04rem;
    height: 1.04rem;
	object-fit: cover;
	background: #FFFFFF;
	z-index: 4;
	border-radius: 1.04rem;
	opacity: 1;
	position: absolute;
`
const ItemBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #F5F5F5;
	padding-top: 0.5rem;
	margin-top: 0.5rem;
`
const ItemTitle = styled.div`
    font-size: 0.81rem;
    color: #181818;
`
const ItemText = styled.div`
    font-size: 0.56rem;
    color: #828388;
	margin-top: 0.75rem;
	max-width: 12rem;
	word-break: break-all;
`
const ReceiveBtn = styled.div`
    width: 3.35rem;
    height: 1.52rem;
    background: ${props => props.bg};
    border-radius: 0.72rem;
	font-size: 0.65rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin-left:0.5rem;
	:hover {
		filter: opacity(0.8);
	}
`
const ItemCount = styled.div`
    font-size: 0.88rem;
    color: #000000;
	margin: 0 0.25rem;
`
const Planned = styled.div`
    font-size: 0.88rem;
    color: #000000;
	margin: 0 0.25rem;
`
const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
`;
const PlanIcon = styled.img`
	width: 1.75rem;
	height: 1.75rem;
        animation: ${shakeAnimation} 0.5s ease-in-out infinite; /* 将晃动动画应用到:hover状态 */
`
const PlansIcon = styled.img`
	width: 1.75rem;
	height: 1.75rem;
 
`
const TaskCenter = () => {
	const [data,setData] = useState({every:[],sign:[],first:[]})
	const [reload,setReload] = useState(false)
	useEffect(() => {
		if (getToken()){
			getTaskInfo({from:'web'}).then(res => {
				if (res.data.isSucc) {
					setData(res.data.res)
				}
			})
		}
		
	},[reload,getToken()])
	const getState = (state) => {
		if (state === 0){
			return {bg:'#7A57EB',text:'去完成'}
		}else if (state === 1){
			return {bg:'#69BD6F',text:'领取'}
		}else if (state === 2){
			return {bg:'#B3B3B3',text:'已领取'}
		}
	}
	const {userInfo} = useUserInfo()
	const navigate = useNavigate()
	const toDone = (item) => {
		if (item.state === 2) {
			return;
		}
		if (item.state === 0){
			if (item.taskTag ===1 ){
				return navigate('/community/topic/post',{replace:true})
			}else if (item.taskTag === 3 || item.taskTag ===2  ||item.taskTag ===15) {
				return navigate('/community',{replace:true})
			}else if (item.taskTag === 4) {
				return window.location.href = "uniwebview://openAnswerGame"
			}else if (item.taskTag === 5) {
				return window.location.href = "uniwebview://emotionalInteraction"
			}else if (item.taskTag === 6 || item.taskTag === 16) {
				return window.location.href = "uniwebview://dailyInteraction"
			}else if (item.taskTag === 7 || item.taskTag === 17) {
				return window.location.href = "uniwebview://openChatroom"
			}else if (item.taskTag === 8) {
				return navigate('/store',{replace:true})
			}else if (item.taskTag === 14) {
				return navigate('/mine/promote',{replace:true})
			}else if (item.taskTag === 9){
				return navigate('/mine/mine_info',{replace:true})
			}else if (item.taskTag === 10){
				return navigate('/mine/address',{replace:true})
			}else if (item.taskTag === 11){
				return navigate('/mine/tuck_info?enterType=mine',{replace:true})
			}else if (item.taskTag === 12){
				return navigate('/mine/mine_info',{replace:true})
			}else if (item.taskTag === 13){
				return navigate('/mine/certification',{replace:true,state:{info:userInfo}})
			}
		}else if (item.state === 1){
			receiveGift({ids:[item.id]}).then(res => {
				if (res.data.isSucc){
					setReload(!reload)
					Toast.show({icon:'success',content:'领取成功'})
				}else {
					Toast.show({icon:'fail',content:'领取失败'})
				}
			})
		
		}
	}
	const receive = (id) => {
		receiveGift({ids:[id]}).then(res => {
			if (res.data.isSucc ){
				setReload(!reload)
				Toast.show({icon:'success',content:'领取成功'})
			}else {
				Toast.show({icon:'fail',content:'领取失败'})
			}
		})
	}
	const open = () => {
		openBox({}).then(res => {
			if (res.data.isSucc ){
				setReload(!reload)
				Toast.show({icon:'success',content:'已领取宝箱: '+res.data.res.succress+'点卡'})
			}else {
				Toast.show({icon:'fail',content:'领取失败'})
			}
		})
	}
	const getBoxElement = () => {
		if (data.open_ever_now === data.open_ever_tag){
			if (data.haveOpenBox){
				return  <PlanIcon onClick={open} src={CloseChest}/>
			}else {
				return <PlansIcon src={OpenChest}/>
			}
		}else {
			return <PlansIcon src={CloseChest}/>
		}
	}
    return (
        <Container>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>每日签到</Title>
			        <BoxTitle>已签到：<span style={{color:"#EB7A52"}}>{data.signDay}</span> 天</BoxTitle>
		        </FlexBox>
		        <FlexBox style={{justifyContent:"flex-start"}}>
			        {
						data.sign.map((item,index) => {
								return (
									<div onClick={() => {
										if (item.state === 1 ){
											receive(item.id )
										}
									}} key={index} style={{textAlign:"center",marginRight:'0.25rem'}}>
										<QDItem bg={item.state === 1 ? '#FFF5D2' :"#FFFFFF"} >
											{item.state === 2 && <SuccessIcon src={require('../../../assets/images/mine/gr_icon_shiming_success.png')}/>}
											{item.state === 2 && <QdMask/>}
											<MkIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
											<QDCount>+{item.gift}</QDCount>
										</QDItem>
										<div>第{item.id}天</div>
									</div>
								)
							
						})
			        }
					
		        </FlexBox>
	        </WBox>
	        {data.every.length > 0 &&
	        <WBox>
		       <FlexBox style={{justifyContent:"space-between"}}>
			       <Title>每日任务</Title>
			       <FlexBox>
				       <Planned>进度: {data.open_ever_now}/{data.open_ever_tag}</Planned>
				       {getBoxElement()}
			       </FlexBox>
		       </FlexBox>
			   
		        {
					data.every.map((item,index) => {
							return (
								<ItemBox onClick={() => toDone(item)} key={index}>
									<div>
										<ItemTitle>{item.title} ({item.progress[0]}/{item.progress[1]})</ItemTitle>
										<ItemText>{item.desc}</ItemText>
									</div>
									<FlexBox style={{justifyContent:"space-between"}}>
										<ItemCount>+{item.gift}</ItemCount>
										<MkIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
										<ReceiveBtn bg={getState(item.state).bg}>{getState(item.state).text}</ReceiveBtn>
									
									</FlexBox>
								</ItemBox>
							)
						
					})
		        }
	        </WBox>}
	        {data.first.filter(i => i.state !== 2 ).length > 0 &&
	            <WBox>
		        <Title>新手任务</Title>
		        {
			        data.first.map((item,index) => {
				        if (item.state !== 2) {
					        return (
						        <ItemBox onClick={() => toDone(item)} key={index}>
							        <div>
								        <ItemTitle>{item.title} ({item.progress[0]}/{item.progress[1]})</ItemTitle>
								        <ItemText>{item.desc}</ItemText>
							        </div>
							        <FlexBox style={{justifyContent:"space-between"}}>
								        <ItemCount>+{item.gift}</ItemCount>
								        <MkIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
								        <ReceiveBtn bg={getState(item.state).bg}>{getState(item.state).text}</ReceiveBtn>
							
							        </FlexBox>
						        </ItemBox>
					        )
				        }
				
				
			        })
		        }
	        </WBox>
		
	        }
        </Container>
    );
};

export default TaskCenter;
