import React, {useEffect} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png"
import {useLocation} from "react-router-dom";
import {useNavBtn} from "../../../hooks";
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`

const ItemContainer = styled.div`
    background-color: #F8F8F8;
    text-align: center;
	padding: 0 1rem;
	margin-top: 1rem;
`
const Time = styled.div`
	font-size: 0.75rem;
	color: #828388;
	
`
const Avatar = styled.img`
	width: 2.81rem;
	height: 2.81rem;
	border-radius: 2.81rem;
	object-fit: cover;
`
const MessageBox = styled.div`
	width: 17.12rem;
	max-height: 9.47rem;
    background: #FFFFFF;
	border-radius: 0.75rem;
	padding: 0.5rem;
	margin-left: 0.5rem;
	text-align: left;
`
const Name = styled.div`
    font-size: 1rem;
    color: #091023;
	
`
const Content = styled.div`
    font-size: 0.875rem;
    color: #3F4456;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical;
	margin-top: 0.5rem;
`
const PostBox = styled.div`
    width: 16.12rem;
    height: 3.75rem;
    background: #F4F6F9;
    border-radius: 0.75rem;
    padding: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 0.5rem;
`
const PostContent = styled.div`
    font-size: 0.875rem;
    color: #3F4456;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp:1;
    margin-top: 0.25rem;
    -webkit-box-orient: vertical;
`
const TopicTip = styled.div`
    font-size: 0.875rem;
    color: #7A57EB;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 10rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem;
    word-break: break-all;
    word-wrap: break-word;
	
`
const LikeImg = styled.img`
    width: 1.19rem;
    height: 1.065rem;
`
const LikeText = styled.div`
    font-size: 0.75rem;
    color: #3F4456;
`
const MessageDetails = ({}) => {
	const {state:{type}} = useLocation();

    return (
        <Container>
	        <ItemContainer>
		        <Time>昨天 12:41</Time>
		        <FlexBox style={{justifyContent:"space-between",marginTop:"1rem",alignItems:"flex-start"}}>
			        <Avatar src={DefaultAvatar}/>
			        <MessageBox>
				        <Name>卡车圈大事件</Name>
				        {type === 1 &&  <Content>这是评论内容这是评论内容这是评论内容这是评论内容这是评论内容这是评论内容...</Content>}
				        {type === 0 &&  <FlexBox style={{justifyContent:"flex-start"}}>
					        <LikeImg src={require('../../../assets/images/forum/lt_icon_zan_2.png')}/>
					        <LikeText>赞了这篇帖子</LikeText>
				        </FlexBox>}
				        <PostBox>
					        <Avatar src={DefaultAvatar}/>
					        <div style={{marginLeft:"0.5rem"}}>
						        <Name>我爱吃萝卜</Name>
						        <PostContent>近日，针对货车司机权益保障突出问题，...</PostContent>
						        <TopicTip>#货车司机权益保障</TopicTip>
					        </div>
					        
				        </PostBox>
			        </MessageBox>
		        </FlexBox>
	        </ItemContainer>
        </Container>
    );
};

export default MessageDetails;
