import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import styled from "styled-components";
import ExchangeBg from '../../../assets/images/exchangelogs/wzz_bg_k_card.png'
import FlexBox from "../../../commen/FlexBox";
import RoleModal from "./components/RoleModal";
import {useModal, useRefresh, useUserInfo} from "../../../hooks";
import {getBrandOrTypes, shopList} from "../../../services";
import {getToken} from "../../../utils/token";
import {PullToRefresh} from "antd-mobile";
import LivingSupplies from "../tuckHome/components/LivingSupplies";

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 5.75rem;
`
const KBg = styled.div`
    background-image: url(${ExchangeBg});
    background-size: cover;
    width: 20.5rem;
    height: 10.416rem;
    position: relative;
    border-radius: 0.5rem;
`
const CarImg = styled.img`
    width: 7.895rem;
    height: 6.729rem;
    object-fit: cover;
    margin-right: 1.25rem;
    margin-top: 0.125rem;
`
const MTitle = styled.div`
    font-size: 1rem;
    color: #8F5F39;
`
const MkIcon = styled.img`
    width: 1.96rem;
    height: 1.686rem;
    object-fit: cover;
    margin-top: 0.93rem;
`
const MCount = styled.div`
    font-size: 2.07rem;
    color: #8F5F39;
    margin-top: 0.93rem;
    margin-left: 0.54rem;
`


const ActionBtn = styled.div`
    width: 7.06rem;
    height: 1.7rem;
    background: rgba(255, 255, 255, 0);
    border: 0.19px solid #8F5F39;
    border-radius: 0.85rem;
    font-size: 0.86rem;
    color: #8F5F39;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`
let y = 0
const GiftExchange = () => {
	const {userInfo, setUserInfo} = useUserInfo()
	const {refresh, setRefresh} = useRefresh()
	const navigate = useNavigate()
	const localScType = localStorage.getItem('scType')
	const [scType, setScType] = useState(localScType?JSON.parse(localScType):[])
	
	const {setOpenModal, setModalContent, setPosition} = useModal()
	const token = getToken()
	useEffect(() => {
		if (!localScType){
			getBrandOrTypes({}).then(res => {
				if (res.data.isSucc) {
					localStorage.setItem('scType', JSON.stringify(res.data.res.sctype))
					setScType(res.data.res.sctype)
				}
			})
		}
		
	}, [!localScType])
	useEffect(() => {
		window.onscroll = () => {
			y = window.scrollY
		}
		return () => {
			window.onscroll = null
			
		}
	}, [])
	useEffect(() => {
		window.scroll(0,y)
	},[])
	return (
		<PullToRefresh
			onRefresh={() => {
				setRefresh(!refresh)
			}}
		>
			<Container>
				
				<KBg>
					<div onClick={() => {
						setModalContent(<RoleModal/>);
						setPosition('center');
						setOpenModal(true);
					}} style={{
						position: "absolute",
						top: "0.25rem",
						right: "0.5rem",
						fontSize: "0.75rem",
						color: "#8F5F39"
					}}>点卡获取规则 >
					</div>
					<FlexBox style={{justifyContent: "space-between",}}>
						<div style={{marginTop: "2rem", width: "12rem", marginLeft: "1.833rem"}}>
							<MTitle>当前点卡</MTitle>
							<FlexBox style={{
								borderBottom: "1px solid #31D3D803",
								justifyContent: "flex-start",
								marginTop: '0.375rem',
								width: '4.979rem',
								height: '1.166rem',
							}}>
								<MkIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_2.png')}/>
								<MCount>{userInfo.coin}</MCount>
							</FlexBox>
						</div>
					</FlexBox>
					<FlexBox style={{justifyContent: "center", marginTop: "1.686rem"}}>
						<ActionBtn onClick={() => navigate('/store/coin_logs')}>点卡明细</ActionBtn>
						<ActionBtn onClick={() => {
							navigate('/store/exchange_logs')
						}} style={{marginLeft: "1.98rem"}}>兑换记录</ActionBtn>
					</FlexBox>
				</KBg>
				<div style={{width: "20.5rem"}}>
					{scType.map((item, index) => {
						return (
							<LivingSupplies key={index} type={'1'} title={item.value}/>
						)
					})}
				</div>
			
			
			</Container>
		
		
		</PullToRefresh>
	
	);
};

export default GiftExchange;
