import axios from 'axios'
import {getToken} from "./token";
import {WalletUrl} from "./baseUrl";
//http://43.136.14.72:8848
// http://192.168.31.20:8848
const walletRequest = axios.create({
	baseURL:WalletUrl,//基准地址
	timeout:5000,
})

//拦截请求
walletRequest.interceptors.request.use((config)=>{
	config.headers["Content-Type"] = 'application/json'
	if (getToken()){
		console.log(getToken())
		config.headers['__token'] = getToken()
	}
	return config
})
//拦截响应
walletRequest.interceptors.response.use((response)=>{
		return response
	},function (error){
		//对响应的错误做点什么
		return Promise.reject(error);
	}
)

export default walletRequest
