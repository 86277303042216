import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import EditIcon from "../../../../assets/images/mine/gr_btn_edit_location.png"

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
	padding:0.75rem;
	width:19rem;
	border-radius: 0.5rem;
	margin-bottom: 0.75rem;
`
const LocationText = styled.div`
    font-size: 0.88rem;
    color: #181818;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 15rem;
`
const DefaultText = styled.div`
    width: 1.56rem;
    height: 0.875rem;
    background: #5898F7;
    font-size: 0.5rem;
    color: #FFFFFF;
	margin-left: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	border-radius: 0.125rem;
`
const Text = styled.div`
    font-size: 0.75rem;
    color: #828388;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 17rem;
`
const Icon = styled.img`
	width: 1.375rem;
	height: 1.375rem;
`
const AddItem = ({onClick,data}) => {
	return (
		<Container  >
			<div>
				<FlexBox style={{justifyContent:"flex-start"}}>
					<LocationText>{data.address_area}</LocationText>
					{data.isDefault && <DefaultText >默认</DefaultText>}
				</FlexBox>
				<Text>{data.address_more}</Text>
				<FlexBox style={{justifyContent:"flex-start"}}>
					<Text style={{maxWidth:"6rem"}}>{data.name}</Text>
					<Text style={{marginLeft:'1.38rem'}}>{data.phone}</Text>
				</FlexBox>
				
			</div>
			<Icon onClick={onClick} src={EditIcon}/>
		</Container>
	);
};

export default AddItem;
