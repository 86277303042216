import React, {useEffect} from 'react';
import styled from "styled-components";
import {Outlet} from "react-router";

const Container = styled.div`
`

const Store = () => {
	useEffect(() => {
		return () => {
			localStorage.clear()
		}
	},[])
	return (
		<Container>
			<Outlet/>
		</Container>
	);
};

export default Store;
