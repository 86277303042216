export const emoteList = [
	{id:1,name:'[哦]',Img:'Emj1'},{id:2,name:'[微笑]',Img:'Emj2'},
	{id:3,name:'[撇嘴]',Img:'Emj3'},{id:4,name:'[色]',Img:'Emj4'},
	{id:5,name:'[发呆]',Img:'Emj5'},{id:6,name:'[得意]',Img:'Emj6'},
	{id:7,name:'[流泪]',Img:'Emj7'},{id:8,name:'[害羞]',Img:'Emj8'},
	{id:9,name:'[闭嘴]',Img:'Emj9'},{id:10,name:'[睡]',Img:'Emj10'},
	{id:11,name:'[大哭]',Img:'Emj11'},{id:12,name:'[尴尬]',Img:'Emj12'},
	{id:13,name:'[发怒]',Img:'Emj13'},{id:14,name:'[调皮]',Img:'Emj14'},
	{id:15,name:'[呲牙]',Img:'Emj15'},{id:16,name:'[惊讶]',Img:'Emj16'},
	{id:17,name:'[难过]',Img:'Emj17'},{id:18,name:'[囧]',Img:'Emj18'},
	{id:19,name:'[抓狂]',Img:'Emj19'},{id:20,name:'[吐]',Img:'Emj20'},
	{id:21,name:'[偷笑]',Img:'Emj21'},{id:22,name:'[愉快]',Img:'Emj22'},
	{id:23,name:'[白眼]',Img:'Emj23'},{id:24,name:'[傲慢]',Img:'Emj24'},
	{id:25,name:'[困]',Img:'Emj25'},{id:26,name:'[惊恐]',Img:'Emj26'},
	{id:27,name:'[憨笑]',Img:'Emj27'},{id:28,name:'[悠闲]',Img:'Emj28'},
	{id:29,name:'[咒骂]',Img:'Emj29'},{id:30,name:'[疑问]',Img:'Emj30'},
	{id:31,name:'[嘘]',Img:'Emj31'},{id:32,name:'[晕]',Img:'Emj32'},
	{id:33,name:'[衰]',Img:'Emj33'},{id:34,name:'[敲打]',Img:'Emj34'},
	{id:35,name:'[再见]',Img:'Emj35'},{id:36,name:'[擦汗]',Img:'Emj36'},
	{id:37,name:'[鼓掌]',Img:'Emj37'},{id:38,name:'[坏笑]',Img:'Emj38'},
	{id:39,name:'[抠鼻]',Img:'Emj39'},{id:40,name:'[鄙视]',Img:'Emj40'},
	{id:41,name:'[委屈]',Img:'Emj41'},{id:42,name:'[快哭了]',Img:'Emj42'},
	{id:43,name:'[阴险]',Img:'Emj43'},{id:44,name:'[亲亲]',Img:'Emj44'},
	{id:45,name:'[可怜]',Img:'Emj45'},{id:46,name:'[生病]',Img:'Emj46'},
	{id:47,name:'[脸红]',Img:'Emj47'},{id:48,name:'[笑脸]',Img:'Emj48'},
	{id:49,name:'[破涕为笑]',Img:'Emj49'},{id:50,name:'[恐惧]',Img:'Emj50'},
	{id:51,name:'[失望]',Img:'Emj51'},{id:52,name:'[无语]',Img:'Emj52'},
	{id:53,name:'[嘿哈]',Img:'Emj53'},{id:54,name:'[捂脸]',Img:'Emj54'},
	{id:55,name:'[奸笑]',Img:'Emj55'},{id:56,name:'[机智]',Img:'Emj56'},
	{id:57,name:'[皱眉]',Img:'Emj57'},{id:58,name:'[耶]',Img:'Emj58'},
	{id:59,name:'[吃瓜]',Img:'Emj59'},{id:60,name:'[加油]',Img:'Emj60'},
	{id:61,name:'[汗]',Img:'Emj61'},{id:62,name:'[天啊]',Img:'Emj62'},
	{id:63,name:'[Emm]',Img:'Emj63'},{id:64,name:'[社会社会]',Img:'Emj64'},
	{id:65,name:'[旺财]',Img:'Emj65'},{id:66,name:'[好的]',Img:'Emj66'},
	{id:67,name:'[打脸]',Img:'Emj67'},{id:68,name:'[哇]',Img:'Emj68'},
	{id:69,name:'[翻白眼]',Img:'Emj69'},{id:70,name:'[666]',Img:'Emj70'},
	{id:71,name:'[让我看看]',Img:'Emj71'},{id:72,name:'[叹气]',Img:'Emj72'},
	{id:73,name:'[苦涩]',Img:'Emj73'},{id:74,name:'[裂开]',Img:'Emj74'},
	{id:75,name:'[猪头]',Img:'Emj75'},{id:76,name:'[鬼魂]',Img:'Emj76'},
	{id:77,name:'[嘴唇]',Img:'Emj77'},{id:78,name:'[爱心]',Img:'Emj78'},
	{id:79,name:'[心碎]',Img:'Emj79'},{id:80,name:'[拥抱]',Img:'Emj80'},
	{id:81,name:'[强]',Img:'Emj81'},{id:82,name:'[弱]',Img:'Emj82'},
	{id:83,name:'[握手]',Img:'Emj83'},{id:84,name:'[胜利]',Img:'Emj84'},
	{id:85,name:'[抱拳]',Img:'Emj85'},{id:86,name:'[勾引]',Img:'Emj86'},
	{id:87,name:'[拳头]',Img:'Emj87'},{id:88,name:'[ok]',Img:'Emj88'},
	{id:89,name:'[合十]',Img:'Emj89'},{id:90,name:'[啤酒]',Img:'Emj90'},
	{id:91,name:'[咖啡]',Img:'Emj91'},{id:92,name:'[蛋糕]',Img:'Emj92'},
	{id:93,name:'[玫瑰]',Img:'Emj93'},{id:94,name:'[凋谢]',Img:'Emj94'},
	{id:95,name:'[菜刀]',Img:'Emj95'},{id:96,name:'[炸弹]',Img:'Emj96'},
	{id:98,name:'[便便]',Img:'Emj98'},{id:99,name:'[月亮]',Img:'Emj99'},
	{id:100,name:'[太阳]',Img:'Emj100'},{id:101,name:'[庆祝]',Img:'Emj101'},
	{id:102,name:'[礼物]',Img:'Emj102'},{id:103,name:'[红包]',Img:'Emj103'},
	{id:104,name:'[发]',Img:'Emj104'},{id:105,name:'[福]',Img:'Emj105'},
	{id:106,name:'[烟花]',Img:'Emj106'},{id:107,name:'[鞭炮]',Img:'Emj107'},
]
