import React, {useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useLocation} from "react-router-dom";
import {useWindowSize} from "../../../utils/useWindowSize";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png";
import {ImageViewer} from "antd-mobile";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const WBox = styled.div`
    width: 18.5rem;
	background-color: #FFFFFF;
	padding: 0.75rem 1rem;
    margin-top: 0.31rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-radius: 0.5rem;
	flex-direction: column;
`
const Title =styled.div`
	font-size: 1rem;
	color: #000000;
	font-weight: bold;
`
const Text = styled.div`
    font-size: 0.875rem;
    color: #001123;
	margin-top: 1rem;
`
const HelpDetail = () => {
	const {state:{data}} =useLocation()
	const {height} = useWindowSize()
	const [visible, setVisible] = useState(false)
    return (
        <Container style={{minHeight:height}}>
	        {visible && <div style={{position:"fixed",backgroundColor:"#000000",inset:0,height:height,zIndex:999}}/>}
	        <ImageViewer
		        capture={false}
		        accept={"image/*"}
		        image={require('../../../assets/images/help/zhiling.jpg')}
		        visible={visible}
		        onClose={() => {
			        setVisible(false)
		        }}
	        />
	        <WBox>
		        <Title>{data.q}</Title>
		        {data.q.includes('指令有哪些') ? <img onClick={() => setVisible(true)} style={{width:"18.5rem",marginTop:"1rem"}} src={require('../../../assets/images/help/zhiling.jpg')} alt=""/> :
		        <Text>{data.a.includes('；')?data.a.split('；').map((item,index) => {
					if (item !== ''){
						return(
							<div key={index}>{item};</div>
						)
					}
		        }):data.a}</Text>}
	        </WBox>
        </Container>
    );
};

export default HelpDetail;
