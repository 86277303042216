import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Swiper} from "antd-mobile";
import TuckMenu from "./TuckMenu";
import LivingSupplies from "./LivingSupplies";
import { getShopBanner} from "../../../../services";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {getToken} from "../../../../utils/token";



const BannerBtn = styled.div`
    width: 4.686rem;
    height: 1.793rem;
    background: #FFFFFF;
    border-radius: 0.9rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    text-align: center;
    position: absolute;
    right: 0.66rem;
    bottom: 0.62rem;
    z-index: 99;
`
const BannerTitle = styled.div`
    font-size: 1.175rem;
    color: #FFFFFF;
    position: absolute;
    left: 0.63rem;
    bottom: 1.54rem;
`
const TuckFirst = () => {
	const localStoreBanner = localStorage.getItem('storeBanner')
	const [banner, setBanner] = useState(localStoreBanner?JSON.parse(localStoreBanner):[])
	useEffect(() => {
		if (getToken() && !localStoreBanner) {
			getShopBanner({}).then(res => {
				if (res.data.isSucc) {
					setBanner(res.data.res.list)
					localStorage.setItem('storeBanner', JSON.stringify(res.data.res.list))
				}else {
				
				}
			})
		}
		
	}, [getToken()])
	useEffect(() => {
		if (localStorage.getItem('scrollY')){
			window.scroll(0,localStorage.getItem('scrollY')?Number(localStorage.getItem('scrollY')):0)
		}
	},[])
	const navigate = useNavigate()
	return (
		<div>
			<div style={{
				top: "5rem",
				position: "relative",
				backgroundColor: "#FFFFFF",
				height: "13rem",
				borderBottom: "1px solid #F3F3F3",
				marginTop:28
			}}>
				<Swiper
					loop
					autoplay
					onIndexChange={i => {
					}}
					style={{"--border-radius":"8px"}}
					indicatorProps={{
						'--dot-color': 'rgba(0, 0, 0, 0.4)',
						'--active-dot-color': '#ffc0cb',
						'--dot-size': '10px',
						'--active-dot-size': '10px',
						'--dot-border-radius': '50%',
						'--active-dot-border-radius': '15px',
						'--dot-spacing': '8px',
					}}
				>
					{banner.map((item, index) => (
						<Swiper.Item  key={index}>
							<div onClick={() => {
								if (!item.jump_id){
									return;
								}
								navigate('/store/store_details/' + item.jump_id)
							}
							}
							     style={{position: "relative", width: "20.5rem", height: "13.666rem",}}>
								<BannerTitle>{item.titles}</BannerTitle>
								<img
									style={{
										width: "20.5rem",
										height: "13rem",
										marginTop: "1rem",
										objectFit: "cover",
										borderRadius:8
									}}
									onClick={() => {
										// Toast.show(`你点击了卡片 ${index + 1}`)
									}}
									src={item.images}
									alt={''}
								/>
								<BannerBtn>立即购买</BannerBtn>
							</div>
						
						</Swiper.Item>
					))}
				</Swiper>
			</div>
			<div style={{marginTop: "6.75rem"}}>
				<TuckMenu type={'2'} title={'热卖车型'}/>
				<LivingSupplies type={'1'} title={'生活物资'}/>
			</div>
			
		
		</div>
	);
};

export default TuckFirst;
