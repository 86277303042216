import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CheckTab from "../../community/forum/components/CheckTab";
import GiftExchange from "../giftExhange";
import TuckFirst from "./components/TuckFirst";
import Buy from "./components/Buy";
import {useModal} from "../../../hooks";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

const Container = styled.div`
    margin: 0 1rem;
	padding-bottom: 2rem;
`

const TuckHome = () => {
	const [tabIndex, setTabIndex] = useState(0)
	const checkData = [ '首页', '购车', '物资']
	const {setOpenModal} = useModal()
	const {pathname} =useLocation()
	useEffect(() => {
		setOpenModal(false)
		// window.scrollTo(0,0)
	},[tabIndex])

	useEffect(() => {
		window.onscroll = () => {
			localStorage.setItem('scrollY', String(window.scrollY))
		}
		return () => {
			window.onscroll = null
			
		}
	}, [])
	
	return (
        <Container>
	        <CheckTab defaultIndex={tabIndex} data={checkData} style={{position: "fixed", top: "3rem",marginTop:28,justifyContent:"space-between",left:0,padding:"0 3.25rem",width:"16rem"}} callback={(index) => {setTabIndex(index);}}/>
	        {/*<Buy/>*/}
	        <div style={{marginTop:"2rem"}}>
		        {tabIndex === 0 && <TuckFirst/>}
		        {tabIndex === 1 && <Buy/>}
		        {tabIndex === 2 && <GiftExchange/>}
				
	        </div>
        </Container>
    );
};

export default TuckHome;
