import Quill from 'quill';

const ImageBlot = Quill.import('formats/image');

class CustomImageBlot extends ImageBlot {
	static create(value) {
		const node = super.create(value);
		node.setAttribute('style', 'max-width: 600px;width:100%; object-fit: cover');
		return node;
	}
}

export default CustomImageBlot
