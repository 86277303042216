import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {useModal} from "../../../../hooks";
import {useNavigate} from "react-router";

const Container = styled.div`
    width: 17.79rem;
    background: #FFFFFF;
    border-radius: 0.83rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
`
const Tip = styled.div`
    font-size: 0.876rem;
    color: #011012;
    position: absolute;
    top: 1.575rem;
    left: 2.75rem;
`

const MkIcon = styled.img`
    width: 1.453rem;
    height: 1.262rem;
    object-fit: cover;
`
const Text = styled.div`
    font-size: 0.87rem;
    color: #181818;
    margin-left: 1rem;
`
const Title = styled.div`
    font-size: 1.13rem;
    color: #181818;
`


const MCount = styled.div`
    font-size: .876rem;
    color: #181818;
`
const Name = styled.div`
    font-size: 0.876rem;
    color: #7A57EB;
`
const Btn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.3rem;
    font-size: 1rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0.19px solid #7A57EB;
`
const CancelBtn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #FFFFFF;
    border-radius: 1.3rem;
    font-size: 1rem;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0.19px solid #C6C6C6;
`
const NoAddressModal = () => {
	const {setOpenModal} = useModal()
	const navigate = useNavigate()
	return (
		<Container>
			<Title>提示</Title>
			<FlexBox style={{marginTop: "3rem",}}>
				<Text>您暂未填写收货地址信息，请先前往填写收货地址信息</Text>
			</FlexBox>
			<FlexBox style={{justifyContent: "center", marginTop: '4.48rem', marginLeft: "0.75rem"}}>
				<CancelBtn onClick={() => {
					setOpenModal(false)
				}}>取消</CancelBtn>
				<Btn onClick={() => {
					setOpenModal(false);
					navigate('/mine/address')
				}} style={{marginLeft: "1rem"}}>{"前往填写"}</Btn>
			</FlexBox>
		</Container>
	);
};

export default NoAddressModal;
