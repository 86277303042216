import React, {useEffect} from 'react';
import styled from "styled-components";
import {Outlet} from "react-router";
import {forumLogin} from "../../services/forum";
import {getToken} from "../../utils/token";
import {useUserInfo} from "../../hooks";

const Container = styled.div`
    margin-top: 3.5rem;
`

const Community = () => {
	const {setUserInfo} = useUserInfo()
	useEffect(() => {
		window.onresize = () => {
			let html= document.documentElement
			if (html.clientWidth > 1920) {
				const scale = html.clientWidth /1920;
				html.style.fontSize =16 * scale + 'px';
			}else if (html.clientWidth > 1300 && html.clientWidth<1920) {
				const scale = html.clientWidth /1300;
				html.style.fontSize =16 * scale + 'px';
			}else if (html.clientWidth > 1200 && html.clientWidth<1300) {
				const scale = html.clientWidth /600;
				html.style.fontSize =16 * scale + 'px';
			}else {
				const scale = html.clientWidth /360;
				html.style.fontSize =16 * scale + 'px';
			}
		}
		return () => {
			window.onresize = null
		}
	},[])
	useEffect(() => {
		if (window.ta ){
			window.ta.track('BrowseForums');
		}
	},[window.ta])
	useEffect(() => {
		forumLogin({__token: getToken()}).then(res => {
			if (res.data.isSucc) {
				setUserInfo(res.data.res.user)
			}
		})
	},[])
	return (
		<Container>
			<Outlet/>
		</Container>
	);
};

export default Community;
