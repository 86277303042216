import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {formatDate} from "../../../../utils";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 21.208rem;
    height: 4.729rem;
    background-color: #FFFFFF;
    border-radius: 0.86rem;
	margin-bottom: 0.5rem;
`
const Title = styled.div`
    font-size: 0.876rem;
    color: #181818;
     word-break: break-all;
    word-wrap: break-word;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	margin-right: 1rem;
`
const Time = styled.div`
    font-size: 0.75rem;
    color: #828388;
    margin-top: 0.603rem;
`
const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.1rem;
`
const MCount = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-right: 0.25rem;
`
const CoinItem = ({data}) => {
	return (
		<Container>
			<div style={{marginLeft: "1.75rem"}}>
				<Title>{data.title}</Title>
				<Time>{formatDate(data.time)}</Time>
			</div>
			<FlexBox style={{marginRight: '1.75rem'}}>
				<MCount>{data.change}</MCount>
				<MkIcon src={require('../../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
			</FlexBox>
		</Container>
	);
};

export default CoinItem;
