import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {useWindowSize} from "../../../../utils/useWindowSize";
import {Toast} from "antd-mobile";
import {useModal, useUserInfo} from "../../../../hooks";
import {getTopicList, searchTopic} from "../../../../services/forum";
import SelectIcon from "../../../../assets/images/forum/selected_icon.png"
import {generateRandomNumber} from "../../../../utils";
const Container = styled.div`
	background-color: #FFFFFF;
`
const CancelText = styled.div`
    font-size: 0.73rem;
    color: #AAAAAA;
`
const OKText = styled.div`
    font-size: 0.73rem;
    color: #7A57EB;
`
const BTitle = styled.div`
    font-size: 1rem;
    color: #020C18;
`
const SearchContainer = styled.div`
    width: 20.126rem;
    height: 1.5rem;
    background: #DFDFDF;
    border-radius: 0.75rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.25rem;
	margin-top: 1rem;
`
const SearchIcon = styled.img`
	width: 1rem;
	height: 1rem;
    margin-left: 1rem;
	margin-right: 0.5rem;
`
const SearchInput = styled.input`
	font-size: 0.81rem;
	color:#030C29;
	border: none;
	outline: none;
	background-color:  #DFDFDF;
	flex:1;
	margin-left: 1rem;
`
const Title = styled.div`
    font-size: 0.93rem;
    color: #030C29;
	margin-top: 1rem;
`
const ItemImg = styled.img`
    width: 3.09rem;
    height:2.196rem;
    border-radius: 0.63rem;
	object-fit: cover;
`
const ItemTitle = styled.div`
    font-size:0.81em;
    color: #091023;
`
const ItemText = styled.div`
    font-size: 0.75rem;
    color: #828388;
	margin-top: 0.35rem;
`
const SelectImg = styled.img`
	width: 1rem;
	height: 1rem;
	position: absolute;
	right: 1rem;
`
let selectKeys = []
const SelectTopicModal = ({defaultTitle,callback}) => {
	const {height, width} = useWindowSize()
	const {setOpenModal} = useModal()
	const [value,setValue] = useState('')
	const [list,setList] = useState([])
	const [joins,setJoins] = useState([])
	const [select1,setSelect1] = useState('')
	const [select2,setSelect2] = useState('')
	const [searchList,setSearchList] = useState([])
	const {userInfo} = useUserInfo()
	useEffect(() => {
		if (value === '') {
			setSearchList([])
			getTopicList({}).then(res => {
				if (res.data.isSucc) {
					res.data.res.list.map(item => {
						item.key = String(generateRandomNumber())
						if (item.title === defaultTitle && res.data.res.joins.length === 0) {
							setSelect1(item.key)
							selectKeys = [item.key]
						}
					})
					res.data.res.joins.map(item => {
						item.key = String(generateRandomNumber())
						if (item.title === defaultTitle){
							setSelect1(item.key)
							selectKeys = [item.key]
						}
					})
					
					if (userInfo.server_leve === 0){
						const newList = res.data.res.list.filter(item => !['小卡活动分享','小卡新闻分享'].includes(item.title))
						const newJoins = res.data.res.joins.filter(item => !['小卡活动分享','小卡新闻分享'].includes(item.title))
						setList(newList)
						setJoins(newJoins)
					}else {
						setList(res.data.res.list)
						setJoins(res.data.res.joins)
					}
					
				}
			})
		}
		
	},[value])
	
	const search = () => {
		searchTopic({key:value}).then(res => {
			if (res.data.isSucc){
				res.data.res.item.map(i => {
					i.key = String(generateRandomNumber())
					if (i.title === defaultTitle && res.data.res.joins.length === 0 && res.data.res.list.length === 0) {
						setSelect1(i.key)
						selectKeys = [i.key]
					}
				} )
				
				if (userInfo.server_leve > 0) {
					setSearchList(res.data.res.item)
				}else {
					const newList = res.data.res.item.filter(item => !['小卡活动分享','小卡新闻分享'].includes(item.title))
					setSearchList(newList)
				}
				
				
			}
		})
	}
	const getSelectData = () => {
		let titles = []
		list.filter(value => {
			if (value.key === select1 || value.key === select2){
				if (value.title){
					titles.push(value.title)
				}
				
			}
		})
		joins.filter(value => {
			if (value.key === select1 || value.key === select2){
				if (value.title){
					titles.push(value.title)
				}
				
			}
		})
		searchList.filter(value => {
			if (value.key === select1 || value.key === select2){
				if (value.title){
					titles.push(value.title)
				}
				
			}
		})
		if (titles[0] === titles[1]){
			if (titles[0]) {
				return [titles[0]]
			}
			
		}else {
			return titles
		}
		
	}
	return (
		<Container style={{width: width,}}>
			<div style={{padding: "1rem"}}>
				<FlexBox style={{justifyContent: "space-between"}}>
					<CancelText onClick={() => setOpenModal(false)}>取消</CancelText>
					<BTitle>添加话题</BTitle>
					<OKText onClick={() => {
						callback(getSelectData())
						setOpenModal(false)
					}}>完成</OKText>
				</FlexBox>
				<SearchContainer>
					<SearchInput placeholder={'输入话题'} onChange={(e) => {setValue(e.target.value)}} type={'text'}/>
					<SearchIcon onClick={search} src={require('../../../../assets/images/forum/search_icon.png')}/>
				</SearchContainer>
				<div style={{marginTop:"0.5rem",overflowY:"scroll",height:height-200}}>
					{searchList.length > 0 && <div>
						
						{searchList.map((item,index) => {
							return (
								<FlexBox onClick={() => {
									if (selectKeys.includes(item.key)){
										selectKeys = selectKeys.filter(value=> value !== item.key)
										setSelect1(selectKeys[0])
										setSelect2(selectKeys[1])
										return;
									}
									selectKeys.push(item.key)
									if (selectKeys.length  > 2) {
										selectKeys.shift()
									}
									setSelect1(selectKeys[0])
									setSelect2(selectKeys[1])
								}} key={item.key} style={{justifyContent:"flex-start",marginTop:"0.55rem",position:"relative"}}>
									<ItemImg src={item.banner}/>
									<div style={{marginLeft:"0.55rem"}}>
										<ItemTitle># {item.title}</ItemTitle>
										<ItemText>{item.join_cont}人参与</ItemText>
									</div>
									{(select1 === item.key ||select2 === item.key ) && <SelectImg src={SelectIcon}/>}
								</FlexBox>
							)
						})}
					</div>}
					{searchList.length === 0 &&
					<div>
						{joins.length > 0 && <Title>参与过的话题</Title>}
						
						{joins.map((item,index) => {
							return (
								<FlexBox onClick={() => {
									if (selectKeys.includes(item.key)){
										selectKeys = selectKeys.filter(value=> value !== item.key)
										setSelect1(selectKeys[0])
										setSelect2(selectKeys[1])
										return;
									}
									selectKeys.push(item.key)
									if (selectKeys.length  > 2) {
										selectKeys.shift()
									}
									setSelect1(selectKeys[0])
									setSelect2(selectKeys[1])
								}} key={item.key} style={{justifyContent:"flex-start",marginTop:"0.55rem",position:"relative"}}>
									<ItemImg src={item.banner}/>
									<div style={{marginLeft:"0.55rem"}}>
										<ItemTitle># {item.title}</ItemTitle>
										<ItemText>{item.join_cont}人参与</ItemText>
									</div>
									{(select1 === item.key ||select2 === item.key ) && <SelectImg src={SelectIcon}/>}
								</FlexBox>
							)
						})}
						{list.length > 0 && <Title>热门推荐话题</Title>}
						
						{
							list.map((item,index) => {
								return (
									<FlexBox onClick={() => {
										if (selectKeys.includes(item.key)){
											selectKeys = selectKeys.filter(value=> value !== item.key)
											setSelect1(selectKeys[0])
											setSelect2(selectKeys[1])
											return;
										}
										selectKeys.push(item.key)
										if (selectKeys.length  > 2) {
											selectKeys.shift()
										}
										setSelect1(selectKeys[0])
										setSelect2(selectKeys[1])
									}} key={item.key} style={{justifyContent:"flex-start",marginTop:"0.55rem",position:"relative"}}>
										<ItemImg src={item.banner}/>
										<div style={{marginLeft:"0.55rem"}}>
											<ItemTitle># {item.title}</ItemTitle>
											<ItemText>{item.join_cont}人参与</ItemText>
										</div>
										{(select1 === item.key ||select2 === item.key ) && <SelectImg src={SelectIcon}/>}
									</FlexBox>
								)
							})
						}
					</div>}
					
					
				</div>
				
				
			</div>
		
		</Container>
	);
};

export default SelectTopicModal;

