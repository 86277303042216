import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png";
import {useRefresh, useUserInfo} from "../../../hooks";
import {CascadePicker, DatePicker, DotLoading, ImageUploader, Picker, SpinLoading, Toast} from "antd-mobile";
import {formatDate, formatTime, getPickerDate} from "../../../utils";
import {useNavigate} from "react-router";
import uploadFile from "../../../utils/upload";
import Uploader from "../../../commen/Uploader";
import {editTuckInfo, editUserInfo, getUserInfo} from "../../../services";
import {useSearchParams} from "react-router-dom";
import {getToken} from "../../../utils/token";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top:  5.4rem;
`
const ItemContainer = styled.div`
    background-color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    height: 2.5rem;
    display: flex;
    width: 20.5rem;
`
const Avatar = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    border: 0.15rem solid #EFEFEF;
    object-fit: cover;
`
const ItemTitle = styled.div`
    font-size: 0.85rem;
    color: #181818;
`
const ArrowIcon = styled.img`
    width: 0.33rem;
    height: 0.58rem;
    margin-left: 0.83rem;
`
const InfoText = styled.div`
    font-size: 0.75rem;
    color: #828388;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 10rem;
`
const TuckInfo = () => {
	const [visible, setVisible] = useState(false)
	const [showDatePicker,setShowDatePicker] = useState(false)
	const [showLoading, setShowLoading] = useState(false)
	const [info,setInfo] = useState({})
	const {refresh,setRefresh} = useRefresh()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams();
	
	// 通过 get() 方法获取特定查询参数的值
	const xkToken = searchParams.get('xkToken')
	useEffect(() => {
		getUserInfo({__token:xkToken?xkToken:getToken()}).then(res => {
			if (res.data.isSucc) {
				res.data.res.user.vehicle.version = res.data.res.user.version
				setInfo(res.data.res.user.vehicle)
			}
		})
		
	},[refresh])
	
	function editInfo (data) {
		editTuckInfo({info:data}).then(res => {
			if (res.data.isSucc){
				setRefresh(!refresh)
				window.ta.track('motorcycle_type')
				Toast.show({
					icon: 'success',
					content: '修改成功',
				})
			}else {
				Toast.show({
					icon: 'error',
					content: '修改失败',
				})
			}
		}).catch(err => {Toast.show({
			icon: 'error',
			content: '修改失败',
		})})
	}
	const tuckColumns = [
		[
			{label: 'J7牵引车', value: 'J7牵引车'},
			{label: 'J6V牵引车', value: 'J6V牵引车'},
			{label: 'JH6牵引车', value: 'JH6牵引车'},
			{label: 'JK6载货车', value: 'JK6载货车'},
			{label: 'J6P牵引车', value: 'J6P牵引车'},
			{label: '悍V2.0自卸车', value: '悍V2.0自卸车'},
			{label: 'JH6载货车', value: 'JH6载货车'},
			{label: 'J7载货车', value: 'J7载货车'},
			{label: 'J6L载货车', value: 'J6L载货车'},
			{label: '其他类型', value: '其他类型'},
		],
	]
	
	return (
		<Container>
			<Picker
				defaultValue={info.vehicle_model}
				columns={tuckColumns}
				visible={visible}
				onClose={() => {
					setVisible(false)
				}}
				onConfirm={v => {
					setInfo({...info,vehicle_model:v})
					editInfo({...info,vehicle_model:v[0]})
				}}
			/>
			<DatePicker
				visible={showDatePicker}
				onClose={() => {
					setShowDatePicker(false)
				}}
				defaultValue={info.vehicle_buyTime?new Date(info.vehicle_buyTime):new Date(2008, 0, 1)}
				min={new Date(2000, 0, 1)}
				max={new Date()}
				precision='day'
				onConfirm={val => {
					setInfo({...info,vehicle_buyTime:new Date(val).getTime()})
					editInfo({...info,vehicle_buyTime:new Date(val).getTime()})
				}}
			/>
			{showLoading && <FlexBox style={{
				flexDirection: "column",
				position: "absolute",
				top: "3rem",
				'--size': '12px',
				zIndex: 99,
				color: "#1677ff"
			}}><SpinLoading color={'#1677ff'}/>
				<div style={{marginTop: "0.25rem"}}>上传中,请稍等...</div>
			</FlexBox>}
			<ItemContainer onClick={() => {
				setVisible(true)
			}} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>卡车型号</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.vehicle_model}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/change_tuck_number')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>车牌号</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.vehicle_carnumber}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/change_drivers_license')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>驾驶证</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.vehicle_driverlicense}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => {
				setShowDatePicker(true)
			}} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>购车时间</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.vehicle_buyTime?formatDate(info.vehicle_buyTime,'YYYY-MM-DD'):'还未选择购车时间~'}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			
			<ItemContainer onClick={() => navigate('/mine/change_tuck_store')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>购车4s店</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.vehicle_buyShop4s}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
		</Container>
	);
};

export default TuckInfo;
