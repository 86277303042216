import request from "../utils/request";
import {getToken} from "../utils/token";
import fRequest from "../utils/forumRequest";

const platform = 'xiaoka'



export const getLoginCode = (data) => {
	return request('/login/Login', {
		method: "POST",
		data: {
			__platform: platform,
			...data
		}
	})
}
export const loginCallback = (data) => {
	return request('/login/callback/Callback', {
		method: "POST",
		data: {
			__platform: platform,
			...data
		}
	})
}
export const reStart = (data) => {
	return fRequest('/admin/Restart', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const coinLogs = (data) => {
	return request('/user/query/Coin_logs', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getOssToken = (data) => {
	return request('/user/query/GetOssToken', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const shopList = (data) => {
	return request('/shop/query/shopList', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const exChange = (data) => {
	return request('/shop/CoverItem', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const exChangeLog = (data) => {
	return request('/user/query/Cover_logs', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const getUserInfo = (data) => {
	return request('/update/UserBase', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getAddressList = (data) => {
	return request('/user/query/Address_list', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const editAddress = (data) => {
	return request('/user/setup/Address_edit', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const addAddress = (data) => {
	return request('/user/setup/Address_push', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const deleteAddress = (data) => {
	return request('/user/setup/Address_del', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const editUserInfo = (data) => {
	return request('/user/setup/UserBase_edit', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getCode = (data) => {
	return request('/login/Login', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const editTuckInfo = (data) => {
	return request('/user/setup/Vehicle_edit', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const buyCarLog = (data) => {
	return request('/user/query/BuyCarLog', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}


export const getShopBanner = (data) => {
	return request('/shop/query/ShopBanner', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const getShopDetail = (data) => {
	return request('/shop/query/Shop_Item', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const getBrandOrTypes = (data) => {
	return request('/shop/query/truckShopTypes', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}


export const getShopType = (data) => {
	return request('/shop/query/Shop_type', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getTruchShop = (data) => {
	return request('/shop/query/TruchShop_brandAndtypes', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const readNameAuth = (data) => {
	return request('/user/auth/ReadNameAuth', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const readNameInfo = (data) => {
	return request('/user/query/ReadCardInfo', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getShareInfo = (data) => {
	return request('/share/PageInfo', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getShareGift = (data) => {
	return request('/share/GetGift', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const bindShareCode = (data) => {
	return request('/share/BindCode', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const getExcelConfig = (data) => {
	return request('/func/GetExecConfig', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}


export const getTaskInfo = (data) => {
	return request('/taskCenter/Info', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const signTask = (data) => {
	return request('/taskCenter/com/Sign', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const receiveGift = (data) => {
	return request('/taskCenter/GetTaskGift', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const checkImage = (data) => {
	return request('/func/CheckImage', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}

export const openBox = (data) => {
	return request('/taskCenter/OpenBox', {
		method: "POST",
		data: {
			__platform: platform,
			__token: getToken(),
			...data
		}
	})
}
export const backCoverShop = (data) => {
	return request('/shop/ctl/BackCoverShop', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
