import React from 'react';
import styled from "styled-components";

import Close from "../assets/images/base/close.png"
import {getEmote} from "../utils/getEmote";
import {emoteList} from "../utils/emoteList";
import FlexBox from "../commen/FlexBox";
import {useWindowSize} from "../utils/useWindowSize";
const Container = styled.div`
    padding: 1.5rem 0;
    display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	overflow-y: scroll;
	height: 14rem;
	background-color: #FFFFFF;
	position: relative;
    scrollbar-width: thin; /* 定义滚动条的宽度 */
    scrollbar-color: transparent transparent; /* 定义滚动条的颜色 */
    overflow-y:scroll;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track  {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`
const Item = styled.img`
	width: 2rem;
	height: 2rem;
    margin: 0.25rem;

`
const CloseIcon = styled.img`
    width: 1.5rem;
    height:1.5rem;
    padding: 0.25rem;
	margin-right: 2rem;
	cursor: pointer;
`
const Emote = ({callback,onClose,style}) => {
	
	const {width} = useWindowSize()
	return (
        <Container style={style}>
	        <FlexBox style={{position:"fixed",bottom:"15.5rem",backgroundColor:"#FFFFFF",height:"2rem",width:width-32,justifyContent:"flex-end"}}>
		        <CloseIcon onClick={onClose} src={Close}/>
	        </FlexBox>
	        
	        <div style={{marginTop:"0.5rem",maxWidth:width-32}}>
		        {emoteList.map((item,index) => {
			        return (
				        <Item onClick={() => callback(item)} src={getEmote(item.Img)} key={index}/>
			        )
		        })}
	        </div>
	        
        </Container>
    );
};

export default Emote;
