import React from 'react';
import styled from "styled-components";
import BackIcon from "../assets/images/forum/back.png"
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {clearToken} from "../utils/token";
import {useNavBtn} from "../hooks";
import {useWindowSize} from "../utils/useWindowSize";

const Container = styled.div`
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #121E34;
    position: fixed;
    width: 100%;
    top: 28px;
    background-color: #FFFFFF;
    z-index: 9;
`


const Title = styled.div`
    font-size: 1rem;
    color: #000000;
`

const Btn = styled.div`
    font-size: 0.81rem;
    color: ${props => props.color};
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

const CommunityNavBar = () => {
	const {pathname, search} = useLocation()
	const { width} =useWindowSize()
	const navigate = useNavigate()
	const {navBarBtn} = useNavBtn()
	const type = new URLSearchParams(search).get('type')
	const searchParams = new URLSearchParams(search);
	const dealerCode = searchParams.get('dealerCode')
	const getTitle = () => {
		if (pathname === '/community/forum' || pathname === '/community') {
			return '卡友社区'
		} else if (pathname.split('/').includes('detail')) {
			return '资讯详情'
		} else if (pathname === '/community/topic/post') {
			return '发帖'
		} else if (pathname === '/community/topic/vote') {
			return '发布话题'
		} else if (pathname === '/community/com_login') {
			if (dealerCode){
				return '注册小卡'
			}else {
				return '登录论坛'
			}
			
		} else if (pathname.split('/').includes('vote_detail')) {
			if (navBarBtn) {
				return navBarBtn.title
			} else {
				return ''
			}
			
		}
	}
	const onBack = () => {
		if (pathname === '/community/forum' || pathname === '/community') {
			clearToken()
			window.location.href = "uniwebview://close"
		} else if (type !== 'fromHome' && type !== 'fromVote' ) {
			if (pathname.split('/').includes('detail') || pathname.split('/').includes('topic')){
				navigate('/community', {replace: true})
			}else {
				navigate(-1)
			}
		} else {
			navigate(-1)
		}
	}
	const isAndroid = /Android/i.test(navigator.userAgent);
	const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
	return (
		<Container>
			{pathname.includes('com_login') || (width>800 && (pathname==='/community' || pathname === '/community/forum' ))?
				<div style={{width: '1.5rem', height: "1.5rem", paddingRight: "1rem"}}/>:
			<img src={BackIcon} onClick={onBack} style={{width: '1.5rem', height: "1.5rem", paddingLeft: "1rem"}}
			     alt=''/>}
			<Title>{getTitle()}</Title>
			{navBarBtn ?
				<div>
					{navBarBtn.action && <Btn onClick={navBarBtn.action} color={navBarBtn.color}>{navBarBtn.text}</Btn>}
					{!navBarBtn.action && <div style={{width: '1.5rem', height: "1.5rem", paddingRight: "1rem"}}/>}
				</div> : <div style={{width: '1.5rem', height: "1.5rem", paddingRight: "1rem"}}/>}
			
		</Container>
	);
};

export default CommunityNavBar;
