import React, {useState} from 'react';
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import {useModal} from "../../../../hooks";
import  Resizer  from 'react-image-file-resizer';
import FlexBox from "../../../../commen/FlexBox";
import {useWindowSize} from "../../../../utils/useWindowSize";

const ShareBtn = styled.div`
    width: 18rem;
    height: 2.5rem;
    background: #7A57EB;
    border-radius: 1.25rem;
	font-size: 0.875rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	position:absolute;
	bottom:2.5rem;
	:hover {
		filter: opacity(0.8);
	}
`
const AvatarModal = ({file,uploadFile,callback,setShowLoading}) => {
	const [editor, setEditor] = useState(null);
	const {setOpenModal} = useModal()
	const {height} = useWindowSize()
	const handleSave = () => {
		setShowLoading(true);
		if (editor) {
			const canvas = editor.getImage();
			canvas.toBlob((blob) => {
				// 将裁剪后的图像文件上传到服务器
				resizeAndUpload(blob);
			});
		}
	};
	const resizeAndUpload = (file) => {
		Resizer.imageFileResizer(
			file,
			128,
			128,
			'PNG',
			100,
			0,
			(resizedFile) => {
				// 将压缩后的图像文件上传到服务器
				uploadFile(resizedFile,callback,'img');
			},
			'blob'
		);
	};
	return (
		<FlexBox  onClick={(e) => {setOpenModal(false);e.stopPropagation()}} style={{flexDirection:"column",height: height,position:"relative",width:"18rem"}}>
			<AvatarEditor
				onClick={(e) => {e.stopPropagation()}}
				ref={(editor) => setEditor(editor)}
				image={file}
				width={164}
				height={164}
				border={1}
				color={[122, 87, 235]} // RGBA
				scale={1.5}
			/>
			<ShareBtn  onClick={(e) => {handleSave();e.stopPropagation()}}>保存</ShareBtn>
		</FlexBox>
	);
};

export default AvatarModal;
