import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Avatar, ImageViewer, InfiniteScroll, Toast} from 'antd-mobile';
import {useLocation, useNavigate, useParams} from 'react-router';
import FlexBox from '../../../commen/FlexBox';
import {useWindowSize} from '../../../utils/useWindowSize';
import {checkProhibitions, formatMDTime, formatTime, formatTimeText, throttling} from '../../../utils';
import {
	forumComment,
	forumGetComments,
	forumGetItem,
	like,
	searchTopic,
	unLike,
	voteSelect
} from "../../../services/forum";
import styled from "styled-components";
import {useModal, useNavBtn, useUserInfo} from "../../../hooks";
import ManageBar from "../components/ManageBar";
import CommentIcon from "../../../assets/images/forum/lt_icon_pinglun.png";
import Zan2 from "../../../assets/images/forum/lt_icon_zan_2.png";
import Zan1 from "../../../assets/images/forum/lt_icon_zan_1.png";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png";
import Emote from "../../../components/Emote";
import {emoteList} from "../../../utils/emoteList";
import {getEmote} from "../../../utils/getEmote";
import SelectIcon from "../../../assets/images/forum/selected_icon.png";
import {getToken} from "../../../utils/token";
import {useSearchParams} from "react-router-dom";
import {debounce} from "lodash";



const Container = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #030C29;
    padding-bottom: 1.125rem;
    border-bottom: 1px solid #F5F5F5;
`
const Name = styled.div`
    font-size: 0.75rem;
    margin-left: 0.5rem;
    color: #000000;
`

const Time = styled.div`
    font-size: 0.625rem;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    color: #828388;
`

const Title = styled.div`
    font-size: 1.125rem;
    margin-top: 1rem;
    font-weight: 600;
    word-break: break-all;
    word-wrap: break-word;
`
const Text = styled.div`
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3F4456;
    font-size: 0.875rem;
    min-width: 19.425rem;
    overflow-x: hidden;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items:center ;
	text-align: left;
    word-break: break-all;
    word-wrap: break-word;
`

const CommentBox = styled.div`
    background-color: #FFFFFF;
    padding: 0.75rem 0.75rem 1.5rem 0.75rem;
    color: #091023;
    border-radius: 0.625rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
`
const CommentText = styled.div`
    font-size: 0.875rem;
    margin-top: 0.625rem;
    padding-bottom: 1rem;
    margin-left: 2.75rem;
    color: #3F4456;
    word-break: break-all;
    word-wrap: break-word;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`
const InputBox = styled.div`
    position: fixed;
    bottom: 0;
    padding: 0.5rem 0;
    background-color: #DFDFDF;
    color: #AAAAAA;
`
const Textarea = styled.textarea`
    height: 1.75rem;
    border-radius: 0.5rem;
    border: 1px solid #b1b1b1;
    outline: none;
    font-size: 0.875rem;
    background-color: #DFDFDF;
    text-indent: 0.25rem;
    padding-top: 0.5rem;
    color: #3F4456;

    &::placeholder {
        color: #AAAAAA;
    }
`

const VoteContainer = styled.div`
    width: 17.12rem;
    background: #F4F6F9;
    border-radius: 0.63rem;
    padding: 1rem;
    position: relative;
`
const VoteItem = styled.div`
    background: #F7F7F7;
    border: 0.19px solid #E1E4E8;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    color: #3F4456;
    font-size: 0.75rem;
`
const VoteTitle = styled.div`
    font-size: 1rem;
    color: #3F4456;
`
const VoteType = styled.div`
    margin-top: 0.25rem;
    font-size: 0.625rem;
    color: #3F4456;
`
const CommentImg = styled.img`
    width: 1.19rem;
    height: 1.065rem;
`
const CountText = styled.div`
    font-size: 0.63rem;
    color: #828388;
    margin-left: 0.25rem;
    min-width: 0.5rem;
`
const PlayImg = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    position: absolute;
    top: 5.5rem;
    left: 9rem;
    background-color: #FFFFFF;
    z-index: 9;
`
const TopicTip = styled.div`
    font-size: 1rem;
    color: #7A57EB;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 10rem;
`

const SelectImg = styled.img`
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 2rem;
`
const VoteBtn = styled.div`
    margin-top: 1rem;
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 5rem;
    height: 2rem;
    border-radius: 1.3rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: ${props => props.filter};
`

const Detail = ({title}) => {
	const [visible, setVisible] = useState(false)
	const [data, setData] = useState({id: 0, images: [], title: '', selectInfo: {select: []},tags:[]})
	const [comments, setComments] = useState([])
	const [value, setValue] = useState('')
	const [page, setPage] = useState(0)
	const [showInput, setShowInput] = useState(false)
	const [max,setMax] = useState([])
	const [total, setTotal] = useState(0)
	const [showEmote, setShowEmote] = useState(false)
	const [selectId, setSelectId] = useState([])
	const [clicked, setClicked] = useState(false);
	const {width, height} = useWindowSize()
	const {userInfo} = useUserInfo()
	const params = useParams()
	const {setNavBarBtn} = useNavBtn()
	const {setModalContent, setOpenModal, setPosition} = useModal()
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const type =searchParams.get('type')
	const isTopPost = searchParams.get('isTopPost')
	const xkToken = searchParams.get('xkToken')
	
	useEffect(() => {
		window.scrollTo(0, 0)
		
	}, [])
	
	const getItem = () => {
		forumGetItem({id: params.id,__token:xkToken?xkToken:getToken()}).then(res => {
			if (res.data.isSucc) {
				const itemData = res.data.res.item.content
				res.data.res.item.content = itemData.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
				setData(res.data.res.item)
			}else {
				Toast.show({icon:'fail',content:'该帖子找不到啦~'})
				navigate(-1)
			}
		})
	}
	const imgViewerRef = useRef(null)
	useEffect(() => {
		if (getToken()){
			getItem()
		}
		
	}, [params.id,getToken()])
	

	useEffect(() => {
			setNavBarBtn({
				text: <img src={require('../../../assets/images/forum/nav_more.png')}
				           style={{width: '1.8rem',}}/>,
				color: '#B3B3B3',
				action: () => {
					setModalContent(<ManageBar isMine={data.author_id === userInfo.uuid} isTopPost={isTopPost} type={'post'} id={params.id}/>);
					setPosition('bottom');
					setOpenModal(true)
				},
				title: ''
			})
		return () => {
			setNavBarBtn(null)
		}
	}, [data])
	
	useEffect(() => {
		if (getToken()) {
			getComments()
		}
	}, [getToken()])
	useEffect(() => {
		window.addEventListener('resize', () => {
			if (window.screen.height - window.innerHeight > 200) {
				setShowEmote(false)
			}
		})
		return () => {
			window.removeEventListener('resize', () => {
			})
		}
		
	}, []);
	
	const contentRef = useRef(null)
	const handleClick = (index) => {
		// 处理点击事件
		imgViewerRef.current.swipeTo(index);
		setVisible(true);
	}
	
	useEffect(() => {
		if (data.content){
				if ( contentRef.current.querySelectorAll('video').length > 0) {
					const videos = contentRef.current.querySelectorAll('video');
					videos.forEach((video, index) => {
						video.addEventListener('play', (e) => {
							if (videos.length > 1 ) {
								videos.forEach(i => {
									if (i !== e.target){
										i.pause()
									}
								})
							}else {
								e.target.play();
							}
							
						});
					});
					
					return () => {
						videos.forEach(video => {
							video.removeEventListener('play', () => {
								// handleVideoClick(video)
							});
						});
					};
				}
		}
	}, [contentRef,data]);
	useEffect(() => {
		if (data.content) {
				if (contentRef.current.querySelectorAll('img').length > 0) {
					const imgTags = contentRef.current.querySelectorAll('img')
					const pTags = contentRef.current.querySelectorAll('p')
					
					pTags.forEach((p,index) => {
						if (p.querySelector('img') === null&& p.querySelector('video') === null) {
							// p标签下不包含img和video标签
							p.style.width = '100%';
							p.style.textAlign = 'left'
						}
					})
					imgTags.forEach((img,index) => {
						img.addEventListener('click', (e) => {
							handleClick(index)
							e.stopPropagation()
						})
					})
					
					return () => {
						imgTags.forEach((img,index) => {
							img.removeEventListener('click', (e) => {
								handleClick(index)
							})
						})
					}
				}
			
		}
	}, [contentRef.current,data.content])
	
	const getComments = () => {
		forumGetComments({id: params.id, page: page, limit: 10}).then(res => {
			if (res.data.isSucc) {
				setTotal(res.data.res.length)
				if (page === 0) {
					setComments(res.data.res.list)
				} else {
					setComments(value => [...value, ...res.data.res.list])
				}
			}
		})
	}
	const loadMore = () => {
		if (comments.length < total) {
			let nextPage = page + 1
			setPage(nextPage)
			return getComments()
		}
	}
	const commentRef = useRef(null)
	const textAreaRef = useRef(null)
	const onRefresh = useCallback(() => {
		getComments()
		setPage(0)
		getItem()
	}, [])
	const handSubmit = async () => {
		if (value.trim() === '') {
			setClicked(false)
			return Toast.show({icon: 'fail', content: '请输入内容或内容不能为空白',});
		}
		let res = await checkProhibitions(value)
		if (res.length > 0) {
			setClicked(false)
			return Toast.show({icon: 'fail', content: '发送失败,回复内容带有违禁词'})
		}
		setShowInput(false)
		forumComment({str: value, repe_id: data.id, author: data.author}).then(res => {
			if (res.data.isSucc) {
				onRefresh()
				//刷新后滚动到可视区域
				const h = commentRef.current?.offsetTop - height / 2
				window.scrollTo(0, h)
				setValue('')
				Toast.show({icon: 'success', content: '评论成功',})
				setClicked(false)
			} else {
				setClicked(false)
				Toast.show({icon: 'error', content: res.data.err,})
			}
		})
	}
	function getTotalNum(data) {
		let newArr = []
		for (let i = 0;i<data.length;i++ ){
			let arr = [...data[i].ids]
			newArr.push(arr)
		}
		let flattenedList = newArr.reduce((acc, val) => acc.concat(val), []);
		let uniqueList = [...new Set(flattenedList)];
		return uniqueList.length
	}
	const onVote = () => {
		if (data.selectInfo.selectids.includes(userInfo.uuid)) {
			return Toast.show({icon: 'fail', content: '你已经投过过票了',})
		}
		if (selectId.length < 1) {
			return Toast.show({icon: 'fail', content: '至少选择一项投票',})
		}
		if (new Date().getTime() > data.selectInfo.endTime && data.selectInfo.endTime !== -1) {
			return Toast.show({icon: 'fail', content: '投票已结束',})
		}
		voteSelect({item_id: data.id, select_id: selectId}).then(res => {
			if (res.data.isSucc) {
				getItem()
				Toast.show({icon: 'success', content: '投票成功',})
				
			} else {
				if (res.data.err.code === '50021') {
					return Toast.show({icon: 'fail', content: '投票已结束',})
				}
				Toast.show({icon: 'error', content: res.data.err,})
			}
		}).catch(err => {
		})
	}
	
	
	function zan() {
		if (data.like_me) {
			unLike({id: data.id}).then(res => {
				if (res.data.isSucc) {
					onRefresh()
				} else {
					Toast.show({icon: 'error', content: res.data.err,})
				}
			})
		} else {
			like({repe_id: data.id}).then(res => {
				if (res.data.isSucc) {
					onRefresh()
				} else {
					Toast.show({icon: 'error', content: res.data.err,})
				}
			})
		}
		
		
	}
	
	function parseTextWithEmotes(text) {
		const parts = text.split('/');
		const regex = /\[[^\s]+\]/;
		parts.map((part, idx) => {
			if (regex.test(part)) {
				const emote = emoteList.find(item => item.name === part)
				const emotePath = getEmote(emote ? emote.Img : '');
				const imgHtml = `<img src="${emotePath}" style="width: 1.5rem;height: 1.5rem;" alt="" />`
				parts.splice(idx, 1, imgHtml)
			} else {
				// 否则这个 part 是普通文本
				const textHtml = `<div style="font-size: 1rem">${part}</div>`
				parts.splice(idx, 1, textHtml)
			}
		});
		return parts.join('')
	}
	const handleSub = debounce(() => {
		// 处理按钮点击逻辑
		handSubmit()
		
	}, 1000);
	const toVote = async (item) => {
		if (type==='fromVote'){
			navigate(-1)
		}else {
			let res = await  searchTopic({key: item})
			if (res.data.res.max.title !== item) {
				return Toast.show({icon:'fail',content:"该话题已被删除"})
			}
			navigate('/community/vote_detail/' + item);
		}
	}
	return (
		<div id={'container'} onClick={(e) => {
			setShowInput(false);
			e.stopPropagation()
		}} style={{minHeight: height, paddingBottom: "5rem",marginTop:"5.35rem"}}>
			{visible && <div style={{position:"fixed",backgroundColor:"#000000",inset:0,height:height,zIndex:999}}/>}
			
			<Container>
				<FlexBox style={{justifyContent: 'flex-start', paddingBottom: '0.5rem'}}>
					<Avatar src={(data.head !== 'test' && data.head !== '') ? data.head : DefaultAvatar}
					        style={{'--border-radius': '2rem', '--size': '2rem'}}/>
					<div>
						<Name>{data.author}</Name>
						<Time>{data.create_time ? formatMDTime(data.create_time) : ''}</Time>
					</div>
				
				</FlexBox>
				<Title>{data.title}</Title>
				{
					data.tags?.map((item, index) => {
							return (
								<TopicTip onClick={  (e) => {
									e.stopPropagation();
									toVote(item)
								}} key={index}># {item}</TopicTip>
							)
					}
					
					)
				}
				<Text ref={contentRef}   dangerouslySetInnerHTML={{__html: data.content}}/>
				
					<ImageViewer.Multi
						ref={imgViewerRef}
						images={data.images}
						visible={visible}
						defaultIndex={0}
						onClose={() => {
							setVisible(false)
						}}
					/>
			
				{
					data.selectInfo &&
					<VoteContainer style={{marginTop: "0.5rem"}}>
						<VoteTitle>{data.selectInfo.title}</VoteTitle>
						<VoteType>{data.selectInfo.optionType === 0 ? '单选' : '多选'} {getTotalNum(data.selectInfo.select)}人参与 {data.selectInfo.endTime > 1 ? `截止时间:${formatTime(data.selectInfo.endTime)}` : ''}</VoteType>
						{data.selectInfo.select.map((item, index) => {
							
							return (
								<VoteItem onClick={() => {
									if (new Date().getTime() > data.selectInfo.endTime && data.selectInfo.endTime !== -1) {
										return;
									}
									
									if (!selectId.includes(item.id)) {
										if (data.selectInfo.optionType === 0) {
											setSelectId([item.id])
										} else {
											setSelectId((value) => [...value, item.id])
										}
										
									} else {
										let newIds = selectId.filter(i => i !== item.id)
										setSelectId(newIds)
									}
									
								}} key={index}>
									<div style={{
										position: 'absolute',
										left: "1rem",
										zIndex: 2,
										backgroundColor: item.ids.includes(userInfo.uuid) ? "#B5B5FF" : (data.selectInfo.selectids?.includes(userInfo.uuid) ? "#B3B3B3" : "#F7F7F7"),
										width: `${17.12 * (item.num / getTotalNum(data.selectInfo.select))}rem`,
										height: "2rem",
										borderRadius: "0.75rem"
									}}/>
									<FlexBox style={{zIndex: 5, width: "17.12rem", justifyContent: "space-between"}}>
										<div>{item.name}{item.ids.includes(userInfo.uuid) ? '\t(您已投票)' : ''}</div>
										
										{(data.selectInfo.selectids?.includes(userInfo.uuid) || new Date().getTime() > data.selectInfo.endTime) &&
										<div style={{marginRight: "1.5rem"}}>{item.num}票</div>}
										{
											(selectId.includes(item.id) || item.ids.includes(userInfo.uuid)) &&
											<SelectImg src={SelectIcon}/>
											
										}
									</FlexBox>
								</VoteItem>
							
							
							)
						})}
						{(new Date().getTime() < data.selectInfo.endTime || data.selectInfo.endTime === -1) &&
						<FlexBox>
							<VoteBtn
								filter={(data.selectInfo.selectids.includes(userInfo.uuid)) ? 'grayscale(100%)' : 'grayscale(0)'}
								onClick={onVote}>{data.selectInfo.selectids.includes(userInfo.uuid) ? '已投票' : '投票'}</VoteBtn>
						</FlexBox>}
					</VoteContainer>
				}
				<FlexBox style={{justifyContent: "flex-end", marginTop: '1rem'}}>
					<FlexBox onClick={(e) => {
						e.stopPropagation()
					}} style={{marginRight: "1rem"}}>
						<CommentImg src={CommentIcon}/>
						<CountText>{data.comment_num}</CountText>
					</FlexBox>
					<FlexBox onClick={(e) => {
						zan();
						e.stopPropagation()
					}}>
						<CommentImg src={data.like_me ? Zan2 : Zan1}/>
						<CountText>{data.like_num}</CountText>
					</FlexBox>
				</FlexBox>
			</Container>
			{(comments.length > 0) &&
			<CommentBox ref={commentRef}>
				<div style={{fontSize: "0.875rem", paddingBottom: '0.75rem', color: "#030C29"}}>评论</div>
				{comments.map((item, index) => {
					return (
						<div style={{marginTop: "0.35rem", borderBottom: "1px solid #F5F5F5",}} key={index}>
							<FlexBox style={{justifyContent:"space-between",alignItems:"flex-start"}}>
								<FlexBox style={{
									justifyContent: 'flex-start',
									alignItems: "flex-start",
									paddingBottom: '0.5rem',
									paddingTop: "0.35rem",
								}}>
									<Avatar src={(item.head !== 'test' && item.head !== '') ? item.head : DefaultAvatar}
									        style={{'--border-radius': '2rem', '--size': '2rem'}}/>
									<div>
										<Name>{item.author}</Name>
										<Time>{formatTimeText(item.time)}</Time>
									</div>
								</FlexBox>
								<img onClick={() => {setModalContent(<ManageBar onRefresh={onRefresh} isMine={item.uuid === userInfo.uuid} commentId={item.id} isTopPost={isTopPost} type={'post'} id={params.id}/>);
									setPosition('bottom');
									setOpenModal(true)}} style={{width:"1.25rem",height:"1.25rem"}} src={require('../../../assets/images/forum/more_icon.png')} alt=""/>
							</FlexBox>
							
							
							<CommentText dangerouslySetInnerHTML={{__html: parseTextWithEmotes(item.content)}}/>
						</div>
					)
				})}
			
			</CommentBox>}
			
			
			{showInput ?
				<FlexBox onClick={(e) => {
					e.stopPropagation()
				}} style={{
					justifyContent: 'flex-start',
					width: width-32,
					zIndex: 10,
					backgroundColor: "#FFFFFF",
					position: 'fixed',
					bottom: 0,
					padding: '0.5rem 1rem',
					flexDirection: "column"
				}}>
					<FlexBox style={{justifyContent: "flex-start", width: "100%"}}>
						<Textarea ref={textAreaRef} autoFocus={true} style={{width: width> 1200?width-180:'15rem', alignItems: "flex-start"}}
						          placeholder={'我有话要说...'} value={value}  onChange={(e) => {
							setValue(e.target.value)
						}}/>
						
						<img  onClick={() => {
							setTimeout(() => {
								setShowEmote(true)
							}, 50);
						}} src={require('../../../assets/images/base/emj_icon.png')}
						     style={{width: "1.75rem", height: "1.75rem", marginLeft: "0.5rem",cursor:"pointer"}} alt=""/>
						<div onClick={() => {
							if (!clicked) {
								// 处理点击事件的逻辑
								setClicked(true);
								handSubmit()
								// 将点击状态设置为 true，防止再次触发点击事件
								
							}
							
						}
						} style={{
							color: value !== "" ? "#FFFFFF" : "#B3B3B3",
							width: width>1200?64:"2.5rem",
							height: "1.75rem",
							background: value !== "" ? "#B5B5FF" : "#D3D3D3",
							textAlign: "center",
							lineHeight: "1.75rem",
							borderRadius: "0.5rem",
							marginLeft: "0.5rem",
							cursor:"pointer"
						}}>发表
						</div>
					</FlexBox>
					{showEmote && <Emote callback={(emote) => setValue(value + '/' + emote.name + '/')} onClose={() => {
						setShowEmote(false);
						textAreaRef.current.focus()
					}}/>}
				
				
				</FlexBox> :
				<InputBox onClick={(e) => {
					setShowInput(true);
					e.stopPropagation()
				}} style={{width:'100%', zIndex: 9, backgroundColor: "#F5F5F5"}}>
					<FlexBox style={{
						backgroundColor: "#DFDFDF",
						color: "#AAAAAA",
						flex: 1,
						height: "2.12rem",
						borderRadius: '0.825rem',
						margin:"0 1rem"
					}}>
						我有话要说...
					</FlexBox>
				</InputBox>}
			
			
			<InfiniteScroll loadMore={loadMore} threshold={50} hasMore={comments.length < total}/>
		</div>
	);
};

export default Detail;
