import React from 'react';
import styled from "styled-components";
import {useWindowSize} from "../../../utils/useWindowSize";

const Container = styled.div`
	overflow: hidden;

`
const Iframe = styled.iframe`
    padding-top:0.5rem;

`
const PrivacyPolicy = () => {
	const {width,height} = useWindowSize()
	return (
		<Container style={{maxHeight:height}}>
			<Iframe src="https://www.xktruck.com/private.html" frameborder={0} seamless="seamless" width={width} height={height} />
		</Container>
	);
};

export default PrivacyPolicy;

