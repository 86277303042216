import React, {useEffect, useState} from 'react';
import FlexBox from "../../../../commen/FlexBox";
import styled from "styled-components";
import {getShopType} from "../../../../services";
import {useNavigate} from "react-router-dom";
import {getToken} from "../../../../utils/token";


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`
const Title = styled.div`
    font-size: 1.25rem;
    color: #030C29;
`
const ItemTitle = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-top: 0.63rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp:2;
	//height: 2rem;
 	line-height: 1rem;
	min-height: 2rem;
    -webkit-box-orient: vertical;
    max-width: 9.38rem;
`
const Tips = styled.div`
    height: 0.73rem;
    background: #F5F5F5;
    border-radius: 0.21rem;
    font-size: 0.5rem;
    color: #828388;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.125rem 0.25rem;
    margin-top: 0.5rem;
`
const Price = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-left: 0.5rem;
`
const GTitle = styled.div`
    font-size: 0.81rem;
    color: #828388;
`
const CoinIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
`
const LivingSupplies = ({title, type, outList}) => {
	const key = title+type+'_list'
	const localList= localStorage.getItem(key)
	const [list, setList] = useState(outList ? outList :(localList?JSON.parse(localList):[]))
	const navigate = useNavigate()
	useEffect(() => {
		if (outList) {
			setList(outList)
		}
	},[outList])
	useEffect(() => {
		if (!outList && title && getToken() && !localList) {
			getShopType({type: title, classType: type}).then(res => {
				if (res.data.isSucc) {
					setList(res.data.res.list)
					localStorage.setItem(key,JSON.stringify(res.data.res.list))
				}
			})
		}
	}, [getToken(),outList,title])
	
	return (
		<div style={{marginTop:list.length >0? "1.5rem":"0"}}>
			{list.length > 0 && <Title>{title}</Title>}
			
			<Container>
				{list?.map((item, index) => {
					return (
						<FlexBox onClick={() => navigate('/store/store_details/' + item.id )}
						         style={{alignItems: "flex-start", flexDirection: 'column', marginTop: "1.06rem",justifyContent:"flex-start"}}
						         key={index}>
							<img style={{width: "9.38rem", height: "6.31rem", borderRadius: '0.25rem',objectFit:"cover"}} src={item.icon}
							     alt=""/>
							<ItemTitle>{item.name}</ItemTitle>
							{item.tags?.map((i, ind) => {
								return (
									<Tips style={{marginRight: "0.25rem"}} key={ind}>{i}</Tips>
								)
							})}
							<FlexBox style={{justifyContent: "flex-start", marginTop: "0.76rem"}}>
								<GTitle>兑换：</GTitle>
								<CoinIcon src={require('../../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
								<Price>{item.price}</Price>
							</FlexBox>
							{/*<FlexBox style={{justifyContent: "flex-start", marginTop: "0.52rem"}}>*/}
							{/*	<GTitle>购买：</GTitle>*/}
							{/*	<Price>¥ {item.rprice}</Price>*/}
							{/*</FlexBox>*/}
						</FlexBox>
					)
				})}
			
			</Container>
		</div>
	
	);
};

export default LivingSupplies;
