import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    display: flex;
`

const Home = () => {
	return (
		<Container>
		</Container>
	);
};

export default Home;
