import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CoinItem from "./components/CoinItem";
import {coinLogs} from "../../../services";
import {useWindowSize} from "../../../utils/useWindowSize";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5.5rem;
	background-color: #F8F8F8;
	padding-bottom: 3rem;
`

const CoinLogs = () => {
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(0)
	const {height} = useWindowSize()
	useEffect(() => {
		coinLogs().then(res => {
			if (res.data.isSucc) {
				setList(res.data.res.list)
			}
			
		})
	}, [])
	
	function loadMore() {
		if (list.length !== 0) {
			let count = page + 1
			setPage(count)
		}
	}
	
	return (
		<Container style={{minHeight:height-60}}>
			{
				list.map((item, index) => {
					return (
						<CoinItem data={item} key={index}/>
					)
				})
			}
			{/*<InfiniteScroll loadMore={loadMore} threshold={50} hasMore={list.length<total} />*/}
		</Container>
	);
};

export default CoinLogs;
