import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {formatDate, getUtcTime} from "../../../../utils";
import JdIcon from "../../../../assets/images/mine/gr_icon_jindou_l.png"
import DkIcon from "../../../../assets/images/mine/gr_icon_dianka_l.png"
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20.5rem;
    height: 4.729rem;
    background-color: #FFFFFF;
    border-radius: 0.86rem;
    border-bottom: 1px solid #F8F8F8;
`
const Title = styled.div`
    font-size: 0.876rem;
    color: #181818;
     word-break: break-all;
    word-wrap: break-word;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	margin-right: 1rem;
	font-weight: bold;
`
const Time = styled.div`
    font-size: 0.75rem;
    color: #828388;
    margin-top: 0.603rem;
`
const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.1rem;
	margin-right: 0.5rem;
`
const MCount = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-right: 0.25rem;
`
const ReItem = ({data,hot}) => {
	function getTitle() {
		switch (data.type) {
			case 1:
				return "充值";
			case 2:
				return "兑换点卡";
			case 3:
				return "兑换商品";
			case 4:
				return "礼物收入";
			case 5:
				return "礼物支出";
			case 6:
				return '房间收入';
			case 7:
				return '房间支出'
		}
	}
	return (
		<Container>
			<div>
				<Title>{getTitle()}</Title>
				<Time>{getUtcTime(data.createTime)}</Time>
			</div>
			<FlexBox style={{flexDirection:"column",alignItems:"flex-end"}}>
				<FlexBox style={{justifyContent:"flex-end"}}>
					<MCount>{data.transactionType===1?'+':'-'}{data.amount}</MCount>
					<MkIcon src={data.currencyType===2?DkIcon:JdIcon}/>
				</FlexBox>
				{/*{*/}
				{/*	data.type === 2 &&*/}
				{/*	<FlexBox  style={{justifyContent:"flex-end",marginTop:"0.125rem"}}>*/}
				{/*		<MCount>-{data.amount/15}</MCount>*/}
				{/*		<MkIcon src={JdIcon}/>*/}
				{/*	</FlexBox>*/}
 				{/*}*/}
			</FlexBox>
			
		</Container>
	);
};

export default ReItem;
