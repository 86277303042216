import React from 'react';
import {Navigate, useRoutes} from "react-router";
import CoinLogs from "../pages/store/coinlogs";
import ExchangeLogs from "../pages/store/exchangelogs";
import Store from "../pages/store";
import Home from "../pages/home";
import Forum from "../pages/community/forum";
import Detail from "../pages/community/detail";
import Topic from "../pages/community/topic";
import GiftExchange from "../pages/store/giftExhange";
import Community from "../pages/community";
import Mine from "../pages/mine";
import MineIndex from "../pages/mine/mineIndex";
import MineInfo from "../pages/mine/mineInfo";
import Address from "../pages/mine/address";
import EditAddress from "../pages/mine/editAddress";
import ChangeNickname from "../pages/mine/changeNickname";
import ChangePhone from "../pages/mine/changePhone";
import ChangeProfile from "../pages/mine/changeProfile";
import VoteDetail from "../pages/community/voteDetail";
import Download from "../pages/downLoad";
import BuyCarMark from "../pages/mine/buyCarMark";
import TuckInfo from "../pages/mine/tuckInfo";
import ChangeTuckNumber from "../pages/mine/changeTuckNumber";
import ChangeDriversLicense from "../pages/mine/changeDriversLicense";
import ChangeTuckStore from "../pages/mine/changeTuckStore";
import TuckHome from "../pages/store/tuckHome";
import StoreDetails from "../pages/store/storeDetails";
import ComLogin from "../pages/community/login";
import Certification from "../pages/mine/certification";
import Promote from "../pages/mine/promote";
import TaskCenter from "../pages/mine/taskCenter";
import Help from "../pages/help";
import HelpHome from "../pages/help/helpHome";
import HelpDetail from "../pages/help/helpDetail";
import HelpList from "../pages/help/helpList";
import About from "../pages/mine/about";
import UserAgreement from "../pages/mine/userAgreement";
import PrivacyPolicy from "../pages/mine/privacyPolicy";
import CancelAccount from "../pages/mine/cancelAccount";
import Wallet from "../pages/mine/wallet";
import CoinExchange from "../pages/mine/coinExchange";
import GbRecharge from "../pages/mine/gbRecharge";
import ReDetails from "../pages/mine/reDetails";
import GiftDetails from "../pages/mine/giftDetails";
import Message from "../pages/mine/message";
import MessageDetails from "../pages/mine/messageDetails";




const Routes = () => {
	
	const routers = [
		
		// {
		// 	path: '/',
		// 	element: <Navigate to={'/store'}/>,
		// },
		{
			path: '/store',
			element: <Store/>,
			children: [
				{
					path: '',
					element: <TuckHome/>
				},
				{
					path: 'tuck_home',
					element: <TuckHome/>
				},
				{
					path: 'gift_exchange',
					element: <GiftExchange/>
				},
				{
					path: 'coin_logs',
					element: <CoinLogs/>
				},
				{
					path: 'exchange_logs',
					element: <ExchangeLogs/>
				},
				{
					path: 'store_details/:id',
					element: <StoreDetails/>
				}
			]
		},
		{
			path: '/community',
			element: <Community/>,
			children: [
				{
					path: '',
					element: <Forum/>
				},
				{
					path: 'forum',
					element: <Forum/>
				},
				{
					path: 'detail/:id',
					element: <Detail/>
				},
				{
					path: 'topic/:type',
					element: <Topic/>
				},
				{
					path: 'vote_detail/:tag',
					element: <VoteDetail/>
				},
				{
					path: 'com_login',
					element: <ComLogin/>
				}
				
			]
		},
		{
			path: '/mine',
			element: <Mine/>,
			children: [
				{
					path: '',
					element: <MineIndex/>
				},
				{
					path: 'mine_info',
					element: <MineInfo/>
				},
				{
					path: 'address',
					element: <Address/>
				},
				{
					path: 'edit_address',
					element: <EditAddress/>
				},
				{
					path: 'change_nickname',
					element: <ChangeNickname/>
				},
				{
					path: 'change_phone',
					element: <ChangePhone/>
				},
				{
					path: 'change_profile',
					element: <ChangeProfile/>
				},
				{
					path: 'buy_car_mark/:id',
					element: <BuyCarMark/>
				},
				{
					path: 'tuck_info',
					element: <TuckInfo/>
				},
				{
					path: 'change_tuck_number',
					element: <ChangeTuckNumber/>
				},
				{
					path: 'change_drivers_license',
					element: <ChangeDriversLicense/>
				},
				{
					path: 'change_tuck_store',
					element: <ChangeTuckStore/>
				},
				{
					path: 'certification',
					element: <Certification/>
				},
				{
					path: 'promote',
					element: <Promote/>
				},
				{
					path: 'task_center',
					element: <TaskCenter/>
				},
				{
					path: 'about',
					element: <About/>
				},
				{
					path: 'user_agreement',
					element: <UserAgreement/>
				},
				{
					path: 'privacy_policy',
					element: <PrivacyPolicy/>
				},
				{
					path: 'cancel_account',
					element: <CancelAccount/>
				},
				{
					path: 'wallet',
					element: <Wallet/>
				},
				{
					path: 'coin_exchange',
					element: <CoinExchange/>
				},
				{
					path: 'gb_recharge',
					element: <GbRecharge/>
				},
				{
					path: 're_details',
					element: <ReDetails/>
				},
				{
					path: 'gift_details',
					element: <GiftDetails/>
				},
				{
					path: 'message',
					element: <Message/>
				},
				{
					path: 'message_details',
					element: <MessageDetails/>
				}
			]
		},
		{
			path: '/help',
			element: <Help/>,
			children: [
				{
					path: '',
					element: <HelpHome/>
				},
				{
					path: 'help_home',
					element: <HelpHome/>
				},
				{
					path: 'help_list',
					element: <HelpList/>
				},
				{
					path: 'help_detail',
					element: <HelpDetail/>
				}
			]
		},
		{
			path: '/download',
			element: <Download/>
		}
	]
	
	const element = useRoutes(routers)
	
	return (
		
		element
	
	);
};

export default Routes;
