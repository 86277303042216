import fRequest from "../utils/forumRequest";
import {getToken} from "../utils/token";

const token = getToken()
export const forumToken = (data) => {
	return fRequest('/user/query/GetOssToken', {
		method: "POST",
		__token: getToken(),
		...data
	})
}
export const forumLogin = (data) => {
	return fRequest('/user/Login', {
		method: "POST",
		data
	})
}


export const forumGetList = (data) => {
	return fRequest('/user/post/List', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const forumGetItem = (data) => {
	return fRequest('/user/GetItem', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const forumGetComments = (data) => {
	return fRequest('/user/GetComments', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const forumComment = (data) => {
	return fRequest('/user/Comment', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const forumPost = (data) => {
	return fRequest('/user/post/Create', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const getTopicList = (data) => {
	return fRequest('/user/topic/List', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const addTopic = (data) => {
	return fRequest('/admin/AddTopic', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const like = (data) => {
	return fRequest('/user/Like', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const unLike = (data) => {
	return fRequest('/user/post/UnLike', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const postSetTop = (data) => {
	return fRequest('/user/post/Top', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const deletePost = (data) => {
	return fRequest('/user/post/Del', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const voteSetTop = (data) => {
	return fRequest('/user/topic/Top', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const passCreate = (data) => {
	return fRequest('/admin/post/Pass_create', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}


export const voteSelect = (data) => {
	return fRequest('/user/Select_v2', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const searchTopic = (data) => {
	return fRequest('/user/topic/searchTopic', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const deleteTopic = (data) => {
	return fRequest('/admin/topic/Del', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}

export const pushPost = (data) => {
	return fRequest('/admin/post/Push', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}


export const cancelTop = (data) => {
	return fRequest('/admin/post/Del_top', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const delComment = (data) => {
	return fRequest('/user/DelComments', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const reportComment = (data) => {
	return fRequest('/user/comment/Report', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const reportTopic = (data) => {
	return fRequest('/user/topic/Report', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
export const reportPost = (data) => {
	return fRequest('/user/post/Report', {
		method: "POST",
		data: {
			__token: getToken(),
			...data
		}
	})
}
