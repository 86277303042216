import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {copyText} from "../../../utils";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const Logo = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    margin-top: 2rem;
	
`
const Title = styled.div`
    font-size: 0.85rem;
    color: #181818;
	font-weight: bold;
	margin-top: 1rem;
	max-width: 12rem;
	text-align: center;
`
const WBox  = styled.div`
	background-color: #FFFFFF;
	padding: 1rem;
	width: 18.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
	margin-top: 0.5rem;
	border-radius: 0.75rem;
`

const ItemTitle = styled.div`
    font-size: 0.85rem;
    color: #181818;
`
const ItemText = styled.div`
    font-size: 0.85rem;
    color: #7A57EB;
`
const ItemBtn = styled.div`
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 3rem;
    height: 1.5rem;
    border-radius: 1.3rem;
    font-size: 0.875rem;
    text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`
const InfoText = styled.div`
    font-size: 0.75rem;
    color: #828388;
	width: 20.5rem;
	text-align: left;
	margin-top: 2rem;
`
const CancelAccount = () => {
	const call = (phone) => {
		window.location.href = "tel:" + phone;
	}
    return (
        <Container>
	        <Logo src={require('../../../assets/images/mine/gr_icon_zhuxiao_notice.png')}/>
	        <Title>若您需要注销账号,请联系客服,预计在3-5个工作日内完成</Title>
	        <InfoText>警告: <span style={{color:"#FF5C5C"}}>注销后无法恢复,请谨慎操作</span> </InfoText>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between",width:"100%"}}>
			        <FlexBox>
				        <ItemTitle>24小时服务热线1:</ItemTitle>
				        <ItemText>02883958187</ItemText>
			        </FlexBox>
			        <ItemBtn onClick={() => call('02883958187')}>拨打</ItemBtn>
		        </FlexBox>
		        <FlexBox style={{justifyContent:"space-between",width:"100%",marginTop:"0.5rem"}}>
			        <FlexBox>
				        <ItemTitle>24小时服务热线2:</ItemTitle>
				        <ItemText>02883958293</ItemText>
			        </FlexBox>
			        <ItemBtn onClick={() => call('02883958293')}>拨打</ItemBtn>
		        </FlexBox>
		        <FlexBox style={{justifyContent:"space-between",width:"100%",marginTop:"0.5rem"}}>
			        <FlexBox>
				        <ItemTitle>24小时服务热线3:</ItemTitle>
				        <ItemText>18980557908</ItemText>
			        </FlexBox>
			        <ItemBtn onClick={() => call('18980557908')}>拨打</ItemBtn>
		        </FlexBox>
		        <FlexBox style={{justifyContent:"space-between",width:"100%",marginTop:"0.5rem"}}>
			        <FlexBox>
				        <ItemTitle>小卡科技邮箱地址:</ItemTitle>
				        <ItemText>kefu@xktruck.com</ItemText>
			        </FlexBox>
			        <ItemBtn onClick={() => copyText('kefu@xktruck.com')}>复制</ItemBtn>
		        </FlexBox>
	        </WBox>
        </Container>
    );
};

export default CancelAccount;
