import React, {useEffect, useState} from 'react';
import FlexBox from "../../../../commen/FlexBox";
import styled from "styled-components";
import TuckMenu from "./TuckMenu";
import {useModal} from "../../../../hooks";
import CheckModal from "./CheckModal";
import {useWindowSize} from "../../../../utils/useWindowSize";
import {getBrandOrTypes, getTruchShop} from "../../../../services";
import {getToken} from "../../../../utils/token";

const FTitle = styled.div`
    font-size: 0.87rem;
    color: #030C29;
`
const BrandItem = styled.div`
    width:3.25rem;
    height:3.25rem;
    background: ${props => props.select ? '#E0D7FF' : '#FFFFFF'};
    border: ${props => props.select ? '0.19px solid #B9A6F5' : '0.19px solid #E1E4E8'};
    border-radius: 0.42rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
`
const BrandLogo = styled.img`
    width: 2rem;
    height: 2rem;
`
const BrandName = styled.div`
    font-size: 0.56rem;
    color: #030C29;
    margin-top: 0.1rem;
`
const TuckType = styled.div`
    width: 3.75rem;
    height: 1.1rem;
    background: ${props => props.select ? '#E0D7FF' : '#FFFFFF'};
    border: ${props => props.select ? '0.19px solid #B9A6F5' : '0.19px solid #E1E4E8 '};
    border-radius: 0.42rem;
    text-align: center;
    color: ${props => props.select ? '#7A57EB' : '#3F4456'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
`
const ResetBtn = styled.div`
    width: 2rem;
    height: 1.1rem;
    background: #FFFFFF;
    border: 0.19px solid #E1E4E8;
    border-radius: 0.42rem;
    text-align: center;
    color: #3F4456;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.125rem;
`
const Buy = () => {
	const localSelectBrand = localStorage.getItem('selectedBrand')
	const localSelectType = localStorage.getItem('selectedType')
	const localTuckType = localStorage.getItem('tuckType')
	const localBrand = localStorage.getItem('brand')
	const [selectedBrand, setSelectedBrand] = useState(localSelectBrand?Number(localSelectBrand):-1)
	const [selectedType, setSelectedType] = useState(localSelectType?Number(localSelectType):-1)
	const [tuckType, setTuckType] = useState(localTuckType?localTuckType:'')
	const [brand, setBrand] = useState(localBrand?localBrand:'')
	const [brandList, setBrandList] = useState([])
	const [typeList, setTypeList] = useState([])
	const [scType, setScType] = useState([])
	const [list, setList] = useState([])
	const {openModal, setOpenModal, setPosition, setModalContent, setStyleOverlay, setStyleContent} = useModal()
	const {width, height} = useWindowSize()
	
	
	useEffect(() => {
		localStorage.setItem('selectedBrand',String(selectedBrand))
		localStorage.setItem('selectedType',String(selectedType))
		localStorage.setItem('tuckType',tuckType)
		localStorage.setItem('brand',brand)
		
	},[selectedBrand,selectedType,tuckType,brand])
	useEffect(() => {
		if (getToken()){
			getBrandOrTypes({}).then(res => {
				if (res.data.isSucc) {
					setBrandList(res.data.res.brand)
					setTypeList(res.data.res.types)
					setScType(res.data.res.types)
				}
			})
		}
		
	}, [brand, tuckType,getToken()])
	useEffect(() => {
		if (getToken()){
			if (tuckType !== '' || brand !== '') {
				getTruchShop({brand: brand, type: tuckType, classType: '2'}).then(res => {
					if (res.data.isSucc) {
						setList(res.data.res.list)
					}
				})
				
			}
		}
		
		
	}, [brand, tuckType,getToken()])
	useEffect(() => {
		if (!openModal) {
			setStyleOverlay({});
			setStyleContent({});
		}
	}, [openModal])
	
	return (
		<div>
			<div style={{
				top: "5rem",
				position: "fixed",
				backgroundColor: "#FFFFFF",
				height: "8rem",
				borderBottom: "1px solid #F3F3F3",
				width:"22.5rem",
				zIndex: 99,
				marginTop:28
			}}>
				<FlexBox style={{paddingTop: "1rem", justifyContent: "flex-start"}}>
					<FTitle>品牌</FTitle>
					<FlexBox style={{justifyContent: 'flex-start'}}>
						{brandList?.map((item, index) => {
							if (index < 5) {
								return (
									<BrandItem onClick={() => {
										setBrand(item.value);
										setSelectedBrand(index);
									}} select={selectedBrand === index} key={index}>
										<BrandLogo src={item.icon}/>
										<BrandName>{item.lable}</BrandName>
									</BrandItem>
								)
							}
							
						})}
					
					</FlexBox>
				</FlexBox>
				<FlexBox style={{paddingTop: "1rem", justifyContent: "flex-start"}}>
					<FTitle>类型</FTitle>
					<FlexBox style={{justifyContent: 'flex-start'}}>
						{typeList?.map((item, index) => {
							if (index < 4) {
								return (
									<TuckType onClick={() => {
										setTuckType(item.value);
										setSelectedType(index)
									}} select={selectedType === index} key={index}>{item.value}</TuckType>
								)
							}
							
						})}
					
					</FlexBox>
					{/*<TuckType onClick={(e) => {*/}
					{/*	setPosition('top');*/}
					{/*	setModalContent(<CheckModal callback={(tuckType, brand, tuckIndex, selectedBrand) => {*/}
					{/*		setTuckType(tuckType);*/}
					{/*		setBrand(brand);*/}
					{/*		setSelectedType(tuckIndex);*/}
					{/*		setSelectedBrand(selectedBrand)*/}
					{/*	}} brandList={brandList} typeList={typeList}/>);*/}
					{/*	setStyleOverlay({marginTop: "4.65rem"});*/}
					{/*	setStyleContent({inset: 'auto auto auto 50%', transform: 'translate(-50%, 0)'});*/}
					{/*	setOpenModal(true);*/}
					{/*	e.stopPropagation();*/}
					{/*}} select={false} style={{padding: "0 0.25rem"}}>更多选择</TuckType>*/}
					<ResetBtn style={{position: "absolute", right: "1.5rem"}} onClick={() => {
						setTuckType('');
						setBrand('');
						setSelectedBrand(-1);
						setSelectedType(-1);
						setList([])
					}}>重置</ResetBtn>
				</FlexBox>
			</div>
			<div style={{marginTop: "15.65rem", position: "relative"}}>
				{list.length > 0 && <TuckMenu outList={list} title={tuckType ? tuckType : brand} type={'2'}/>
				
				}
				{
					(selectedType < 0 && selectedBrand < 0) &&
					<div>
						{scType.map((item, index) => {
							return (
								<TuckMenu key={index} type={'2'} title={item.value}/>
							
							)
						})}
					</div>
				}
				{(list.length === 0) && <FlexBox style={{marginTop: "15rem"}}>没有更多了~</FlexBox>}
			
			</div>
		</div>
	);
};

export default Buy;
