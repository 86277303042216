import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ReItem from "../wallet/components/ReItem";
import NoData from "../wallet/components/NoData";
import {coinLogs} from "../../../services";
import {DatePicker, InfiniteScroll} from "antd-mobile";
import FlexBox from "../../../commen/FlexBox";
import JdIcon from "../../../assets/images/mine/gr_icon_jindou_l.png"
import GiftItem from "../wallet/components/GiftItem";
import {useWindowSize} from "../../../utils/useWindowSize";
import {beansTotal, giftEarningDetails, transactionDetails} from "../../../services/wallet";
import {useModal} from "../../../hooks";
import {formatAddMonth, getMonthStartAndEnd} from "../../../utils";
const Container = styled.div`
	background-color: #FFFFFF;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
`
const CIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const DownIcon = styled.img`
  width: 0.5rem;
  height:0.5rem;
	margin-left: 0.25rem;
`
const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.5rem;
	margin-right: 0.5rem;
`
const GiftDetails = () => {
	const [visible, setVisible] = useState(false)
	const [beansCount, setBeansCount] = useState(0)
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [monthStart,monthEnd] = getMonthStartAndEnd(new Date())
	const [startTime, setStartTime] = useState(monthStart)
	const month = new Date(startTime).getMonth() + 1
	const [endTime, setEndTime] = useState( monthEnd)
	const {height} = useWindowSize()
	useEffect(() => {
		beansTotal({start:startTime,end:endTime}).then(res => {
			if (res.data.code === 0) {
				setBeansCount(res.data.data.total?res.data.data.total:0)
			}
		})
	},[])
	
	useEffect(() => {
		if (page === 1){
			getList()
		}
		
	}, [startTime,endTime,page])
	
	function getList() {
		giftEarningDetails({page:page,limit:10,start:startTime,end:endTime}).then(res => {
			if (res.data.code === 0) {
				setTotal(res.data.data.total)
				if (page === 1){
					setList(res.data.data.list)
				}else {
					setList([...list,...res.data.data.list])
				}
			}
			
		})
	}
	
	function loadMore() {
		if (page * 10 > total) {
			return;
		}
		if (list.length < total) {
			let nextPage = page + 1
			setPage(nextPage)
			return getList()
		}
	}
	
	
	return (
		<Container style={{minHeight:height}}>
			<FlexBox style={{justifyContent:"space-between",position:"fixed",top:"4.25rem",backgroundColor:"#FFFFFF",padding:"0.5rem 0",width:"22.5rem",borderBottom:"1px solid #F8F8F8"}}>
				<FlexBox onClick={() => setVisible(true)}
				         style={{justifyContent: "flex-start",  marginLeft:"1rem"}}>
					<CIcon src={require('../../../assets/images/mine/gr_icon_date.png')}/>
					<div style={{marginLeft: "0.5rem"}}>{new Date(startTime).getFullYear().toString() + '-' + (month < 10 ? `0${month}` : month).toString()}</div>
					<DownIcon src={visible?require('../../../assets/images/mine/gr_icon_extend_2.png'):require('../../../assets/images/mine/gr_icon_extend_1.png')}/>
				</FlexBox>
				<FlexBox style={{marginRight:"1rem"}}>
					<div style={{marginLeft: "0.5rem"}}>{'本月礼物明细:'+beansCount}</div>
					<MkIcon src={JdIcon}/>
				</FlexBox>
			</FlexBox>
			<DatePicker
				visible={visible}
				onClose={() => {
					setVisible(false)
				}}
				min={new Date(2000, 0, 1)}
				max={new Date()}
				precision='month'
				confirmText={'确认'}
				cancelText={'取消'}
				onConfirm={val => {
					
					const [monthStart,monthEnd] = getMonthStartAndEnd(val)
					setStartTime(monthStart)
					setEndTime(monthEnd)
					setPage(1)
					
				}
				}
			/>
			<div style={{paddingBottom:"2rem",marginTop:"5.75rem"}}>
				{
					list.map((item, index) => {
						return (
							<GiftItem  data={item}  key={index}/>
						)
					})
				}
				{
					list.length === 0 && <NoData text={'没有任何收支记录'}/>
				}
			</div>
			<InfiniteScroll loadMore={loadMore} threshold={50} hasMore={list.length < total }/>
		</Container>
	);
};

export default GiftDetails;
