import React from 'react';
import styled from "styled-components";
import {useModal} from "../../../../hooks";

const Container = styled.div`
    width: 17.79rem;
    background: #FFFFFF;
    border-radius: 0.83rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
`
const Tip = styled.div`
    font-size: 0.876rem;
    color: #011012;
    position: absolute;
    top: 1.575rem;
    left: 2.75rem;
`

const Text = styled.div`
    font-size: 0.87rem;
    color: #181818;
    margin-top: 4.6rem;
`
const Title = styled.div`
    font-size: 1.13rem;
    color: #181818;
`


const Btn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.3rem;
    font-size: 1rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0.19px solid #7A57EB;
`
const NoExModal = ({text}) => {
	const {setOpenModal} = useModal()
	return (
		<Container>
			<Title>提示</Title>
			<Text>{text}</Text>
			<Btn onClick={() => setOpenModal(false)}  style={{marginTop: '4.48rem',}}>确定</Btn>
		</Container>
	);
};

export default NoExModal;
