import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {Dialog, Switch, Toast} from "antd-mobile";
import {useLocation} from "react-router-dom";
import {addAddress, deleteAddress, editAddress} from "../../../services";
import {useNavBtn, useRefresh} from "../../../hooks";
import {useNavigate} from "react-router";
import {areObjectsEqual, arePropertiesEqual, checkProhibition, isValidPhoneNumber} from "../../../utils";

const Container = styled.div`
    padding-top: 6.5rem;
`
const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 0.5rem 1.15rem;
    height: 2.5rem;
    width: 20.25rem;
`
const ItemTitle = styled.div`
    font-size: 0.85rem;
    color: #181818;
	min-width: 5rem;
`

const InfoText = styled.div`
    font-size: 0.75rem;
    margin-right: 1rem;
`
const TipsText = styled.div`
    font-size: 0.5rem;
    color: #828388;
`
const AddBtn = styled.div`
    background-color:${props => props.color} ;
    border: none;
    color: #FFFFFF;
    width: 18.043rem;
    height: 2.583rem;
    border-radius: 1.3rem;
    font-size: 0.93rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 1.813rem;
	z-index: 5;
`
const Input = styled.input`
    background-color: #FFFFFF;
    border: none;
    outline: none;
    font-size: 0.85rem;
   	width: 12rem;
    text-align: right;
	margin-left: 1rem;
`
const TextArea = styled.textarea`
    background-color: #FFFFFF;
    border: none;
    outline: none;
    font-size: 0.85rem;
    flex: 1;
	height: 5rem;
    text-align: right;
	margin-left: 1rem;
`
const Btn = styled.div`
    font-size: 0.81rem;
    color: #EB5757;
`
const EditAddress = () => {
	const [canSubmit,setCanSubmit] = useState(false)
	const {refresh,setRefresh} = useRefresh()
	const {state: {data}} = useLocation()
	const [addressInfo, setAddressInfo] = useState(data?{
		id:new Date().getTime(),
		name: data.name,
		phone: data.phone,
		address_area:data.address_area,
		address_more:data.address_more,
		isDefault: data.isDefault
	}:{
		id:new Date().getTime(),
		name:  '',
		phone:'',
		address_area:'',
		address_more:'',
		isDefault: false
	})
	const {setNavBarBtn} = useNavBtn()
	const navigate = useNavigate()
	const deleteItem = () => {
		Dialog.confirm({
			content: '确认删除当前地址吗?',
			onConfirm: () => {
				deleteAddress({id:data.id}).then(res => {
					if (res.data.isSucc){
						setRefresh(!refresh)
						Toast.show({icon:'success',content:'删除成功'})
						navigate(-1)
					}else {
						Toast.show({icon:'fail',content:'删除失败'})
					}
				})
			},
		})
		
	}
	useEffect(() => {
		if (data){
			setNavBarBtn({color:"#EB5757",text:'删除',action:deleteItem})
		}
		return () => {
			setNavBarBtn(null)
		}
	},[data])
	useEffect(() => {
		if (data){
			if (arePropertiesEqual(data,addressInfo,['id'])){
			     setCanSubmit(false)
			}else {
				 setCanSubmit(true)
			}
		}else if (Object.values(addressInfo).every(value => value !== '')){
			setCanSubmit(true)
		}
		
	},[addressInfo])
	const submit = async () => {
		let info = addressInfo
		if (addressInfo.name === ''){
			return Toast.show({icon:'fail',content:"请输入收货人姓名"})
		}
		let res = await checkProhibition(addressInfo.name)
		if (res.length > 0) {
			return Toast.show({icon:'fail',content:'修改失败,收货人姓名带有违禁词'})
		}
		if (addressInfo.phone === ''){
			return Toast.show({icon:'fail',content:"请输入手机号"})
		}
		
		if (!isValidPhoneNumber(addressInfo.phone)){
			return Toast.show({icon:'fail',content:"请检查手机号是否正确"})
		}
		info.phone = Number(info.phone)
		if (addressInfo.address_area === ''){
			return Toast.show({icon:'fail',content:"请输入地区"})
		}
		let resArea = await checkProhibition(addressInfo.address_area)
		if ( resArea.length > 0) {
			return Toast.show({icon:'fail',content:'修改失败,地区带有违禁词'})
		}
		if (addressInfo.address_more === ''){
			return Toast.show({icon:'fail',content:"请输入详细地址"})
		}
		let resMore = await checkProhibition(addressInfo.address_more)
		if (resMore.length > 0) {
			return Toast.show({icon:'fail',content:'修改失败,详细地址带有违禁词'})
		}
		if (data) {
			if (!canSubmit){
				return Toast.show({icon:'fail',content:"你还没有修改哦~"})
			}
			editAddress({id:data.id,info:info}).then(res => {
				if (res.data.isSucc){
					setRefresh(!refresh)
					navigate(-1)
					Toast.show({icon:'success',content:'修改成功'})
				}else {
					Toast.show({icon:'fail',content:"添加失败"})
				}
			}).catch(err => {Toast.show({icon:'fail',content:err})})
		}else {
			addAddress({info:info}).then(res => {
				if (res.data.isSucc){
					setRefresh(!refresh)
					navigate(-1)
					Toast.show({icon:'success',content:'添加成功'})
				}else {
					Toast.show({icon:'fail',content:"添加失败"})
				}
			}).catch(err => {Toast.show({icon:'fail',content:err})})
		}
		
		
	}
	return (
		<Container>
			<Item>
				<ItemTitle>收货人</ItemTitle>
				<Input maxLength={10} value={addressInfo.name} onChange={(e) => setAddressInfo({...addressInfo, name: e.target.value})}
				       placeholder={'请输入收货人姓名'} type={'text'}/>
			</Item>
			<Item style={{borderTop: "1px solid #FAFAFA"}}>
				<ItemTitle>手机号</ItemTitle>
				<Input value={addressInfo.phone} maxLength={11}
				       onChange={(e) =>{setAddressInfo({...addressInfo, phone:e.target.value })} }
				       placeholder={'请输入手机号'} type={'text'}/>
			</Item>
			<Item style={{borderTop: "1px solid #FAFAFA"}}>
				<ItemTitle>所在地区</ItemTitle>
				<Input maxLength={20} value={addressInfo.address_area} onChange={(e) => setAddressInfo({...addressInfo, address_area: e.target.value})}
				       placeholder={'请输入地区(最大20个字符)'} type={'text'}/>
			</Item>
			<Item style={{borderTop: "1px solid #FAFAFA",alignItems:"flex-start",height:"5rem"}}>
				<ItemTitle>详细地址</ItemTitle>
				<TextArea maxLength={50} value={addressInfo.address_more} onChange={(e) => setAddressInfo({...addressInfo, address_more: e.target.value})}
				       placeholder={'请输入详细地址(最大50个字符)'} type={'text'}/>
			</Item>
			<Item style={{borderTop: "1px solid #FAFAFA",}}>
				<div>
					<ItemTitle>设置默认地址</ItemTitle>
					<TipsText>提醒：兑换礼物优先使用该地址</TipsText>
				</div>
				<Switch defaultChecked={addressInfo.isDefault} onChange={(val) => setAddressInfo({...addressInfo,isDefault: val})}
				        style={{'--checked-color': "#7A57EB","--width":"4.25rem","--height":"1.58rem"}}/>
			</Item>
			<FlexBox>
				<AddBtn color={canSubmit?"#7A57EB":"#B3B3B3"} onClick={submit}>
					<div>保存</div>
				</AddBtn>
			</FlexBox>
		
		</Container>
	);
};

export default EditAddress;
