import React, {useState} from 'react';
import styled from "styled-components";
import {useWindowSize} from "../../utils/useWindowSize";
import {isWeixin} from "../../utils";
import {Toast} from "antd-mobile";


const Container = styled.div`
    display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
`
const DownloadBtn = styled.div`
    width: ${props => props.pc === 'pc' ? "260px":"20.5rem"};
    height:  ${props => props.pc === 'pc' ? '48px':"3.21rem"};
    background: #1B1F22;
    border-radius:${props => props.pc === 'pc' ? '12px':"0.75rem"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.pc === 'pc' ? '18px':"0.96rem"};
    color: #FFFFFF;
    line-height: ${props => props.pc === 'pc' ? 1:"3.21rem"};
	margin-top: ${props => props.pc === 'pc' ? '36px':"2.3rem"};
	cursor: pointer;
`

const Download = () => {
	const {height,width} = useWindowSize()
	const [showArrow,setShowArrow] = useState(false)

	function onDownApp() {
		if (isWeixin()){
			setShowArrow(true)
			return Toast.show({icon:'fail',content:"请在浏览器中打开"})
		}else {
			window.location.href = 'http://lulucar.fragranceplay.com/XiaoKa_v1.0.0.apk'
		}
	}
	const isPc = () => {
		return width > 600
	}
    return (
        <Container style={{height: height}}>
	        {(showArrow && !isPc() )&&
	        <div style={{position:"absolute",top:"1rem",right:"1rem"}}>
		        <img style={{width:"5rem",height:"5rem"}} src={require('../../assets/images/base/share_arrow.png')} alt=""/>
		        <div style={{width:"5rem",color:"#B3B3B3"}}>点击上方按钮，选择浏览器中打开...</div>
	
	        </div>}
		        <img  style={{width:isPc()?315:"13.126rem",height:isPc()?315:"13.126rem",borderRadius:isPc()?315:"13.126rem"}}
		              src={require('../../assets/images/download/_1.gif')} />
		        <img  style={{width:isPc()?272.5:"11.353rem",height:isPc()?38.5:"1.603rem",marginTop:isPc()?48:"3.5rem"}}
		              src={require('../../assets/images/download/show_word.png')} />
		        <DownloadBtn pc={isPc()?'pc':'mobile'} onClick={onDownApp}>
			        <img style={{width:isPc()?32:"2rem",height:isPc()?32:"2rem",marginRight:isPc()?24:"0.5rem"}} src={require('../../assets/images/base/icon_share_download.png')} alt=""/>
			        <div>下载应用</div>
		        </DownloadBtn>
        </Container>
    );
};

export default Download;

