import React from 'react';
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transform: translateY(50%);
`
const NoneIcon = styled.img`
	width: 8rem;
	height: 8rem;
	object-fit: cover;
`
const Text = styled.div`
    font-size: 1rem;
    color: #C4C5CA;
	margin-top: 1rem;
`
const NoData = ({text}) => {
    return (
        <Container>
	        <NoneIcon src={require('../../../../assets/images/mine/gr_img_none.png')}/>
	        <Text>{text}</Text>
        </Container>
    );
};

export default NoData;
