import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useWindowSize} from "../../../utils/useWindowSize";
import {getExcelConfig} from "../../../services";
import {useNavigate} from "react-router";
import {getToken} from "../../../utils/token";
import AITalk from "../../../assets/images/help/gb_btn_extend_chat.png"
import ComIcon from "../../../assets/images/help/gb_btn_extend_luntan.png"
import StoreIcon from "../../../assets/images/help/gb_btn_extend_shop.png"
import GameIcon from "../../../assets/images/help/gb_btn_extend_game.png"
import RoomIcon from "../../../assets/images/help/gb_btn_extend_chat_room.png"
import ShareIcon from "../../../assets/images/help/gb_btn_extend_share.png"
import MineIcon from "../../../assets/images/help/gb_btn_extend_geren.png"
import HelpIcon from "../../../assets/images/help/gb_btn_extend_help.png"
import {useLocation} from "react-router-dom";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
	padding-bottom:3rem;
`
const WBox = styled.div`
    width: 18.5rem;
	background-color: #FFFFFF;
	padding: 0.75rem 1rem;
    margin-top: 0.31rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 0.5rem;
	position:relative;
`
const Icon = styled.img`
	width: 1.5rem;
	height: 1.5rem;
	object-fit: cover;
	
`
const Title = styled.div`
	font-size: 0.75rem;
	color: #000000;
	margin-top: 0.25rem;
`
const Question = styled.div`
	font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    -webkit-line-clamp: 1; //行数
    display: -webkit-box; //特别显示模式
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.25rem;
`
const Back = styled.img`
    width: 0.5rem;
    height: 0.875rem;
    object-fit: cover;
	margin-left: 1rem;
`
const HelpHome = () => {
	const {height} = useWindowSize()
	const [list,setList] = useState([])
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const xkToken = searchParams.get('xkToken')
	useEffect(() => {
		getExcelConfig({key:'cloud_faq',__token:xkToken}).then(res => {
			if (res.data.isSucc){
				const filteredData =res.data.res.list.reduce((acc, curr) => {
					if (!acc[curr.type]) {
						acc[curr.type] = [];
					}
					acc[curr.type].push(curr);
					return acc;
				}, {});
				let newList = []
				for (let i in  filteredData) {
					newList.push(filteredData[i])
				}
				setList(newList)
			}
		})
	},[])
	const navigate = useNavigate()
	const getIcon = (type) => {
		switch (type) {
			case '数字人聊天':
				return AITalk
			case '论坛':
				return ComIcon
			case '物资站':
				return StoreIcon
			case '答题游戏':
				return GameIcon
			case '语聊房':
				return RoomIcon
			case '推广邀请':
				return ShareIcon
			case '个人中心':
				return MineIcon
			case '常见问题':
				return HelpIcon
		}
		
	}
    return (
        <Container style={{minHeight:height}}>
	        {
				list.map((item,index) => {
					return (
						<WBox onClick={() => navigate('/help/help_list',{state:{data:item}})} key={index}>
							<FlexBox style={{flexDirection:'column',width:"4rem"}}>
								<Icon src={getIcon(item[0].type)}/>
								<Title>{item[0].type}</Title>
							</FlexBox>
								<div style={{marginLeft:"1rem",width:"12rem"}}>
									{
										item.map((i,ind) => {
											if (ind < 3){
												return (
													<Question key={ind}>{i.q}</Question>
												)
											}
											
										})
									}
								</div>
							<Back src={require('../../../assets/images/mine/gr_icon_list_arrow.png')}/>
						
						</WBox>
					)
				})
	        }
        </Container>
    );
};

export default HelpHome;
