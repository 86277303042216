import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CheckTab from "../../community/forum/components/CheckTab";
import ReItem from "../wallet/components/ReItem";
import NoData from "../wallet/components/NoData";
import {useWindowSize} from "../../../utils/useWindowSize";
import {coinLogs} from "../../../services";
import {DatePicker, InfiniteScroll} from "antd-mobile";
import FlexBox from "../../../commen/FlexBox";
import {useModal} from "../../../hooks";
import FilterModal from "./components/FilterModal";
import {transactionDetails} from "../../../services/wallet";
import {formatAddMonth, getMonthStartAndEnd} from "../../../utils";

const Container = styled.div`
	background-color: #FFFFFF;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	
`
const CIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const DownIcon = styled.img`
  width: 0.5rem;
  height:0.5rem;
	margin-left: 0.25rem;
`
const ReDetails = () => {
	const [tabIndex, setTabIndex] = useState( 0)
	const [visible, setVisible] = useState(false)
	const [type,setType] = useState(0)
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [monthStart,monthEnd] = getMonthStartAndEnd(new Date())
	const [startTime, setStartTime] = useState(monthStart)
	const month = new Date(startTime).getMonth() + 1
	const [endTime, setEndTime] = useState( monthEnd)
	const {height} = useWindowSize()
	const checkData = ['点卡', '金豆']
	const {openModal, setOpenModal, setPosition, setModalContent, setStyleOverlay, setStyleContent} = useModal()
	useEffect(() => {
		if (page === 1){
			gitList()
		}
		
	}, [startTime,endTime,type,tabIndex,page])
	useEffect(() => {
		return () => {
			setOpenModal(false)
		}
	},[])
	function gitList() {
		transactionDetails({page:page,limit:10,currencyType:tabIndex===0?2:1,transactionType:type,start:startTime,end:endTime}).then(res => {
			if (res.data.code === 0) {
				setTotal(res.data.data.total)
				if (page === 1){
					setList(res.data.data.list)
				}else {
					setList([...list,...res.data.data.list])
				}
			}
			
		})
	}
	
	function loadMore() {
		if (page * 10 > total) {
			return;
		}
		if (list.length < total) {
			let nextPage = page + 1
			setPage(nextPage)
			return gitList()
		}
	}
	
	const getType  =() => {
		if (type === 0){
			return '全部'
		}else if (type === 2) {
			return '消费'
		}else if (type=== 1) {
			return '收入'
		}
	}
	
    return (
        <Container style={{minHeight:height}}>
	        <CheckTab style={{justifyContent:"center",width:"20.5rem",position:"fixed",top:"4.35rem",zIndex:97}} itemStyle={{margin:"0 3rem"}} data={checkData} defaultIndex={tabIndex} callback={(index) => {
				setPage(1)
		        setTabIndex(index)
			}}/>
	        <FlexBox style={{justifyContent:"space-between",position:"fixed",top:"7rem",backgroundColor:"#FFFFFF",paddingBottom:"0.5rem",width:"22.5rem"}}>
		        <FlexBox onClick={() => setVisible(true)}
		                 style={{justifyContent: "flex-start",  marginTop: "1rem",marginLeft:"1rem"}}>
			        <CIcon src={require('../../../assets/images/mine/gr_icon_date.png')}/>
			        <div style={{marginLeft: "0.5rem"}}>{new Date(startTime).getFullYear().toString() + '-' + (month < 10 ? `0${month}` : month).toString()}</div>
			        <DownIcon src={visible?require('../../../assets/images/mine/gr_icon_extend_2.png'):require('../../../assets/images/mine/gr_icon_extend_1.png')}/>
		        </FlexBox>
		        <FlexBox onClick={() => {
			        setPosition('top');
			        setModalContent(<FilterModal defaultType={type} callback={(index) => {
						setPage(1)
				       setType(index)
			        }} />);
			        setStyleOverlay({marginTop: "9.5rem"});
			        setStyleContent({inset: 'auto auto auto 50%', transform: 'translate(-50%, 0)'});
			        setOpenModal(!openModal);
		        }}
		                 style={{justifyContent: "flex-start", marginTop: "1rem",marginRight:"1rem"}}>
			        <div style={{marginLeft: "0.5rem"}}>{getType()}</div>
			        <DownIcon src={openModal?require('../../../assets/images/mine/gr_icon_extend_2.png'):require('../../../assets/images/mine/gr_icon_extend_1.png')}/>
		        </FlexBox>
	        </FlexBox>
	        <DatePicker
		        visible={visible}
		        onClose={() => {
			        setVisible(false)
		        }}
		        min={new Date(2000, 0, 1)}
		        max={new Date()}
		        precision='month'
		        confirmText={'确认'}
		        cancelText={'取消'}
		        onConfirm={val => {
			        const [monthStart,monthEnd] = getMonthStartAndEnd(val)
			        setStartTime(monthStart)
			        setEndTime(monthEnd)
			        setPage(1)
		        }
		        }
	        />
		        <div style={{paddingBottom:"2rem",marginTop:"9.75rem"}}>
			        {
				        list.map((item, index) => {
					        return (
						        <ReItem   data={item} key={index}/>
					        )
				        })
			        }
			        {
				        list.length === 0 && <NoData text={'没有任何收支记录'}/>
			        }
		        </div>
	        <InfiniteScroll loadMore={loadMore} threshold={50} hasMore={list.length < total }/>
        </Container>
    );
};

export default ReDetails;
