import React, {useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {useWindowSize} from "../../../../utils/useWindowSize";
import {Form, Input, Toast} from "antd-mobile";
import DeleteIcon from '../../../../assets/images/forum/lt_icon_post_detele_toupiao.png'
import {useModal} from "../../../../hooks";
import {generateRandomNumber} from "../../../../utils";


const Container = styled.div`
	background-color: #FFFFFF;
	overflow-y: scroll;
`
const CancelText = styled.div`
    font-size: 0.73rem;
    color: #AAAAAA;
`
const OKText = styled.div`
    font-size: 0.73rem;
    color: #7A57EB;
`
const BTitle = styled.div`
    font-size: 1rem;
    color: #020C18;
`
const ItemInput = styled.input`
	font-size: 0.81rem;
	color: #3F4456 ;
	border: none;
	outline: none;
	background-color: #F7F7F7;
`
const RemoveIcon = styled.img`
	width: 0.875rem;
	height: 0.875rem;
`
const VoteItem = styled.div`
    background: #F7F7F7;
    border: 0.19px solid #E1E4E8;
    border-radius: 0.75rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	margin-top: 0.3rem;
`
const AddItemText = styled.div`
    font-size: 0.81rem;
    color: #7A57EB;
	margin-left: 0.25rem;
`
const SelectBtn = styled.div`
    width: 3.626rem;
    height: 1.876rem;
    background: ${props => props.bgcolor};
    border:${props => props.border} ;
    border-radius: 0.75rem;
	color: ${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin-right: 0.5rem;
`
const AddVoteModal = ({callback}) => {
	const {height,width} = useWindowSize()
	const [voteTitle,setVoteTitle] = useState('')
	const [voteType,setVoteType] = useState(0)
	const [voteLifespan,setVoteLifespan] = useState(0)
	const {setOpenModal} = useModal()
	const [voteItems,setVoteItems] = useState([{id:generateRandomNumber(),content:'',},{id:generateRandomNumber(),content:''}])
	const addItem = (content) => {
		if (voteItems.length === 10){
			return Toast.show({icon:'fail',content:'最多10个选项'})
		}
		setVoteItems((value) => [...value,{id:generateRandomNumber(),content:content}])
	}
	const deleteItem = (id) => {
		if (voteItems.length < 2) {
			setVoteItems([])
		}else {
			let items = voteItems.filter(item => item.id !== id)
			setVoteItems(items)
		}
	}
	const changeContent = (value,id) => {
		let items = voteItems
		 items.forEach(item => {
			if (item.id === id) {
				item.content = value
			}
		})
		if (items){
			setVoteItems(items)
		}else {
			addItem(value)
		}
		
	}
	
	
	
	return (
		<Container style={{height: height-82,width: width}}>
			<div style={{padding:"1rem",position:"relative"}}>
				<FlexBox style={{justifyContent:"space-between",position:"fixed",top:0,width: '20.5rem',padding:"1rem 0",background:'#FFFFFF',zIndex:3}}>
					<CancelText onClick={() => setOpenModal(false)}>取消</CancelText>
					<BTitle>添加投票</BTitle>
					<OKText onClick={() => {
						if (voteTitle === ''){
							return Toast.show({icon:'fail',content:'请输入标题'})
						}
						if (voteItems.filter(item => item.content === '').length > 0) {
							return Toast.show({icon:'fail',content:'不能有空选项'})
						}
						if (voteItems.length < 2  ){
							return Toast.show({icon:'fail',content:'编辑选项不少于2个'})
						}
						const voteObj = {title:voteTitle,select:voteItems.map(item => item.content),optionType:voteType,endTime:voteLifespan}
						callback(voteObj);setOpenModal(false)}}>完成</OKText>
				</FlexBox>
				<FlexBox style={{justifyContent:'flex-start',marginTop:"2rem"}}>
					<div style={{color: "#000000", fontSize: "1rem"}}>标题</div>
					<div style={{color: "#AAAAAA", fontSize: "1rem",marginLeft:"0.21rem"}}>(必填)</div>
				</FlexBox>
				<Input
					style={{"--font-size": "0.875rem", '--color': "#000000",marginTop:"0.5rem"}}
					placeholder='请输入标题(5-30个字)'
					value={voteTitle}
					onChange={val => {
						setVoteTitle(val)
					}}
					maxLength={50}
				/>
				
				<FlexBox style={{justifyContent:'flex-start',marginTop:"1rem"}}>
					<div style={{color: "#000000", fontSize: "1rem"}}>选项</div>
					<div style={{color: "#AAAAAA", fontSize: "1rem",marginLeft:"0.21rem"}}>(每个选项限14个字)</div>
				</FlexBox>
				<div style={{marginTop:"0.5rem"}}>
				
					{voteItems.map((item,index) => {
						return (
							<VoteItem key={item.id}>
								<ItemInput id={item.id} maxLength={14} onChange={(e) => {changeContent(e.target.value,item.id)}} type={'text'} placeholder={'选项'}/>
								<RemoveIcon onClick={() => {deleteItem(item.id)}} src={DeleteIcon}/>
							</VoteItem>
						)
					})}
					{voteItems.length < 10 &&
					<VoteItem style={{justifyContent:"center"}}>
						<RemoveIcon src={require('../../../../assets/images/forum/lt_icon_post_add_toupiao.png')}/>
						<AddItemText onClick={() => {addItem('')}}>添加选项（剩余{10-voteItems.length}个选项）</AddItemText>
					</VoteItem>}
				</div>
				<div style={{color: "#000000", fontSize: "1rem",marginTop:"1rem"}}>投票类型</div>
				<FlexBox style={{justifyContent:'flex-start',marginTop:"1rem"}}>
					<SelectBtn onClick={() => setVoteType(0)} bgcolor={voteType===0?'#E0D7FF':'#F7F7F7'}
					           color={voteType===0?'#7A57EB':'#3F4456'}
					           border={voteType===0?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>单选</SelectBtn>
					<SelectBtn onClick={() => setVoteType(1)} bgcolor={voteType===1?'#E0D7FF':'#F7F7F7'}
					           color={voteType===1?'#7A57EB':'#3F4456'}
					           border={voteType===1?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>多选</SelectBtn>
				</FlexBox>
				<div style={{color: "#000000", fontSize: "1rem",marginTop:"1rem"}}>投票有效期</div>
				<FlexBox style={{justifyContent:'flex-start',marginTop:"1rem"}}>
					<SelectBtn onClick={() => setVoteLifespan(0)} bgcolor={voteLifespan===0?'#E0D7FF':'#F7F7F7'}
					           color={voteLifespan===0?'#7A57EB':'#3F4456'}
					           border={voteLifespan===0?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>永久</SelectBtn>
					<SelectBtn onClick={() => setVoteLifespan(1)} bgcolor={voteLifespan===1?'#E0D7FF':'#F7F7F7'}
					           color={voteLifespan===1?'#7A57EB':'#3F4456'}
					           border={voteLifespan===1?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>1天</SelectBtn>
					<SelectBtn onClick={() => setVoteLifespan(2)} bgcolor={voteLifespan===2?'#E0D7FF':'#F7F7F7'}
					           color={voteLifespan===2?'#7A57EB':'#3F4456'}
					           border={voteLifespan===2?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>7天</SelectBtn>
					<SelectBtn onClick={() => setVoteLifespan(3)} bgcolor={voteLifespan===3?'#E0D7FF':'#F7F7F7'}
					           color={voteLifespan===3?'#7A57EB':'#3F4456'}
					           border={voteLifespan===3?'0.13px solid #B9A6F5':'0.13px solid #E1E4E8'}>30天</SelectBtn>
				</FlexBox>
			</div>
			
		</Container>
	);
};

export default AddVoteModal;
