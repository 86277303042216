import React, {useEffect, useState} from 'react';
import FlexBox from "../../../../commen/FlexBox";
import styled from "styled-components";
import {useWindowSize} from "../../../../utils/useWindowSize";
import {useModal} from "../../../../hooks";

const Container = styled.div`
    background-color: #FFFFFF;
    width: 20.5rem;
    padding: 1rem;
`

const FTitle = styled.div`
    font-size: 0.87rem;
    color: #030C29;
    width: 2.5rem;
`
const BrandItem = styled.div`
    width: 4rem;
    height: 4rem;
    background: ${props => props.select ? '#E0D7FF' : '#FFFFFF'};
    border: ${props => props.select ? '0.19px solid #B9A6F5' : '0.19px solid #E1E4E8'};
    border-radius: 0.42rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
`
const BrandLogo = styled.img`
    width: 2.5rem;
    height: 2.5rem;
`
const BrandName = styled.div`
    font-size: 0.56rem;
    color: #030C29;
`
const TuckType = styled.div`
    width: 3.5rem;
    height: 1.1rem;
    background: ${props => props.select ? '#E0D7FF' : '#FFFFFF'};
    border: ${props => props.select ? '0.19px solid #B9A6F5' : '0.19px solid #E1E4E8 '};
    border-radius: 0.42rem;
    text-align: center;
    color: ${props => props.select ? '#7A57EB' : '#3F4456'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
`

const SubmitBtn = styled.div`
    width: 6.563rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    color: #FFFFFF;
`

const CheckModal = ({brandList, typeList, callback}) => {
	const localSelectBrand = localStorage.getItem('selectedBrand')
	const localSelectType = localStorage.getItem('selectedType')
	const localTuckType = localStorage.getItem('tuckType')
	const localBrand = localStorage.getItem('brand')
	

	const [tuckIndex, setTuckIndex] = useState(localSelectType?Number(localSelectType):-1)
	const {setOpenModal} = useModal()
	const [selectedBrand, setSelectedBrand] = useState(localSelectBrand?Number(localSelectBrand):-1)
	const [tuckType, setTuckType] = useState(localTuckType?localTuckType:'')
	const [brand, setBrand] = useState(localBrand?localBrand:'')
	
	useEffect(() => {
		localStorage.setItem('selectedBrand',String(selectedBrand))
		localStorage.setItem('selectedType',String(tuckIndex))
		localStorage.setItem('tuckType',tuckType)
		localStorage.setItem('brand',brand)
	},[selectedBrand,tuckIndex,tuckType,brand])
	return (
		<Container>
			<FlexBox style={{justifyContent: "space-between"}}>
				<FTitle>品牌</FTitle>
				<FlexBox style={{justifyContent: 'flex-start', flexWrap: "wrap"}}>
					{brandList.map((item, index) => {
						return (
							<BrandItem onClick={() => {
								setSelectedBrand(index);
								setBrand(item.value)
							}} select={selectedBrand === index} key={index}>
								<BrandLogo src={item.icon}/>
								<BrandName>{item.value}</BrandName>
							</BrandItem>
						)
					})}
				
				</FlexBox>
			</FlexBox>
			<FlexBox style={{paddingTop: "1rem", justifyContent: "flex-start", flexWrap: "wrap"}}>
				<FTitle>类型</FTitle>
				<FlexBox style={{justifyContent: 'flex-start'}}>
					{typeList.map((item, index) => {
						return (
							<TuckType onClick={() => {
								setTuckIndex(index);
								setTuckType(item.value)
							}} select={tuckIndex === index} key={index}>{item.value}</TuckType>
						)
					})}
				
				</FlexBox>
			</FlexBox>
			<FlexBox style={{marginTop: "0.5rem"}}>
				<SubmitBtn onClick={() => {
					callback(tuckType, brand, tuckIndex, selectedBrand);
					setOpenModal(false)
				}}>确定</SubmitBtn>
			</FlexBox>
		
		</Container>
	
	);
};

export default CheckModal;
