import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import HotIcon from "../../../../assets/images/mine/gr_icon_hot.png";
import JdIcon from "../../../../assets/images/mine/gr_icon_jindou_l.png";
import {format, formatDate, formatTime, getUtcTime} from "../../../../utils";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20.5rem;
    height: 4.729rem;
    background-color: #FFFFFF;
    border-radius: 0.86rem;
	border-bottom: 1px solid #F8F8F8;
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
	max-height: 1rem;
  max-width: 11.25rem;
`
const ItemIcon = styled.img`
  width: 2.75rem;
  height: 2.75rem;
`
const ItemTime = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`
const SIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.25rem;
    margin-right: 0.5rem;
`
const GiftItem = ({data}) => {
	const getName =() => {
		if (data.senderUserInfo){
			return data.senderUserInfo.name.length<4?data.senderUserInfo.name:data.senderUserInfo.name.substring(0,4)+'...'
		}else {
			return "匿名用户"
		}
		
	}
    return (
        <Container>
	        <FlexBox >
		        <ItemIcon src={'https://www.xktruck.com/gift_img/'+data.icon+'.png'}/>
		        <div style={{marginLeft:"0.7rem"}}>
			        <ItemText>{getName()} 赠送 {data.giftName}×{data.count?data.count:1}</ItemText>
			        <ItemTime style={{marginTop:"0.7rem"}}>{getUtcTime(data.createTime)}</ItemTime>
		        </div>
	        </FlexBox>
	        <FlexBox>
		        <ItemText>+ {data.giftType===2?data.heatValue:data.taxValue}</ItemText>
		        <SIcon src={data.giftType===2?HotIcon:JdIcon}/>
	        </FlexBox>
        </Container>
    );
};

export default GiftItem;
