import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png"
import {useModal, useRefresh, useUserInfo} from "../../../hooks";
import {useNavigate,useLocation} from "react-router";
import {clearToken, getToken} from "../../../utils/token";
import {Dialog, ImageViewer, Toast} from "antd-mobile";
import Emote from "../../../components/Emote";
import {postSetTop} from "../../../services/forum";
import {useWindowSize} from "../../../utils/useWindowSize";
const Container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const ItemContainer = styled.div`
	background-color: #FFFFFF;
	padding: 0.5rem 1.5rem;
	height: 2.5rem;
	display: flex;
	width: 20.5rem;
`
const Avatar = styled.img`
	width: 3.24rem;
	height: 3.24rem;
	border-radius: 3.24rem;
	border: 0.25rem solid #EFEFEF;
`
const UserName = styled.div`
	font-size: 1rem;
	color: #091023;
`
const UserTips = styled.div`
    font-size: 0.75rem;
    color: #828388;
	margin-top: 0.35rem;
`
const ItemIcon = styled.img`
	width: 1.5625rem;
	height: 1.5625rem;
`
const ItemText = styled.div`
	font-size: 1.125rem;
	color: #181818;
	margin-left: 1.5rem;
`
const ArrowIcon = styled.img`
	width: 0.33rem;
	height: 0.58rem;
`
const LogoutText = styled.div`
    font-size: 1rem;
    color: #181818;
`
const Btn = styled.div`
    margin: 5rem 1rem;
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 18.043rem;
    height: 2.583rem;
    border-radius: 1.3rem;
    font-size: 1rem;
    text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`
const shakeAnimation = keyframes`
  0% { transform: scale(1); }
  25% { transform: scale(1.125); }
  50% { transform: scale(1); }
  75% { transform: scale(1.125); }
  100% { transform: scale(1); }
`;
const RwIcon = styled.img`
	width: 3rem;
	height: 3rem;
	object-fit: cover;
`
const ARwIcon = styled.img`
    width: 3rem;
    height: 3rem;
    object-fit: cover;
	border-radius: 1.5rem;
	box-shadow: 0 0 5px 2px #FFCD0A;
    animation: ${shakeAnimation} 3s ease-in-out infinite; /* 将晃动动画应用到:hover状态 */
`
const RwText = styled.div`
    font-size: 0.68rem;
    color: #181818;
	margin-left: 0.75rem;
`
const InfoText = styled.div`
    font-size: 0.75rem;
    color: #828388;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 10rem;
	margin-right: 0.5rem;
`
const MessageBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
    max-width: 1.72rem;
	height: 1rem;
	background-color: #E43C3C;
	color: #FFFFFF;
	font-size: 0.75rem;
	position: absolute;
	top: 0;
	right: -0.25rem;
	border-radius: 1rem;
	padding:0 0.125rem;
`

const PlanIcon = styled.img`
	width: 1.75rem;
	height: 1.75rem;
 
`
const MineIndex = () => {
	const {userInfo} = useUserInfo()
	const navigate = useNavigate()
	const {height} = useWindowSize()
	const [visible, setVisible] = useState(false)
	const {setModalContent,setOpenModal,setPosition} = useModal()
	const isAndroid = /Android/i.test(navigator.userAgent);
	const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const guideTask = searchParams.get('guideTask')
	const [isGuideTask,setIsGuideTask] = useState(guideTask==='1')
	const logout = () => {
		Dialog.confirm({
			cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
			confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
			content: '是否退出登录?',
			onConfirm: () => {
				clearToken()
				window.location.href = "uniwebview://exitApp"
			},
		})
	}
	
    return (
        <Container>
	        <ImageViewer
		        capture={false}
		        accept={"image/*"}
		        image={(userInfo.heard !== 'test' && userInfo.heard !== '')?userInfo.heard:DefaultAvatar}
		        visible={visible}
		        onClose={() => {
			        setVisible(false)
		        }}
	        />
	        {isGuideTask &&
	        <div style={{position:"absolute",inset:0,width:'100%',height:'100%',backgroundColor:"rgba(0,0,0,0.5)",zIndex:1000}}>
		        <div style={{width:'23.5rem'}}>
			        <div onClick={() => {
				        if (isGuideTask) {
					        setIsGuideTask(false)
				        }
				        navigate('/mine/task_center')
			        }}  style={{backgroundColor:'#FFFFFF',width:"3rem",height:"3rem",borderRadius:"1.5rem",position:"absolute",top:"12rem",left:"1rem"}}>
				        <ARwIcon  src={require('../../../assets/images/mine/gr_icon_quest_center.png')}/>
			        </div>
			        <FlexBox style={{color:"#FFFFFF",fontSize:'1rem',position:"absolute",top:"16rem",left:"2rem",justifyContent:"flex-start"}}>
				        <div style={{fontSize:"1.25rem",fontWeight:"bold",marginRight:"1rem"}}>↑</div>
				        <div>点击任务按钮完成新手指引</div>
			        </FlexBox>
		        </div>
	        </div>}
	        {visible && <div style={{position:"fixed",backgroundColor:"#000000",inset:0,height:height,zIndex:999}}/>}
	        <ItemContainer style={{height:"4rem",}}>
		        <FlexBox style={{justifyContent:"flex-start",flex:1}}>
			        <Avatar onClick={() => setVisible(true)} src={(userInfo.heard !== 'test' && userInfo.heard !== '')?userInfo.heard:DefaultAvatar}/>
			        <div style={{marginLeft:'0.84rem',}}>
				        <UserName>{userInfo.name}</UserName>
				        <UserTips>{userInfo.signdesc}</UserTips>
			        </div>
		        </FlexBox>
	        </ItemContainer>
	
	        <ItemContainer style={{height:"5rem",marginTop:"0.5rem",alignItems:"center",justifyContent:"flex-start"}}>
		        <div onClick={() => {
			        navigate('/mine/task_center')
		        }} >
						 <RwIcon src={require('../../../assets/images/mine/gr_icon_quest_center.png')}/>
			        <RwText>任务</RwText>
		        </div>
		        <div style={{marginLeft:"0.75rem"}} onClick={() => navigate('/mine/wallet')} >
			        <RwIcon src={require('../../../assets/images/mine/gr_icon_wallet.png')}/>
			        <RwText>钱包</RwText>
		        </div>
		        {/*<div style={{marginLeft:"0.75rem"}} onClick={() => navigate('/mine/message')} >*/}
			    {/*    <FlexBox style={{width:"3rem",height:'3rem',position:"relative"}}>*/}
				{/*        <MessageBox>99+</MessageBox>*/}
				{/*        <img style={{width:"2.25rem",height:"1.875rem"}} src={require('../../../assets/images/mine/gr_icon_message.png')}/>*/}
			    {/*    </FlexBox>*/}
			    {/*    <RwText >消息</RwText>*/}
		        {/*</div>*/}
	        </ItemContainer>
	        
	        <ItemContainer style={{marginTop:"0.5rem"}} onClick={() => navigate('/mine/mine_info')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_actor_info.png")}/>
				        <ItemText>个人信息</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		        </FlexBox>
		        
	
	        </ItemContainer>
	        <ItemContainer style={{borderTop:"1px solid #FAFAFA"}} onClick={() => navigate('/mine/address')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_actor_location.png")}/>
				        <ItemText>地址管理</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		        </FlexBox>
	        </ItemContainer>
	        <ItemContainer style={{borderTop:"1px solid #FAFAFA"}} onClick={() => navigate('/mine/tuck_info?enterType=mine')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_truck_info.png")}/>
				        <ItemText>卡车信息</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		
		        </FlexBox>
	
	        </ItemContainer>
	        {userInfo.buyCarLogs?.length > 0 &&
	        <ItemContainer style={{borderTop:"1px solid #FAFAFA"}} onClick={() => navigate('/mine/buy_car_mark/no')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_buy_truck_record.png")}/>
				        <ItemText>提车纪念</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		
		        </FlexBox>
	
	        </ItemContainer>}
	        <ItemContainer style={{borderTop:"1px solid #FAFAFA"}} onClick={() => navigate('/mine/promote')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <ItemIcon src={require("../../../assets/images/mine/gr_icom_bonus_share.png")}/>
				        <ItemText>有奖推广</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
	     
		        </FlexBox>
	        
	        </ItemContainer>
	        <ItemContainer onClick={() => navigate('/mine/about')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_about.png")}/>
				        <ItemText>关于小卡</ItemText>
			        </FlexBox>
			        <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		        </FlexBox>
	        </ItemContainer>
	        <ItemContainer onClick={() => navigate('/mine/cancel_account')}>
		        <FlexBox style={{justifyContent:"space-between",flex:1}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <ItemIcon src={require("../../../assets/images/mine/gr_icon_zhuxiao.png")}/>
				        <ItemText>注销账号</ItemText>
			        </FlexBox>
				   <ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
		        </FlexBox>
	        </ItemContainer>
	        {(isAndroid || isiOS) && <Btn onClick={logout}>退出登录</Btn>}
        </Container>
    );
};

export default MineIndex;
