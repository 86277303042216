import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png";
import {useModal, useRefresh, useUserInfo} from "../../../hooks";
import {CascadePicker, DatePicker, DotLoading, ImageUploader, Picker, SpinLoading, Toast} from "antd-mobile";
import {formatDate, formatTime, getPickerDate} from "../../../utils";
import {useNavigate} from "react-router";
import uploadFile from "../../../utils/upload";
import Uploader from "../../../commen/Uploader";
import {checkImage, editUserInfo, getUserInfo, readNameInfo} from "../../../services";
import AvatarEditor from 'react-avatar-editor'
import AvatarModal from "./components/AvatarModal";
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const ItemContainer = styled.div`
    background-color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    height: 2.5rem;
    display: flex;
    width: 20.5rem;
`
const Avatar = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    border: 0.15rem solid #EFEFEF;
    object-fit: cover;
`
const ItemTitle = styled.div`
    font-size: 0.85rem;
    color: #181818;
`
const ArrowIcon = styled.img`
    width: 0.33rem;
    height: 0.58rem;
    margin-left: 0.83rem;
`
const InfoText = styled.div`
    font-size: 0.75rem;
    color: #828388;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 10rem;
`
const MineInfo = () => {
	const [visible, setVisible] = useState(false)
	const [showDatePicker,setShowDatePicker] = useState(false)
	const [showLoading, setShowLoading] = useState(false)
	const [pickerType, setPickerType] = useState(0)
	const [birthDate, setBirthDate] = useState('')
	const [info,setInfo] = useState({})
	const [dealer,setDealer] = useState({})
	const [idInfo,setIdInfo] = useState({})
	const {refresh,setRefresh} = useRefresh()
	const {setModalContent,setPosition,setOpenModal,setStyleOverlay} = useModal()
	useEffect(() => {
		getUserInfo({dealer:true}).then(res => {
			if (res.data.isSucc) {
				setInfo(res.data.res.user)
				setDealer(res.data.res.dealer)
				if (res.data.res.auth){
					readNameInfo({}).then(res => {
						if (res.data.isSucc){
							setIdInfo(res.data.res)
						}
					})
				}
				
			}
		})
		
	},[refresh])
	function editInfo (data) {
		editUserInfo({info:data}).then(res => {
			if (res.data.isSucc){
				setRefresh(!refresh)
				Toast.show({
					icon: 'success',
					content: '修改成功',
				})
			}else {
				Toast.show({
					icon: 'fail',
					content: '修改失败',
				})
			}
		}).catch(err => {Toast.show({
			icon: 'fail',
			content: '修改失败',
		})})
	}
	const sexColumns = [
		[
			{label: '男', value: '男'},
			{label: '女', value: '女'},
		],
	]
	const navigate = useNavigate()
	const callback = async (status, url) => {
		if (status === 200) {
			let res = await checkImage({url:url})
			if (res.data.isSucc){
				if (res.data.res.jude){
					setInfo({...info,heard:url})
					editInfo({...info,heard:url})
				}else{
					Toast.show({
						icon: 'fail',
						content: '图片带有违规信息',
					})
				}
				
			}else {
				Toast.show({
					icon: 'fail',
					content: '校验失败',
				})
			}
			
		} else {
			Toast.show({
				icon: 'fail',
				content: '上传失败',
			})
		}
		setOpenModal(false);
		setShowLoading(false)
	}
	
	const genderText = (gender) => {
		if (gender === 0){
			return '还未选择你的性别哦~'
		}else if (gender === 1){
			return '女'
		}else if (gender === 2){
			return '男'
		}
	}
	return (
		<Container>
			<Picker
				style={{height:"9.5rem"}}
				defaultValue={info.gender ===  1 ? '女':'男'}
				columns={sexColumns}
				visible={visible}
				onClose={() => {
					setVisible(false)
				}}
				onConfirm={v => {
					setInfo({...info,gender:v[0]==='男'?2:1})
					editInfo({...info,gender:v[0]==='男'?2:1})
				}}
			/>
			<DatePicker
				visible={showDatePicker}
				onClose={() => {
					setShowDatePicker(false)
				}}
				defaultValue={info.birthday?new Date(info.birthday):new Date(1980, 0, 1)}
				min={new Date(1949, 0, 1)}
				max={new Date()}
				precision='day'
				onConfirm={val => {
					const date = formatDate(val,'YYYY-MM-DD')
					setBirthDate(date)
					setInfo({...info,birthday:new Date(val).getTime()})
					editInfo({...info,birthday:new Date(val).getTime()})
				}}
			/>
			{showLoading && <FlexBox style={{
				flexDirection: "column",
				position: "absolute",
				top: "3rem",
				'--size': '12px',
				zIndex: 1000,
				color: "#7A57EB"
			}}><SpinLoading color={'#7A57EB'}/>
				<div style={{marginTop: "0.25rem"}}>上传中,请稍等...</div>
			</FlexBox>}
			<ItemContainer style={{marginTop: "0.5rem"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>头像</ItemTitle>
					
					<FlexBox  style={{justifyContent: "flex-end"}}>
						
						<Uploader accept={'image/*'} id={'avatar_img'} ac onSuccess={(file) => {
							if (file.type.includes('jfif')){
								return Toast.show({icon:'fail',content:'图片格式错误,建议上传png,jpg,jpeg格式图片'})
							}
							// if (file.size / 1024 > 2048){
							// 	return Toast.show({icon:'fail',content:'图片大小不能大于2M'})
							// }
							// setShowLoading(true);
							// uploadFile(file, callback,'img')
							setModalContent(<AvatarModal setShowLoading={setShowLoading} callback={callback} uploadFile={uploadFile} file={file}/>)
							setPosition('center')
							setStyleOverlay({backgroundColor:"#000000"})
							setOpenModal(true)
						}}>
							
							<Avatar src={(info.heard !== 'test' && info.heard !== '') ? info.heard : DefaultAvatar}/>
						</Uploader>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			
			
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/change_nickname')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>昵称</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.name}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => {
				setPickerType(0);
				setVisible(true)
			}} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>性别</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{genderText(info.gender)}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => {
				setPickerType(1);
				setShowDatePicker(true)
			}} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>出生日期</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.birthday?formatDate(info.birthday,'YYYY-MM-DD'):'还未选择你的生日哦~'}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/change_phone')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>手机号</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.phone}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/change_profile')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>个人简介</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{info.signdesc}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/certification',{state:{info:idInfo}})} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>实名认证</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{idInfo.id?idInfo.id:'去认证'}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>
			{dealer.name &&
			<ItemContainer  style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>经销商</ItemTitle>
					
					<FlexBox style={{justifyContent: "flex-end",}}>
						<InfoText>{dealer.name}</InfoText>
						<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
					</FlexBox>
				</FlexBox>
			</ItemContainer>}
		</Container>
	);
};

export default MineInfo;
