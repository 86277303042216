import {useContext} from "react";
import {GMContext} from "../contexts";

export const useIsLogin = () => {
	const {isLogin, setIsLogin} = useContext(GMContext)
	return {isLogin, setIsLogin}
}

export const useModal = () => {
	const {openModal, setOpenModal, position, setPosition, modalContent, setModalContent,styleOverlay,setStyleOverlay,styleContent,setStyleContent,} = useContext(GMContext)
	return {openModal, setOpenModal, position, setPosition, modalContent, setModalContent,styleOverlay,setStyleOverlay,styleContent,setStyleContent}
}

export const useUserInfo = () => {
	const {userInfo, setUserInfo} = useContext(GMContext)
	return {userInfo, setUserInfo}
}

export const useRefresh = () => {
	const {refresh, setRefresh} = useContext(GMContext)
	return {refresh, setRefresh}
}

export const useNavBtn = () => {
	const {navBarBtn,setNavBarBtn} = useContext(GMContext)
	return {navBarBtn,setNavBarBtn}
}
export const useIsPermission = () => {
	const {isPermission,setIsPermission} = useContext(GMContext)
	return {isPermission,setIsPermission}
}

