import React from 'react';
import styled from "styled-components";
import BackIcon from "../assets/images/forum/back.png"
import {useLocation, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {clearToken} from "../utils/token";
import {useNavBtn} from "../hooks";
import FlexBox from "../commen/FlexBox";
import {throttling} from "../utils";

const Container = styled.div`
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top:28px;
    background-color: #FFFFFF;
    z-index: 9;
`


const Title = styled.div`
    font-size: 1rem;
    color: #000000;
`

const Btn = styled.div`
    width: 2.5rem;
	height: 1.5rem;
    font-size: 0.8rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    color:${props => props.color};
	&:hover {
		filter: opacity(0.5);
	}
`
const MineNavbar = () => {
	const {pathname,state} = useLocation()
	const navigate = useNavigate()
	const {navBarBtn} = useNavBtn()
	const [searchParams] = useSearchParams();
	
	// 通过 get() 方法获取特定查询参数的值
	const enterType = searchParams.get('enterType')
	const getTitle = () => {
		if (pathname === '/mine') {
			return '个人中心'
		}else if (pathname === '/mine/mine_info') {
			return '个人信息'
		}else if (pathname === '/mine/address' ) {
			return '地址管理'
		}else if (pathname === '/mine/edit_address'){
			return '编辑地址'
		}else if (pathname === '/mine/change_nickname'){
			return '修改昵称'
		}else if (pathname === '/mine/change_phone'){
			return '手机号'
		}else if (pathname === '/mine/change_profile'){
			return '个人简介'
		}else if (pathname.includes('/mine/buy_car_mark')) {
			return '提车纪念'
		}else if (pathname === '/mine/tuck_info') {
			return '卡车信息'
		}else if (pathname === '/mine/change_tuck_number') {
			return '车牌号'
		}else if (pathname === '/mine/change_drivers_license') {
			return '驾驶证'
		}else if (pathname === '/mine/change_tuck_store') {
			return '购车4s店'
		}else if (pathname === '/mine/certification') {
			return '实名认证'
		}else if (pathname === '/mine/promote') {
			return '邀请推广'
		}else if (pathname === '/mine/task_center') {
			return '任务中心'
		}else if (pathname === '/mine/about') {
			return '关于小卡'
		}else if (pathname === '/mine/user_agreement'){
			return '用户协议'
		}else if (pathname === '/mine/privacy_policy'){
			return '隐私政策'
		}else if (pathname === '/mine/cancel_account'){
			return '注销账号'
		}else if (pathname === '/mine/wallet'){
			return '钱包'
		}else if (pathname === '/mine/coin_exchange'){
			return '点卡兑换'
		} else if (pathname === '/mine/gb_recharge'){
			return '金豆充值'
		}else if (pathname === '/mine/re_details'){
			return '收支明细'
		}else if (pathname === '/mine/gift_details'){
			return '礼物明细'
		}else if (pathname === '/mine/message') {
			return '消息'
		}else if (pathname === '/mine/message_details') {
			if (state.type === 0 ) {
				return '收到的赞'
			}else {
				return '回复我的'
			}
		}
	}
	
	const onBack = () => {
		if (pathname === '/mine') {
			clearToken()
			window.location.href = "uniwebview://close"
		} else if (pathname.includes('tuck_info')) {
			navigate('/mine',{replace:true})
		} else if (pathname.includes('mine_info')) {
			navigate('/mine',{replace:true})
		} else if (pathname.includes('task_center')) {
			navigate('/mine',{replace:true})
		} else if (pathname.includes('address')) {
			navigate('/mine',{replace:true})
		}else if (pathname.includes('certification')) {
			navigate('/mine',{replace:true})
		}else if (pathname.includes('promote')) {
			navigate('/mine',{replace:true})
		}else if (pathname.includes('about')) {
			navigate('/mine',{replace:true})
		} else if (pathname.includes('cancel_account')) {
			navigate('/mine',{replace:true})
		}else if (pathname.includes('buy_car_mark') && pathname.indexOf('no') < 0){
			navigate('/mine',{replace:true})
		}else {
			navigate(-1)
		}
	}
	return (
		<Container>
			<FlexBox onClick={onBack} style={{padding:"0.5rem 1rem"}}>
				<img src={BackIcon}  style={{width: '1.5rem', height: "1.5rem", }} alt=''/>
				
			</FlexBox>
			
			<Title>{getTitle()}</Title>
			<FlexBox style={{padding:"0.25rem 0.5rem",width: '2.5rem', height: "1.5rem"}}>
				{navBarBtn ? <Btn onClick={() => throttling(navBarBtn.action,1500)} color={navBarBtn.color}>{navBarBtn.text}</Btn>:
					<div />}
				{/*{navBarBtn}*/}
			</FlexBox>
		</Container>
	);
};

export default MineNavbar;
