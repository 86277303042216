import { ImageUploadItem } from "antd-mobile";
import {forumToken,} from "../services/forum";
import {generateRandomNumber} from "./index";
import {getOssToken} from "../services";

const OSS = require('ali-oss');
const oss_path = "https://bbs-xk.oss-cn-chengdu.aliyuncs.com/"

const tempToken = { accessKeyId: "", accessKeySecret: "", stsToken: "", expire: "" }
// 使用client来操作OSS...
 const uploadFile = async (file, callback,type,fileName) => {
  if (tempToken.accessKeyId?.length === 0) {
    const r = await getOssToken( {});
    tempToken.accessKeyId = r.data.res?.token.AccessKeyId
    tempToken.accessKeySecret = r.data.res?.token.AccessKeySecret
    tempToken.stsToken = r.data.res?.token.SecurityToken
    tempToken.expire = r.data.res?.token.Expiration
  }
  return new Promise(async (resove,reject) => {
    const store = new OSS({
      region: 'oss-cn-chengdu',
      accessKeyId: tempToken.accessKeyId,
      accessKeySecret: tempToken.accessKeySecret,
      stsToken: tempToken.stsToken,
      bucket: 'bbs-xk',
      refreshSTSToken: async () => {
        const info = await getOssToken( {});
        tempToken.accessKeyId = info.data.res?.token.accessKeyId
        tempToken.accessKeySecret = info.data.res?.token.accessKeySecret
        tempToken.stsToken = info.data.res?.token.stsToken
        return {
          accessKeyId: info.data.res?.token.accessKeyId,
          accessKeySecret: info.data.res?.token.accessKeySecret,
          stsToken: info.data.res?.token.stsToken
        }
      },
      refreshSTSTokenInterval: 300000
    });
	
    const name = fileName ? `${fileName}${type === 'img'?'.png':''}`: `${generateRandomNumber()}_${new Date().getTime()}${type === 'img'?'.png':''}`
    let res = await store.put(name, file);
	let url = `${oss_path}${name}`
	  if (res.res.status === 200){
		  callback(res.res.status,url)
		  resove({ url: url,state:res.res.status})
	  }else {
		  reject({ url: '',state:res.res.status})
	  }
   
  })
}

export default uploadFile;

