import React from 'react';
import styled from "styled-components";
import {Outlet} from "react-router";

const Container = styled.div`
`

const Mine = () => {
	
	return (
		<Container>
			<Outlet/>
		</Container>
	);
};

export default Mine;
