import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {getShopLable, getShopType} from "../../../../services";
import {useNavigate} from "react-router-dom";
import {getToken} from "../../../../utils/token";


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`
const Title = styled.div`
    font-size: 1.25rem;
    color: #030C29;
	margin-top: 1rem;
`
const ItemTitle = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-top: 0.63rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 9.38rem;
`
const Tips = styled.div`
    height: 0.73rem;
    background: #F5F5F5;
    border-radius: 0.21rem;
    font-size: 0.5rem;
    color: #828388;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.125rem 0.25rem;
    margin-top: 0.43rem;
`
const Price = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-top: 0.916rem;
`
const TuckMenu = ({title, type, outList}) => {
	const key = title+type+'_list'
	const localList= localStorage.getItem(key)
	const [list, setList] = useState(outList ? outList :(localList?JSON.parse(localList):[]))
	const navigate = useNavigate()

	useEffect(() => {
		if (outList) {
			setList(outList)
		}
	},[outList])
	useEffect(() => {
		if (!outList && title && getToken() && !localList) {
			getShopType({type: title, classType: type}).then(res => {
				if (res.data.isSucc) {
					setList(res.data.res.list)
					localStorage.setItem(key,JSON.stringify(res.data.res.list))
				}
			})
		}
		
	}, [getToken(),outList,title,localList])
	return (
		<div style={{borderBottom: list.length > 0?"1px solid #F5F5F5":'', paddingBottom:list.length > 0? "1rem":0,}}>
			{list.length > 0 && <Title>{title}</Title>}
			<Container>
				{list?.map((item, index) => {
					// if (item.typeTags.includes(title)){
					return (
						<FlexBox onClick={() => navigate('/store/store_details/' + item.id )}
						         style={{alignItems: "flex-start", flexDirection: 'column', marginTop: "1.06rem"}}
						         key={index}>
							<img style={{width: "9.38rem", height: "6.31rem", borderRadius: '0.25rem',objectFit:"cover"}} src={item.icon}
							     alt=""/>
							<ItemTitle>{item.name}</ItemTitle>
							{item.lables?.map((i, ind) => {
								return (
									<Tips style={{marginRight: "0.25rem"}} key={ind}>{i}</Tips>
								)
							})}
							<Price>参考价格: ¥ {item.rprice}万+</Price>
						</FlexBox>
					)
					// }
					
				})}
			
			</Container>
		</div>
	
	);
};

export default TuckMenu;
