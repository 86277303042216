import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {validatePositiveInteger} from "../../../utils";
import {Toast} from "antd-mobile";
import {exchangeCard, getWalletInfo} from "../../../services/wallet";
import {useUserInfo} from "../../../hooks";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

const Container = styled.div`
    padding-top: 5rem;
`
const WBox = styled.div`
	width: 20.5rem;
	background-color: #FFFFFF;
	padding: 1rem;
	margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
const Btn = styled.div`
    background-color: ${props => props.bg};
    border: none;
    color: #FFFFFF;
    width: ${props => props.width};
    height:${props => props.height} ;
    border-radius: 1.15rem;
    font-size: 1rem;
    text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Title = styled.div`
    font-size: 1rem;
    color: #091023;
	font-weight: bold;
	margin-left: 1rem;
	margin-top: 1rem;
`
const CountIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
	object-fit: cover;
	margin-left: 0.25rem;
`
const Count = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
	margin-left: 0.5rem;
`
const ExInput = styled.input`
	 border: none;
	outline: none;
	width: 10rem;
	font-size: 1rem;
	margin-left: 0.5rem;
`
const Tips = styled.div`
    font-size:0.76rem;
    color: #D04949;
	line-height: 1.125rem;
`
const CoinExchange = () => {
	const [data,setData] = useState({})
	const [goldCount,setGoldCount] = useState(100)
	const {userInfo} = useUserInfo()
	const navigate = useNavigate()
	useEffect(() => {
		getWalletInfo().then(res => {
			if (res.data.code === 0) {
				setData(res.data.data)
			}
		})
	},[])
	const setAll = () => {
		setGoldCount(data.beansBalance)
	}
	const getCoinCount = () => {
		if (goldCount < 100 || !validatePositiveInteger(goldCount) ){
			return 0
		}else {
			return (goldCount/10) * 150
		}
	}
	const submit = () => {
		if (!validatePositiveInteger(goldCount)){
			return Toast.show({icon:"fail",content:'请输入正确的金豆数量'})
		}
		if (data.beansBalance < goldCount){
			return Toast.show({icon:"fail",content:'金豆数量不足'})
		}
		if (goldCount < 100){
			return Toast.show({icon:"fail",content:'兑换的最小额度为100'})
		}
		exchangeCard({amount:Number(goldCount)}).then(res => {
			if (res.data.code === 0) {
				navigate(-1)
				Toast.show({icon:"success",content:'兑换成功'})
			
			}
		}).catch(err => {})
	}
    return (
        <Container>
	        <Title>我的金豆</Title>
	        <WBox>
		        <CountIcon src={require('../../../assets/images/mine/gr_icon_jindou_l.png')}/>
		        <Count>{data.beansBalance}</Count>
	        </WBox>
	        <Title>兑换金豆数量</Title>
	        <WBox style={{justifyContent:"space-between"}}>
		        <FlexBox>
			        <CountIcon src={require('../../../assets/images/mine/gr_icon_jindou_l.png')}/>
			        <ExInput value={goldCount} onChange={(e) => {
				        setGoldCount(e.target.value)
			        }} placeholder={'请输入兑换的金豆数量'} type={'text'} maxLength={12}/>
		        </FlexBox>
		       <Btn width={'3.75rem'} height={'1.75rem'} bg={'#7A57EB'} onClick={setAll}>全部</Btn>
	        </WBox>
	        <Title>获得点卡</Title>
	        <WBox>
		        <CountIcon src={require('../../../assets/images/mine/gr_icon_dianka_l.png')}/>
		        <Count>{getCoinCount()}</Count>
	        </WBox>
	        <div style={{marginTop:"1rem",marginLeft:"1rem"}}>
		        <Tips>注：</Tips>
		        <Tips>1.金豆兑换比例：10金豆=150点卡。</Tips>
		        <Tips>2.金豆兑换的最小额度为100。</Tips>
	        </div>
	        <FlexBox style={{position:"absolute",bottom:"2rem",marginLeft:"2.25rem"}}>
		        <Btn onClick={submit}  width={'18rem'} height={'2.5rem'} bg={goldCount >= 100?'#7A57EB':"#DFD8F5"} >立即兑换</Btn>
				
	        </FlexBox>
        </Container>
    );
};

export default CoinExchange;
