import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import PullIcon from "../../../assets/images/forum/lt_icon_more_tuisong.png"
import SetTop from "../../../assets/images/forum/lt_icon_more_zhiding.png"
import DeleteIcon from "../../../assets/images/forum/lt_icon_more_shanchu.png"
import ReportIcon from "../../../assets/images/forum/report_icon.png"
import FlexBox from "../../../commen/FlexBox";
import {useModal, useRefresh, useUserInfo} from "../../../hooks";
import {Dialog, Toast} from "antd-mobile";
import {
	cancelTop,
	delComment,
	deletePost,
	deleteTopic,
	postSetTop,
	pushPost, reportComment, reportPost, reportTopic,
	voteSetTop
} from "../../../services/forum";
import {useNavigate} from "react-router";
import {useWindowSize} from "../../../utils/useWindowSize";

const Container = styled.div`
    height: 6.25rem;
    background-color: #FFFFFF;
    z-index: 9;
    width: 20.5rem;
    padding: 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
`

const ItemIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
`
const Text = styled.div`
    font-size: 0.75rem;
    color: #828388;
    margin-top: 0.5rem;
`
const CancelText = styled.div`
    font-size: 1rem;
    color: #181818;
`
const ReportImg = styled.div`
	background-color:#F3F4F5;
    width: 2.5rem;
    height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
	border-radius: 2.5rem;
`

const ManageBar = ({id, type,isTopPost,isMine,commentId,onRefresh}) => {
	const {setOpenModal} = useModal()
	const navigate = useNavigate()
	const {userInfo} = useUserInfo()
	const {width} = useWindowSize()
	const {refresh,setRefresh} = useRefresh()
	const setTop = () => {
		if (type === 'post') {
			Dialog.confirm({
				cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
				confirmText:<div style={{fontSize:16,color:"#7A57EB"}}>确定</div>,
				content: '是否置顶此帖子',
				onConfirm: () => {
					postSetTop({id: id}).then(res => {
						if (res.data.isSucc) {
							Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
						} else {
							Toast.show({icon: 'fail', content: '提交失败'})
						}
					}).catch(err => {
					})
				},
			})
			
		}
		if (type === 'vote') {
			Dialog.confirm({
				content: '是否置顶此话题',
				cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
				confirmText:<div style={{fontSize:16,color:"#7A57EB"}}>确定</div>,
				onConfirm: () => {
					voteSetTop({id: id}).then(res => {
						if (res.data.isSucc) {
							Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
						} else {
							Toast.show({icon: 'fail', content: '提交失败'})
						}
					}).catch(err => {
					})
				},
			})
			
		}
		
	}
	
	const onDelete = () => {
		if (type === 'post') {
			if (commentId){
				Dialog.confirm({
					content: '是否删除此评论',
					cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
					confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
					onConfirm: () => {
						delComment({id: commentId,repe_id:id}).then(res => {
							if (res.data.isSucc) {
								onRefresh()
								setOpenModal(false)
								Toast.show({icon: 'success', content: '删除成功'})
							} else {
								Toast.show({icon: 'fail', content: '删除失败'})
							}
						}).catch(err => {
						})
					},
				})
			}else {
				Dialog.confirm({
					content: '是否删除此帖子',
					cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
					confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
					onConfirm: () => {
						deletePost({id: id}).then(res => {
							if (res.data.isSucc) {
								setOpenModal(false)
								setRefresh(!refresh)
								navigate(-1)
								if (isMine){
									Toast.show({icon: 'success', content: '删除成功'})
									
								}else {
									Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
								}
							} else {
								Toast.show({icon: 'fail', content: '删除失败'})
							}
						}).catch(err => {
						})
					},
				})
			}
			
			
		}
		if (type === 'vote') {
			Dialog.confirm({
				content: '是否删除此话题',
				cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
				confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
				onConfirm: () => {
					deleteTopic({id: id}).then(res => {
						if (res.data.isSucc) {
							setOpenModal(false)
							Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
						} else {
							Toast.show({icon: 'fail', content: '删除失败'})
						}
					}).catch(err => {
					})
				},
			})
			
		}
		
	}
	const onReport = () => {
		if (type === 'post') {
			if (commentId){
				Dialog.confirm({
					content: '是否举报此评论',
					cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
					confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
					onConfirm: () => {
						reportComment({comment_id: commentId,post_id:id}).then(res => {
							if (res.data.isSucc) {
								setOpenModal(false)
								Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
							} else {
								Toast.show({icon: 'fail', content: '举报失败'})
							}
						}).catch(err => {
						})
					},
				})
			}else  {
				Dialog.confirm({
					content: '是否举报此帖子',
					cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
					confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
					onConfirm: () => {
						reportPost({id: id}).then(res => {
							if (res.data.isSucc) {
								setOpenModal(false)
								Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
							} else {
								Toast.show({icon: 'fail', content: '举报失败'})
							}
						}).catch(err => {
						})
					},
				})
			}
			
			
		}
		if (type === 'vote') {
			Dialog.confirm({
				content: '是否举报此话题',
				cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
				confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
				onConfirm: () => {
					reportTopic({id: id}).then(res => {
						if (res.data.isSucc) {
							setOpenModal(false)
							Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
						} else {
							Toast.show({icon: 'fail', content: '举报失败'})
						}
					}).catch(err => {
					})
				},
			})
			
		}
		
	}
	const onPush = () => {
		Dialog.confirm({
			content: '是否推送此帖子',
			cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
			confirmText:<div style={{fontSize:16,color:"#7A57EB"}}>确定</div>,
			onConfirm: () => {
				pushPost({id:id}).then(res => {
					if (res.data.isSucc) {
						Toast.show({icon: 'success', content: '已提交后台,等待审核通过'})
					} else {
						Toast.show({icon: 'fail', content: '提交失败'})
					}
				}).catch(err => {
				})
			},
		})
		
	}
	const cancelTopPost = () => {
		Dialog.confirm({
			content: '是否取消置顶',
			cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
			confirmText:<div style={{fontSize:16,color:"#EB5757"}}>确定</div>,
			onConfirm: () => {
				cancelTop({id:id}).then(res => {
					if (res.data.isSucc) {
						setOpenModal(false)
						navigate(-1)
						Toast.show({icon: 'success', content: '已取消置顶'})
					} else {
						Toast.show({icon: 'fail', content: '取消失败'})
					}
				})
			},
		})
		
	}
	return (
		<Container style={{width:width>800? width-40 : "20.5rem"}}>
			<FlexBox style={{justifyContent: "flex-start", borderBottom: "1px solid #EBEBEB", paddingBottom: "1rem"}}>
				{(userInfo.server_leve > 0 && type === 'post' && !commentId) &&
				<FlexBox onClick={onPush} style={{marginRight: "1.25rem", cursor:'pointer',flexDirection: "column"}}>
					<ItemIcon src={PullIcon}/>
					<Text>推送</Text>
				</FlexBox>}
				{(userInfo.server_leve > 0  && !commentId) &&
				<FlexBox onClick={isTopPost==='1'?cancelTopPost:setTop} style={{marginRight: "1.25rem", cursor:'pointer', flexDirection: "column"}}>
					<ItemIcon src={SetTop}/>
					<Text>{isTopPost==='1'?'取消置顶':'置顶'}</Text>
				</FlexBox>}
				{(userInfo.server_leve > 0 || isMine)  &&
				<FlexBox onClick={onDelete} style={{marginRight: "1.25rem",  cursor:'pointer',flexDirection: "column"}}>
					<ItemIcon src={DeleteIcon}/>
					<Text>删除</Text>
				</FlexBox>}
				{(!isMine || (userInfo.server_leve > 0 && !isMine)) &&
				<FlexBox onClick={onReport} style={{marginRight: "1.25rem",  cursor:'pointer',flexDirection: "column"}}>
					<ReportImg>
						<img src={ReportIcon} style={{width:"1rem",height:"1rem"}} alt=""/>
					</ReportImg>
					<Text>举报</Text>
				</FlexBox>}
			</FlexBox>
			<FlexBox onClick={() => setOpenModal(false)} style={{marginTop: "0.35rem"}}>
				<CancelText>取消</CancelText>
			</FlexBox>
		</Container>
	);
};

export default ManageBar;
