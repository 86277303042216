import axios from 'axios'
import {getFToken, setFToken} from "./fToken";
import {BaseUrl} from "./baseUrl";

const baseURL = 'https://testserver.xktruck.com' + ':3100'

const fRequest = axios.create({
	baseURL:baseURL,//基准地址
	timeout:5000,
})
const token = getFToken()
//拦截请求
fRequest.interceptors.request.use((config)=>{
	config.headers["Content-Type"] = 'application/json'
	if (token){
		config.headers['Token'] = token
	}
	return config
})
//拦截响应
fRequest.interceptors.response.use((response)=>{
		return response
	},function (error){
		//对响应的错误做点什么
		return Promise.reject(error);
	}
)

export default fRequest
