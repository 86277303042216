import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import DefaultAvatar from "../../../assets/images/mine/gr_icon_head_default.png"
import {useModal} from "../../../hooks";
import ShareLinkModal from "./components/ShareLinkModal";
import SharePosterModal from "./components/SharePosterModal";
import {bindShareCode, getShareGift, getShareInfo} from "../../../services";
import {Toast} from "antd-mobile";
import BindModal from "./components/BindModal";
import {copyText} from "../../../utils";
import {getToken} from "../../../utils/token";
const Container = styled.div`
    padding-top:  4.75rem;
	padding-bottom: 5rem;
`
const Banner = styled.img`
	width: 22.5rem;
	height: 6.313rem;
	object-fit: cover;
`
const WBox = styled.div`
    width: 20.5rem;
	background-color: #FFFFFF;
	padding: 0.75rem 1rem;
    margin-top: 0.31rem;

`
const Title = styled.div`
	font-size: 1rem;
    color: #091023;
`
const ProBtn = styled.div`
    width: 5.13rem;
    height: 1.52rem;
    background: #7A57EB;
    border-radius: 0.72rem;
	font-size: 0.65rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	:hover {
		filter: opacity(0.8);
	}
`
const ProInfoBox = styled.div`
    width: 8.3rem;
    height: 2.71rem;
    background: #FFFFFF;
    border: 0.19px solid #F8F8F8;
    border-radius: 0.375rem;
	padding: 0.375rem 0.75rem;
`
const BoxTitle = styled.div`
    font-size: 0.63rem;
    color: #828388;
`
const BoxCount = styled.div`
    font-size:0.875rem;
    color: #000000;
	margin-left: 0.5rem;
`
const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
    margin-left: 0.1rem;
`
const ReceiveBtn = styled.div`
    width: 3.35rem;
    height: 1.52rem;
    background: ${props => props.bg};
    border-radius: 0.72rem;
	font-size: 0.65rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	:hover {
		filter: opacity(0.8);
	}
`
const CodeText = styled.div`
    font-size:0.75rem;
    color: #828388;
`
const RedCountText = styled.span`
    font-size: 0.75rem;
    color: #EB7A52;
`
const CodeBox  = styled.div`
    width: 20.5rem;
    height: 3.45rem;
    background: #FFFFFF;
    border: 0.19px solid #F8F8F8;
    border-radius: 0.375rem;
	display: flex;
	justify-content: center;
	align-items: center;
    font-size:1.25rem;
    color: #000000;
	margin-top: 0.81rem;
	flex-direction: column;
`
const ProUserBox = styled.div`
	width: 20.5rem;
	padding:1.25rem 0.25rem ;
	background-color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #EFEFEF;
`
const UserAvatar = styled.img`
    width: 1.625rem;
    height: 1.625rem;
	border-radius: 1.625rem;
	object-fit: cover;
`
const UserName = styled.div`
    font-size: 0.81rem;
    color: #181818;
	margin-left: 1rem;
`
const UserPhone = styled.div`
    font-size: 0.685rem;
    color: #181818;
`
const RuleText = styled.div`
	font-size: 0.75rem;
	color:#D04949;
	width: 20.5rem;
	margin-left: 1rem;
	margin-right: 1rem;
	margin-top: 1rem;
`
const ShareBtn = styled.div`
    width: 7.25rem;
    height: 2.5rem;
    background: #7A57EB;
    border-radius: 1.25rem;
	font-size: 0.875rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin:0 0.5rem;
	:hover {
		filter: opacity(0.8);
	}
`
const ProUserName = styled.div`
    font-size: 0.75rem;
    color: #828388;
	margin: 0 0.5rem;
`
const Promote = () => {
	const [data,setData] = useState({share_list:[]})
	const [reload,setReload] = useState(true)
	const {setModalContent,setPosition,setOpenModal} = useModal()
	useEffect(() => {
		if (getToken()){
			getShareInfo({}).then(res => {
				if (res.data.isSucc){
					setData(res.data.res)
				}
			})
		}
	},[reload,getToken()])
	const receive = () => {
		if (!getToken()){
			return;
		}
		if (data.share_gift === 0){
			return;
		}
		getShareGift({}).then(res => {
			if (res.data.isSucc){
				setReload(!reload)
				Toast.show({icon:'success',content:'领取成功'})
			}
		})
	}
	const onReload = useCallback(() => {
		setReload(!reload)
	},[])
	let pressTimer;

// 长按开始时触发的函数
	function startLongPress() {
		pressTimer = setTimeout(function () {
			// 这里是长按事件触发后要执行的代码
			copyText(data.share_code)
		}, 1000); // 长按的时间阈值，单位为毫秒（这里设置为1秒）
	}

// 长按结束时触发的函数
	function endLongPress() {
		clearTimeout(pressTimer);
	}
    return (
        <Container>
	        <Banner src={require('../../../assets/images/mine/gr_img_share_banner.png')}/>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>邀请人</Title>
			        
			        
			        {data.share_personInfo ?
			        <FlexBox>
				        <ProUserName>{data.share_personInfo.name}</ProUserName>
				        <ProUserName>{data.share_personInfo.phone}</ProUserName>
			        </FlexBox>:<ProBtn onClick={() => {setModalContent(<BindModal onReload={onReload}/>);setPosition('center');setOpenModal(true)}}>绑定邀请码</ProBtn>}
		        </FlexBox>
		        <FlexBox style={{justifyContent:"space-between",marginTop:"0.35rem"}}>
			        <ProInfoBox>
				        <BoxTitle>邀请人数</BoxTitle>
				        <BoxCount style={{marginTop:"0.625rem"}}>{data.share_person}</BoxCount>
			        </ProInfoBox>
			        <ProInfoBox>
				        <BoxTitle>邀请收益（点卡）</BoxTitle>
				        <FlexBox style={{justifyContent:"space-between",marginTop:"0.35rem"}}>
					        <FlexBox style={{justifyContent:"flex-start"}}>
						        <MkIcon src={require('../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
						        <BoxCount>{data.share_gift}</BoxCount>
					        </FlexBox>
					        
					        <ReceiveBtn onClick={receive} bg={data.share_gift > 0 ?'#69BD6F':"#BBBBBB"}>领取奖励</ReceiveBtn>
				        </FlexBox>
			        </ProInfoBox>
		        </FlexBox>
		        
	        </WBox>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>我的邀请码</Title>
			        <CodeText>每日邀请有奖：<RedCountText>{data.todayCont}/{data.todayContMax}</RedCountText> (次)</CodeText>
		        </FlexBox>
		        <CodeBox onTouchStart={startLongPress}
		                 onTouchEnd={endLongPress}>
			        <div>{data.share_code}</div>
			        <div  style={{color:"#C3C3C3",fontSize:"0.625rem"}} >长按复制邀请码</div>
				</CodeBox>
	        </WBox>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>邀请列表</Title>
			        <CodeText>邀请人数：<RedCountText>{data.share_list.length}</RedCountText> (次)</CodeText>
		        </FlexBox>
		        {data.share_list.length === 0 && <FlexBox><div style={{margin:"1rem 0"}}>您还没有邀请好友呢~</div></FlexBox>}
		        {data.share_list.length > 0 &&
				<FlexBox style={{flexDirection:"column",justifyContent:"flex-start",marginTop:"0.5rem"}}>
					{data.share_list.map((item,index) => {
						return (
							<ProUserBox key={index}>
								<FlexBox style={{justifyContent:"flex-start"}}>
									<UserAvatar src={item.header!==''&& item.header!=='test'?item.header:DefaultAvatar}/>
									<UserName>{item.name}</UserName>
								</FlexBox>
								<UserPhone>{item.phone}</UserPhone>
							</ProUserBox>
						)
					})}
				</FlexBox>}
	        </WBox>
	        <RuleText>
		        <div>邀请规则:</div>
		        <div style={{marginTop:"1.25rem",lineHeight:"1.25rem"}}>1.邀请新用户下载小卡数字人</div>
		        <div style={{lineHeight:"1.25rem"}}>2.新用户注册时填入邀请码</div>
		        <div style={{lineHeight:"1.25rem"}}>3.新用户实名认证后,即邀请成功,可在此页面领取奖励</div>
	        </RuleText>
	        <FlexBox style={{position:"fixed",bottom:"2rem",width:"22.5rem",zIndex:3}}>
		        <ShareBtn onClick={() => {setModalContent(<ShareLinkModal shareCode={data.share_code}/>);setPosition('center');setOpenModal(true)}}>分享链接</ShareBtn>
		        <ShareBtn onClick={() => {setModalContent(<SharePosterModal shareCode={data.share_code}/>);setPosition('center');setOpenModal(true)}}>生成海报</ShareBtn>
	        </FlexBox>
        </Container>
    );
};

export default Promote;
