const Quill = require('quill');
const BlockEmbed = Quill.import('blots/block/embed')
class VideoBlot extends BlockEmbed {
	static create(value) {
		let node = super.create(value);
		node.setAttribute('src', value.url || value);   //设置audio的src属性
		node.setAttribute('controls', true);   //设置audio的controls，否则他将不会显示
		node.setAttribute('controlsList', 'nodownload');   //设置audio的下载功能为不能下载
		node.setAttribute('id', 'videoAuto');     //设置一个id
		node.setAttribute('style', 'width:100%;max-width:600px');     //设置一个id
		node.setAttribute('poster',value.poster)
		//外层套入div （不套入会产生无法删除乱起八糟的问题😀）
		let divNode = document.createElement("span");
		divNode.appendChild(node)
		if (!value.url) {
			divNode.innerHTML = divNode.innerHTML.replace('<video', '<iframe class="ql-video" frameborder="0" allowfullscreen="true"').replace('<video', '</iframe')
			divNode.getElementsByTagName('iframe')[0].style = 'width:auto'
		}
		return divNode;
	}
	// 添加value获取当前的audio元素。拿到audio元素的属性。
	static value(domNode) {
		const value = {
			url: '',
			name: '',
			poster: ''
		};
		// 这里要加判断。不然会显示undefined
		if (domNode.getAttribute('src')) {
			value.url = domNode.getAttribute('src');
			value.name = domNode.getAttribute('name');
			value.poster = domNode.getAttribute('poster')
		}
		return value;
	}
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'video';
export default VideoBlot;
