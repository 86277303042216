import React, {useState} from 'react';
import styled from "styled-components";
import {copyText} from "../../../../utils";
import {useModal} from "../../../../hooks";
import {bindShareCode} from "../../../../services";
import {Toast} from "antd-mobile";

const Container = styled.div`
    width: 16.5rem;
	height: 10rem;
    background: #FFFFFF;
    border-radius: 0.875rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
    padding: 1.25rem;

`
const Title = styled.div`
    font-size: 1.25rem;
    color: #181818;
`
const Input = styled.input`
    background-color: #FFFFFF;
    border: 1px solid #D3D3D3;
    outline: none;
    font-size:0.875rem;
	height: 1.75rem;
	margin-top: 1.25rem;
	border-radius: 0.5rem;
	padding: 0.25rem;
	width: 15rem;
`
const CopyBtn = styled.div`
    width: 15.13rem;
    height: 2.575rem;
    background: #7A57EB;
    border-radius: 1.25rem;
	font-size: 0.875rem;
	color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	margin-top:1.25rem;
	:hover {
		filter: opacity(0.8);
	}
`
const BindModal = ({onReload}) => {
	const {setOpenModal} =useModal()
	const [code,setCode] = useState('')
	const bind = () => {
		if (code === ''){
			return Toast.show({icon:'fail',content:'请输入邀请码'})
		}
		bindShareCode({shareCode:code}).then(res => {
			if (res.data.isSucc){
				onReload()
				setOpenModal(false)
				Toast.show({icon:'success',content:'绑定成功'})
			}else {
				Toast.show({icon:'fail',content:res.data.err.message})
			}
		})
	}
	return (
		<Container>
			<Title>绑定邀请码</Title>
			<Input maxLength={8}  onChange={(e) => {setCode(e.target.value)}} type={'text'} placeholder={'请输入邀请人的邀请码'}/>
			<CopyBtn onClick={bind}>绑定</CopyBtn>
		</Container>
	);
};

export default BindModal;
