import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavigate, useParams} from "react-router";
import {useModal, useNavBtn, useUserInfo} from "../../../hooks";
import FlexBox from "../../../commen/FlexBox";
import {forumGetList, searchTopic} from "../../../services/forum";
import {InfiniteScroll, Toast} from "antd-mobile";
import ForumItem from "../forum/ForumItem";
import ManageBar from "../components/ManageBar";
import VoteTab from "./components/VoteTab";

const Container = styled.div`
`
const VoteContainer = styled.div`
    width: 22.5rem;
    height: 8.416rem;
    position: relative;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 4.55rem;

`
const VoteTitle = styled.div`
    font-size: 1.02rem;
    color: #FFFFFF;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
`
const VoteContent = styled.div`
    margin: 1rem;
    width: 20.5rem;
    overflow: hidden;
`

const Text = styled.div`
    font-size: 1.02rem;
    color: #030C29;
`
const GText = styled.div`
    font-size: 0.75rem;
    color: #828388;
`
const JoinVoteBtn = styled.div`
    width: 9.416rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.5rem;
    position: fixed;
    bottom: 2.5rem;
    left: 6.5rem;
    font-size: 1rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 9;
`
const VoteDetail = () => {
	const {tag} = useParams()
	const {navBarBtn, setNavBarBtn} = useNavBtn()
	const [checkIndex, setCheckIndex] = useState(0)
	const [list, setList] = useState([])
	const [page, setPage] = useState(0)
	const [total, setTotal] = useState(1)
	const [data, setData] = useState({banner: "", content: "", join_cont: 0, look_cont: 0, post_cont: 0, title: ''})
	const {setModalContent, setPosition, setOpenModal} = useModal()
	const navigate = useNavigate()
	const {userInfo} = useUserInfo()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	useEffect(() => {
		setList([])
		searchTopic({key: tag}).then(res => {
			if (res.data.isSucc) {
				
				setData(res.data.res.max)
				const data = res.data.res.max
					setNavBarBtn({
						text: <img src={require('../../../assets/images/forum/nav_more.png')}
						           style={{width: '1.8rem'}}/>,
						color: '#B3B3B3',
						action: () => {
							setModalContent(<ManageBar type={'vote'} id={data.title}/>);
							setPosition('bottom');
							setOpenModal(true)
						},
						title: `#${data.title}`,
					})
				} else {
					setNavBarBtn({
						text: <img src={require('../../../assets/images/forum/nav_more.png')}
						           style={{width: '1.8rem'}}/>,
						color: '#B3B3B3',
						title: `#${data.title}`,
						action: false,
					})
				}
		})
		
	}, [tag])
	useEffect(() => {
		return () => {
			setNavBarBtn(null)
		}
	}, [])
	const getList = () => {
		forumGetList({type: checkIndex === 0 ? 3 : 6, page: page, limit: 10, title: tag}).then(res => {
			if (res.data.isSucc) {
				if (page === 0) {
					setTotal(res.data.res.length)
				}
				if (page > 0) {
					setList(value => [...value, ...res.data.res.list])
				} else {
					setList(res.data.res.list)
				}
			}
		})
		
	}
	useEffect(() => {
		if (list.length === 0) {
			getList()
		}
	}, [checkIndex, list.length, tag])
	const onRefresh = useCallback(() => {
		if (page === 0) {
			getList()
		}
		setPage(0)
	}, [])
	
	function loadMore() {
		if (list.length < total - 1) {
			let nextPage = page + 1
			setPage(nextPage)
			return getList()
		}
	}
	
	const tabArr = ['推荐', '最新']
	return (
		<Container>
			<VoteContainer img={data.banner}>
				<VoteTitle>#{data.title}</VoteTitle>
			</VoteContainer>
			<VoteContent>
				<Text dangerouslySetInnerHTML={{__html: data.content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}}/>
				<FlexBox style={{justifyContent: "space-between", marginTop: "1.3rem"}}>
					<GText>{data.join_cont} 人参与</GText>
					<GText>{data.look_cont} 次浏览</GText>
					<GText>{data.post_cont} 个内容</GText>
				</FlexBox>
			</VoteContent>
			<VoteTab defaultIndex={0} data={tabArr} style={{justifyContent: "center"}} callback={(index) => {
				setCheckIndex(index);
				setList([]);
				setTotal(1);
				setPage(0)
			}}/>
			{
				list.map((item, index) => {
					return (
						<ForumItem onRefresh={onRefresh} data={item} type={0} key={index}/>
					)
				})
			}
			{((userInfo.server_leve === 0 && data.title !== '小卡新闻分享' && data.title !== '小卡活动分享') || userInfo.server_leve > 0) &&
			<JoinVoteBtn onClick={() => navigate('/community/topic/post?tip='+data.title)}># 参与话题</JoinVoteBtn>}
			<InfiniteScroll loadMore={loadMore} threshold={10} hasMore={list.length < total}/>
		</Container>
	);
};

export default VoteDetail;
