import React from 'react';
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {clearFToken} from "../utils/fToken";

const Container = styled.div`
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 28px;
    background-color: #FFFFFF;
    z-index: 9;
	border-bottom: 1px solid #F3F3F3;
`
const Back = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    object-fit: cover;
	padding-left: 1rem;
`

const Title = styled.div`
    font-size: 1.126rem;
    color: #000000;
`
const NavBar = () => {
	const {pathname} = useLocation()
	const navigate = useNavigate()
	const getTitle = () => {
		if (pathname === '/store/gift_exchange' || pathname === '/store') {
			return '卡车物资站'
		} else if (pathname === '/store/coin_logs') {
			return '点卡明细'
		} else if (pathname === '/store/exchange_logs') {
			return '兑换明细'
		}
	}
	const onBack = () => {
		if (pathname === '/store/gift_exchange' || pathname === '/store') {
			clearFToken()
			window.location.href = "uniwebview://close"
		} else {
			navigate(-1)
		}
	}
	return (
		<Container>
			<Back onClick={onBack} src={require('../assets/images/forum/back.png')}/>
			<Title>{getTitle()}</Title>
			<div style={{width: '1.5rem',height:"1.5rem",paddingRight:"1rem"}}/>
		</Container>
	);
};

export default NavBar;
