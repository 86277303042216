import Routes from "./router";
import React, {useEffect, useState} from "react";
import {clearToken, getToken, setToken} from "./utils/token";
import {GMContext} from "./contexts";
import XModal from "./commen/XModal";
import {useWindowSize} from "./utils/useWindowSize";
import NavBar from "./components/NavBar";
import {getUserInfo} from "./services";
import {useLocation} from "react-router-dom";
import CommunityNavBar from "./components/CommunityNavBar";
import {forumLogin} from "./services/forum";
import MineNavbar from "./components/MineNavBar";
import thinkingdata from "thinkingdata-browser";
import {BaseUrl} from "./utils/baseUrl";
import HelpNavBar from "./components/HelpNavBar";

function App() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const permission = searchParams.get('permission')
	const [isLogin, setIsLogin] = useState(getToken() !== '')
	const [isPermission,setIsPermission] = useState(permission?Number(permission):1)
	const [modalContent, setModalContent] = useState(<div/>)
	const [openModal, setOpenModal] = useState(false)
	const [position, setPosition] = useState('center')
	const [userInfo, setUserInfo] = useState({})
	const [styleOverlay, setStyleOverlay] = useState({})
	const [styleContent, setStyleContent] = useState({})
	const [navBarBtn, setNavBarBtn] = useState({
		text: '', color: '', action: () => {
		}, title: ''
	})
	const {width, height} = useWindowSize()
	const [refresh, setRefresh] = useState(false)
	const {pathname} = useLocation()
	const path = pathname.split('/')[1]
	const xkToken = searchParams.get('xkToken')
	

	useEffect(() => {
		if (xkToken) {
			setToken(xkToken)
		}
		
	}, [xkToken])
	useEffect(() => {
		return () => {
			clearToken()
			localStorage.clear()
		}
	}, [])
	
	useEffect(() => {
		if (path === 'community') {
			forumLogin({__token: xkToken  ? xkToken : getToken()}).then(res => {
				if (res.data.isSucc) {
					setIsLogin(true)
					setUserInfo(res.data.res.user)
				}
			})
		}
		if (path === 'store' || path === 'mine') {
			getUserInfo({__token: xkToken ? xkToken : getToken()}).then(res => {
				if (res.data.isSucc) {
					setIsLogin(true)
					setUserInfo(res.data.res.user)
				}
			})
		}
		
	}, [refresh, xkToken, getToken()])
	useEffect(() => {
		if (userInfo.uuid ) {
			const config = {
				appId: '05b1650ac0b546128c9868891155ab4d',
				serverUrl: 'https://global-receiver-ta.thinkingdata.cn',
				autoTrack: {
					pageShow: true, //开启页面展示事件，事件名ta_page_show
					pageHide: true, //开启页面隐藏事件，事件名ta_page_hide
				}
			};
			thinkingdata.init(config);
			// 将 SDK 实例赋给全局变量 ta，或者其他您指定的变量
			// @ts-ignore
			window.ta = thinkingdata;
			// @ts-ignore
			window.ta.init(config);
			window.ta.login(userInfo.uuid)
			// @ts-ignore
			// @ts-ignore
		}
		
		
	},[userInfo.uuid])
	
	const getBgStyle = () => {
		if (path === 'community') {
			return {background: "#FFFFFF", }
		} else if (path === 'store') {
			return {
				background: "#FFFFFF",
				
			}
		} else if (path === 'mine') {
			return {background: "#F8F8F8", minHeight: height, }
		}
	}
	return (
		<GMContext.Provider value={{
			isLogin: isLogin,
			setIsLogin: setIsLogin,
			openModal: openModal,
			setOpenModal: setOpenModal,
			position: position,
			setPosition: setPosition,
			modalContent: modalContent,
			setModalContent: setModalContent,
			styleOverlay: styleOverlay,
			setStyleOverlay: setStyleOverlay,
			styleContent: styleContent,
			setStyleContent: setStyleContent,
			userInfo: userInfo,
			setUserInfo: setUserInfo,
			setRefresh: setRefresh,
			refresh: refresh,
			navBarBtn: navBarBtn,
			setNavBarBtn: setNavBarBtn,
			isPermission:isPermission,
			setIsPermission:setIsPermission
		}}>
			<XModal children={modalContent}
			        position={position}
			        zIndex={999}
			        styleOverlay={styleOverlay}
			        styleContent={styleContent}
			        onRequestClose={() => setOpenModal(false)}
			        isOpen={openModal}/>
		
			<div style={getBgStyle()}>
				{path === 'community' && <CommunityNavBar/>}
				{path === 'store' && !pathname.includes('store_details') && <NavBar/>}
				{path === 'mine' && <MineNavbar/>}
				{path === 'help' && <HelpNavBar/>}
				<Routes/>
			</div>
		
		</GMContext.Provider>
	);
}

export default App;
