import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useLocation} from "react-router-dom";
import {useWindowSize} from "../../../utils/useWindowSize";
import {useNavigate} from "react-router";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const WBox = styled.div`
    width: 18.5rem;
	background-color: #FFFFFF;
	padding: 0.75rem 1rem;
    margin-top: 0.31rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.5rem;
`
const Question = styled.div`
	font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
`
const Back = styled.img`
    width: 0.5rem;
    height: 0.875rem;
    object-fit: cover;
	padding-left: 1rem;
`
const Tip = styled.div`
	font-size: 1rem;
	color: #F59A23;
	padding-bottom: 0.25rem;
	border-bottom: 0.125rem solid #F59A23;
	margin-left: 1rem;
	margin-bottom: 0.5rem;
`
const HelpList = () => {
	const {state:{data}} =useLocation()
	const {height} = useWindowSize()
	const navigate = useNavigate()
	return (
		<Container style={{minHeight:height}}>
			<FlexBox style={{justifyContent:"flex-start",width:"20.5rem"}}>
				<Tip>{data[0].type}</Tip>
			</FlexBox>
			
			{
				data.map((item,index) => {
					return (
						<WBox onClick={() => {navigate('/help/help_detail',{state:{data:item}})}} key={index}>
								<Question>{item.q}</Question>
								<Back src={require('../../../assets/images/mine/gr_icon_list_arrow.png')}/>
						</WBox>
					)
				})
			}
			
		</Container>
	);
};

export default HelpList;
