import React, {useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {useModal, useRefresh, useUserInfo} from "../../../../hooks";
import {exChange} from "../../../../services";
import {Toast} from "antd-mobile";
import SuccessModal from "./SuccessModal";
import { debounce } from 'lodash';
import {throttling} from "../../../../utils";

const Container = styled.div`
    width: 17.79rem;
    background: #FFFFFF;
    border-radius: 0.83rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
`
const Tip = styled.div`
    font-size: 0.876rem;
    color: #011012;
    position: absolute;
    top: 1.575rem;
    left: 2.75rem;
`

const MkIcon = styled.img`
    width: 1.453rem;
    height: 1.262rem;
    object-fit: cover;
`
const Text = styled.div`
    font-size: 0.87rem;
    color: #181818;
    margin-left: 1rem;
`
const Title = styled.div`
    font-size: 1.13rem;
    color: #181818;
`


const MCount = styled.div`
    font-size: .876rem;
    color: #181818;
	margin-left: 0.5rem;
`
const Name = styled.div`
    font-size: 0.876rem;
    color: #7A57EB;
	max-width: 14rem;
	margin-left: 1rem;
`
const Btn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.3rem;
	font-size: 1rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
    border: 0.19px solid #7A57EB;
`
const CancelBtn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #FFFFFF;
    border-radius: 1.3rem;
	font-size: 1rem;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
	border: 0.19px solid #C6C6C6;
`
const ExchangeModal = ({data}) => {
	const {setOpenModal, setModalContent,openModal} = useModal()
	const {userInfo} = useUserInfo()
	const {refresh, setRefresh} = useRefresh()
	const onExChange = () => {
		if (userInfo.coin === 0) {
			return Toast.show({icon: 'fail', content: "余额不足"})
		}
		Toast.show({icon: 'loading', content: "兑换中..."})
		setOpenModal(false)
		exChange({id: data.id}).then(res => {
			if (res.data.isSucc) {
				Toast.clear()
				setRefresh(!refresh)
				setModalContent(<SuccessModal data={data}/>)
				setOpenModal(true)
			}else if (res.data.res.err.code === '50018') {
				Toast.show({icon: 'fail', content: "库存不足"})
				setOpenModal(false)
			} else {
				Toast.show({icon: 'fail', content: "兑换失败"})
				setOpenModal(false)
			}
		}).catch(err => {
			Toast.show({icon: 'fail', content: "兑换失败"})
			setOpenModal(false)
		})
	}
	const handleClick = debounce(() => {
		// 处理按钮点击逻辑
		onExChange()
	}, 1000); // 设置等待时间为 1000 毫秒
	
	return (
		<Container>
			<Title>提示</Title>
			<FlexBox style={{marginTop: "3rem",}}>
				<Text>是否消耗</Text>
				<FlexBox style={{justifyContent: 'flex-start',marginLeft:"0.5rem"}}>
					<MkIcon src={require('../../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
					<MCount>{data.price}</MCount>
				</FlexBox>
			</FlexBox>
			<FlexBox style={{marginTop: "0.936rem",alignItems:"flex-start"}}>
				<Text>兑换</Text>
				<Name>{data.name}</Name>
			</FlexBox>
			<FlexBox style={{justifyContent: "center", marginTop: '4.48rem', marginLeft: "0.75rem"}}>
				<CancelBtn  onClick={() => {
					setOpenModal(false)
				}}>取消</CancelBtn>
				<Btn onClick={handleClick} style={{marginLeft: "1rem"}}>确定</Btn>
			</FlexBox>
		</Container>
	);
};

export default ExchangeModal;
