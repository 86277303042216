const tokenKey = 'xk_token'

export const setToken = (token) => {
    localStorage.setItem(tokenKey, token)
}

export const getToken = () => {
    const token = localStorage.getItem(tokenKey)
    return token ? token : ''
}

export const clearToken = () => {
    localStorage.removeItem(tokenKey)
}
