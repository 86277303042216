import walletRequest from "../utils/walletRequest";

export const getWalletInfo = (data) => {
	return walletRequest('/wallet/info/query', {
		method: "POST",
		data
	})
}

//wallet/info/exchangeCard
export const exchangeCard = (data) => {
	return walletRequest('/wallet/info/exchangeCard', {
		method: "POST",
		data
	})
}
//wallet/info/recharge
export const recharge = (data) => {
	return walletRequest('/wallet/info/recharge', {
		method: "POST",
		data
	})
}

//wallet/info/transactionDetails
export const transactionDetails = (data) => {
	return walletRequest('/wallet/info/transactionDetails', {
		method: "POST",
		data
	})
}
//wallet/info/giftEarningDetails
export const giftEarningDetails = (data) => {
	return walletRequest('/wallet/info/giftEarningDetails', {
		method: "POST",
		data
	})
}
//wallet/info/beansTotal
export const beansTotal = (data) => {
	console.log(data)
	return walletRequest('/wallet/info/beansTotal', {
		method: "POST",
		data
	})
}
//alipay/order/getStr
export const createPayOrder = (data) => {
	return walletRequest('/alipay/order/getStr', {
		method: "POST",
		data
	})
}
