import React from 'react';
import FlexBox from "../../../commen/FlexBox";
import styled from "styled-components";
import {useNavigate} from "react-router";

const ItemContainer = styled.div`
    background-color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    height: 2.5rem;
    display: flex;
    width: 20.5rem;
`

const ItemTitle = styled.div`
    font-size: 0.85rem;
    color: #181818;
`
const ArrowIcon = styled.img`
    width: 0.33rem;
    height: 0.58rem;
    margin-left: 0.83rem;
`

const Logo = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
`
const Container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #F8F8F8;
    padding-top: 5.4rem;
`
const AppName = styled.div`
    font-size: 1rem;
    color: #181818;
	font-weight: bold;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
`
const About = () => {
	const navigate = useNavigate()
	return (
		<Container>
			<Logo src={require('../../../assets/images/base/xk_icon.png')}/>
			<AppName>小卡数字人</AppName>
			<ItemContainer onClick={() => navigate('/mine/user_agreement')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>用户协议</ItemTitle>
					<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
				</FlexBox>
			</ItemContainer>
			<ItemContainer onClick={() => navigate('/mine/privacy_policy')} style={{borderTop: "1px solid #FAFAFA"}}>
				<FlexBox style={{justifyContent: "space-between", flex: 1}}>
					<ItemTitle>隐私政策</ItemTitle>
					<ArrowIcon src={require("../../../assets/images/mine/gr_icon_list_arrow.png")}/>
				</FlexBox>
			</ItemContainer>
		</Container>
	);
};

export default About;
