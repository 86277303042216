import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";

import {useModal} from "../../../../hooks";

const Container = styled.div`
    width: 17.79rem;
    height: 18.541rem;
    background: #FFFFFF;
    border-radius: 0.83rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem;
`
const Tip = styled.div`
    font-size: 0.876rem;
    color: #011012;
    position: absolute;
    top: 1.575rem;
    left: 2.75rem;
`
const Text = styled.div`
    font-size: 0.87rem;
    color: #181818;
    margin-left: 1rem;
	line-height: 1.25rem;
	flex:1;
	word-wrap: break-word;
	word-break: break-all;
`
const Title = styled.div`
    font-size: 1.13rem;
    color: #181818;
`


const MarkIcon = styled.img`
    width: 0.883rem;
    height: 0.883rem;
`
const Circle = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #000000;
    border-radius: 0.5rem;
	margin-top: 0.35rem;
`
const NoExModal = () => {
	const {setOpenModal} = useModal()
	return (
		<Container>
			<Title>点卡获取规则</Title>
			<div style={{marginTop: "1.8rem",width:"15.5rem"}}>
				<FlexBox style={{justifyContent: "flex-start", width: "15rem",alignItems:"flex-start"}}>
					<Circle/>
					<Text>每日登录小卡可以获得少量点卡</Text>
				</FlexBox>
				<FlexBox style={{marginTop: "0.77rem", justifyContent: "flex-start",alignItems:"flex-start"}}>
					<Circle/>
					<Text>参与答题小游戏可获得丰厚的点卡</Text>
				</FlexBox>
				<FlexBox style={{marginTop: "0.77rem", justifyContent: "flex-start",alignItems:"flex-start"}}>
					<Circle/>
					<Text>参与每日任务可获得海量的点卡</Text>
				</FlexBox>
			</div>
		
		
		</Container>
	);
};

export default NoExModal;
