import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BGImg from "../../../assets/images/buycarmark/gr_tichejilu_main_bg.jpg"
import TitleBg from "../../../assets/images/buycarmark/gr_tichejinian_bg_title.png"
import Mask1 from "../../../assets/images/buycarmark/gr_tichejinian_mask_1.png"
import TimeBg from "../../../assets/images/buycarmark/gr_tichejinian_bg_time.png"
import Mask2 from "../../../assets/images/buycarmark/gr_tichejinian_mask_2.png"
import Mask3 from "../../../assets/images/buycarmark/gr_tichejinian_mask_3.png"
import ShareIcon from "../../../assets/images/buycarmark/gr_tichejinian_mask_btn_share.png"
import T1 from "../../../assets/images/buycarmark/t1.png"
import T2 from "../../../assets/images/buycarmark/t2.png"
import T3 from "../../../assets/images/buycarmark/t3.png"
import {useUserInfo} from "../../../hooks";
import {buyCarLog} from "../../../services";
import {formatDMDate, formatYDMDate} from "../../../utils";
import {useParams} from "react-router";
const Container = styled.div`
    background: url(${BGImg});
    background-size: cover;
    width: 22.5rem;
    height: 188.125rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
	margin-top: 4.65rem;
	position: relative;
`

const Title = styled.div`
    background: url(${TitleBg});
    background-size: cover;
    width: 20.89rem;
    height: 4.125rem;
	text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1rem;
	color: #FFE0C8;
	font-weight: bold;
`
const Bk = styled.img`
    width: 19.583rem;
    height: ${props => props.height};
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
`
const ImgBg = styled.div`
	width: 19.583rem;
	height: ${props => props.height};
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
`
const BgTime = styled.div`
    background: url(${TimeBg});
    background-size: cover;
    width: 9.125rem;
	height: 2.166rem;
	position: absolute;
	bottom: ${props => props.bottom};
	left: ${props => props.left};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: #FFE0C8;
    font-weight: bold;
	z-index: 6;
`
const Text = styled.div`
    font-size: 0.75rem;
    color: #6DA8B9;
	margin-top: 1.5rem;
	max-width: 14.5rem;
	z-index: 6;
`
const ShareBtn = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
	position: absolute;
	right: 1.73rem;
	bottom: 4.5rem;
`
const PlayBtn = styled.img`
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    position: absolute;
    top: 5.25rem;
	left: 8.5rem;
	z-index: 8;
`
const BuyCarMark = () => {
	const [showPlayIcon, setShowPlayIcon] = useState(true)
	const [data,setData] = useState({})
	const {userInfo} = useUserInfo()
	const {id} = useParams()
	useEffect(() => {
		buyCarLog({id:id!== 'no'?id:userInfo.buyCarLogs[userInfo.buyCarLogs.length-1]}).then(res => {
			if (res.data.isSucc){
				setData(res.data.res.data)
			}
		})
	},[id])
	const playVideo = () => {
		const videoDom = document.getElementById('video')
		videoDom.play()
		videoDom.addEventListener('ended', handleVideoEnded);
		setShowPlayIcon(false)
	}
	const handleVideoEnded = () => {
		setShowPlayIcon(true)
	}
    return (
        <Container>
		   <Title style={{marginTop:"9rem"}}>恭喜{data.name}{data.gender ===1 ? '女士':'先生'}喜提新车</Title>
	        <ImgBg height={"31.229rem"}>
		        <BgTime left={'5rem'} bottom={'5.75rem'}>{formatYDMDate(data.time_jiaoche)}</BgTime>
		        <Bk height={"31.229rem"} src={Mask1}/>
		        <img src={data.img_jiaoche} style={{width:"17.63rem",height:"24rem",marginTop:"0.5rem",objectFit:'cover'}} alt=""/>
		        <Text>{data.name}{data.gender ===1 ? '女士':'先生'}在成都源兴4S店购买的{data.model}，于{formatDMDate(data.time_jiaoche)}，成功完成交车仪式。</Text>
	        </ImgBg>
	        <Title style={{marginTop:"2rem"}}>提车祝贺</Title>
	        <ImgBg height={"18.625em"} style={{marginTop:"1rem"}} >
		        <Bk height={"18.125em"} src={Mask2}/>
		        <BgTime left={'5rem'} bottom={0}>{formatYDMDate(data.time_jiaoche)}</BgTime>
		        {showPlayIcon && <PlayBtn onClick={playVideo} src={require('../../../assets/images/buycarmark/gr_tichejinian_play.png')}/>}
			        <video id={'video'} preload={'auto'} src={data.video_tichezhuhe} style={{width:"18.125rem",objectFit:"fill",height:"95%"}} alt=""/>
		        
	        </ImgBg>
	        <Title style={{marginTop:"2rem"}}>运送起程</Title>
	        <ImgBg height={"20.875rem"} style={{marginTop:"1rem",}} >
		        <Bk height={"20.875rem"} src={Mask3}/>
		        <BgTime left={'5rem'} bottom={'5.625rem'}>{formatYDMDate(data.time_yunsong)}</BgTime>
		        <img src={T1} style={{width:"17.63rem",height:"13.7rem",marginTop:"0.5rem"}} alt=""/>
		        <Text>{data.name}{data.gender ===1 ? '女士':'先生'}在成都源兴4S店购买的{data.model}，于{formatDMDate(data.time_yunsong)}，从汽车厂出发，开始向目的地启程。</Text>
	        </ImgBg>
	        <Title style={{marginTop:"2rem"}}>卡车下线</Title>
	        <ImgBg height={"20.875rem"} style={{marginTop:"1rem"}} >
		        <Bk height={"20.875rem"} src={Mask3}/>
		        <BgTime left={'5rem'} bottom={'5.625rem'}>{formatYDMDate(data.time_xiaxian)}</BgTime>
		        <img src={T2} style={{width:"17.63rem",height:"13.7rem",marginTop:"0.5rem"}} alt=""/>
		        <Text>{data.name}{data.gender ===1 ? '女士':'先生'}于{formatDMDate(data.time_xiaxian)}在成都源兴4S店购买的{data.model}，在汽车厂完成制造，开始下线。</Text>
	        </ImgBg>
	        <Title style={{marginTop:"2rem"}}>卡车制造</Title>
	        <ImgBg height={"20.875rem"} style={{marginTop:"1rem"}} >
		        <Bk height={"20.875rem"} src={Mask3}/>
		        <BgTime left={'5rem'} bottom={'5.625rem'}>{formatYDMDate(data.time_zhizhao)}</BgTime>
		        <img src={T3} style={{width:"17.63rem",height:"13.7rem",marginTop:"0.5rem"}} alt=""/>
		        <Text>{data.name}{data.gender ===1 ? '女士':'先生'}在成都源兴4S店购买的{data.model}，于{formatDMDate(data.time_xiaxian)}在汽车厂开始进行制造。</Text>
	        </ImgBg>
	        <Title style={{marginTop:"2rem"}}>订单签约</Title>
	        <ImgBg height={"20.875rem"} style={{marginTop:"1rem"}} >
		        <Bk height={"20.875rem"} src={Mask3}/>
		        <BgTime left={'5rem'} bottom={'5.625rem'}>{formatYDMDate(data.time_qianyue)}</BgTime>
		        <img src={data.img_dingdan} style={{width:"17.63rem",height:"13.7rem",marginTop:"0.5rem"}} alt=""/>
		        <Text>{data.name}{data.gender ===1 ? '女士':'先生'}于{formatDMDate(data.time_qianyue)}在成都源兴4S店，完成{data.model}的购买订单签约。</Text>
	        </ImgBg>
	        {/*<ShareBtn src={ShareIcon} />*/}
        </Container>
    );
};

export default BuyCarMark;
