import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import UnCheck from "../../../assets/images/mine/gr_btn_toggle_1.png"
import Checked from "../../../assets/images/mine/gr_btn_toggle_2.png"
import {useLocation} from "react-router-dom";
import {createPayOrder, recharge} from "../../../services/wallet";
import {Toast} from "antd-mobile";
import {validatePositiveInteger} from "../../../utils";
const Container = styled.div`
    padding-top: 5rem;
`
const WBox = styled.div`
	width: 20.5rem;
	background-color: #FFFFFF;
	padding: 1rem;
	margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
	flex-direction: column;
    height: 5rem;
`
const CountIcon = styled.img`
    width: 2rem;
    height: 2rem;
	object-fit: cover;
	margin-left: 0.25rem;
`
const Count = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
	margin-left: 0.5rem;
	margin-top: 0.5rem;
`
const ExBox = styled.div`
    width: 6rem;
    height: 4rem;
    background: #FFFFFF;
	border: ${props => props.border};
	border-radius: 0.5rem;
	text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	flex-direction: column;
	margin: 0.25rem;
`
const Input = styled.input`
    width: 19rem;
    height: 2rem;
	font-size: 1rem;
    background: #FFFFFF;
	border: 1px solid #D4D4D4;
	border-radius: 0.5rem;
	outline: none;
	margin-top: 0.5rem;
	text-indent: 0.5rem;
`
const ExGb = styled.div`
    font-size: 0.75rem;
    color: #000000;
`
const GbCount = styled.div`
    font-size: 1rem;
    color: #000000;
`
const RmbText = styled.div`
    font-size: 0.75rem;
    color: #ACACAC;
`
const PayIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
	object-fit: cover;
`
const PayText = styled.div`
    font-size: 0.82rem;
    color: #000000;
	margin-left: 0.5rem;
`
const CheckIcon = styled.img`
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
`
const PayBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:0.5rem 1rem;
`
const PayBtn = styled.div`
    width: 20.5rem;
    height: 3.25rem;
    background: linear-gradient(0deg, #8366FF, #AF61FF);
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
	color: #FFFFFF;
	font-size: 1.15rem;
`
const AgreeText = styled.div`
    font-size: 0.625rem;
    color: #919191;
`
const Agree =styled.div`
    font-size: 0.625rem;
    color: #7A57EB;
`
const InputBox =styled.div`
    width: 19rem;
    height: 2rem;
    background: rgba(0,0,0,0);
    border: 0.19px solid #D4D4D4;
    border-radius: 1.25rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 1.25rem;
	color: #B3B3B3;
	margin-left: 1rem;
	padding: 0.5rem;
	margin-top: 0.25rem;
`
const GbRecharge = () => {
	const {state:{data}} = useLocation()
	const [selectIndex,setSelectIndex] = useState(0)
	const [payMethod,setPayMethod] = useState(0)
	const [amount,setAmount] = useState(0)
	const [showInput,setShowInput] = useState(false)
	const exData = [{goldBean:10,rmb:1},{goldBean:100,rmb:10},{goldBean:200,rmb:20},{goldBean:1000,rmb:100},{goldBean:5000,rmb:500},{goldBean:10000,rmb:1000}]
	 const submit = () => {
		 if (!validatePositiveInteger(amount)){
			 return Toast.show({icon:'fail',content:'充值金额为正整数'})
		 }
		 if (amount > 100000){
			 return  Toast.show({icon:'fail',content:'充值金额最高100000元'})
		 }
		 createPayOrder({totalAmount:amount,orderId:10011}).then(res => {
			 if (res.data.code === 0) {
				 console.log("uniwebview://rechargeGoldBeans?payStr=" + res.data.data)
				 window.location.href = "uniwebview://rechargeGoldBeans?payStr=" + res.data.data
			 }else {
				 return Toast.show({icon: 'fail', content:'充值失败'})
			 }
		 }).catch(err => {Toast.show({icon: 'fail', content:'充值失败'})})
		
	 }
	return (
        <Container>
			<WBox>
				<CountIcon src={require('../../../assets/images/mine/gr_icon_jindou_l.png')}/>
				<Count>{data?data.beansBalance:0}</Count>
			</WBox>
	        
	        <div style={{marginTop:"1rem",backgroundColor:"#FFFFFF"}}>
		        <FlexBox style={{flexWrap:"wrap",padding:"1rem 0"}}>
			        {
				        exData.map((item,index) => {
					        return (
						        <ExBox onClick={() => {setSelectIndex(index);setAmount(exData[index].rmb)}} border={selectIndex===index?'1px solid #7A57EB':'1px solid #D4D4D4'} key={index}>
							        <FlexBox>
								        <GbCount>{item.goldBean}</GbCount>
								        <ExGb>金豆</ExGb>
							        </FlexBox>
							        <RmbText>{item.rmb}元</RmbText>
						        </ExBox>
					        )
				        })
			        }
		        </FlexBox>
		        <div style={{fontSize:"1rem",marginLeft:"1rem"}}>金豆数量:{amount*10}</div>
		        <InputBox  onClick={() => setShowInput(true)} >{amount?amount:'自定义金额'}</InputBox>
		        
		        <PayBox style={{marginTop:"1rem"}} onClick={() => setPayMethod(0)}>
			       <FlexBox style={{justifyContent:"flex-start"}}>
				       <PayIcon src={require('../../../assets/images/mine/gr_icon_pay_zhifubao.png')}/>
				       <PayText>支付宝</PayText>
			       </FlexBox>
			        <CheckIcon src={payMethod===0?Checked:UnCheck}/>
		        </PayBox>
		        {/*<PayBox onClick={() => setPayMethod(1)}>*/}
			    {/*    <FlexBox style={{justifyContent:"flex-start"}}>*/}
				{/*        <PayIcon src={require('../../../assets/images/mine/gr_icon_pay_wechat.png')}/>*/}
				{/*        <PayText>微信支付</PayText>*/}
			    {/*    </FlexBox>*/}
			    {/*    <CheckIcon src={payMethod===1?Checked:UnCheck}/>*/}
		        {/*</PayBox>*/}
		        <FlexBox style={{position:"absolute",bottom:"3rem",marginLeft:"1rem"}}>
			        <PayBtn onClick={submit} >立即充值{amount}元</PayBtn>
		        </FlexBox>
		        <FlexBox style={{position:"absolute",bottom:"1.5rem",width:"100%"}}>
			        <AgreeText>充值代表已阅读并同意</AgreeText>
			        <Agree>《用户充值协议》</Agree>
		        </FlexBox>
		        {showInput &&
			        <FlexBox onClick={(e) => {
				        e.stopPropagation()
			        }} style={{
				        justifyContent: 'flex-start',
				        width: '20.5rem',
				        zIndex: 10,
				        backgroundColor: "#FFFFFF",
				        position: 'fixed',
				        bottom: 0,
				        padding: '0.5rem 1rem',
				        flexDirection: "column",
				        borderTop:"1px solid #f3f3f3"
			        }}>
				        <div style={{width:"100%",fontSize:"1rem"}}>金豆数量:{amount*10}</div>
				        <FlexBox style={{justifyContent: "flex-start", width: "100%"}}>
					        <Input maxLength={6} autoFocus={true} onBlur={() => {setShowInput(false)}} type={'number'} onChange={(e) => {
						        setAmount(Number(e.target.value))
					        }} placeholder={'请输入充值金额（最少充值1元）'}/>
					   
					        <div onClick={() => {
						        setShowInput(false)
						        setSelectIndex(999)
						
					        }} style={{
						        color: amount !== 0 ? "#FFFFFF" : "#B3B3B3",
						        width: "3rem",
						        height: "1.75rem",
						        background: amount !== 0 ? "#B5B5FF" : "#D3D3D3",
						        textAlign: "center",
						        lineHeight: "1.75rem",
						        borderRadius: "0.5rem",
						        marginLeft: "0.5rem",
						        padding: '0.25rem 0.5rem',
						        cursor:"pointer",
						        marginTop:"0.5rem"
					        }}>确认
					        </div>
				        </FlexBox>
			
			
			        </FlexBox>}
					
	        </div>
        </Container>
    );
};

export default GbRecharge;
