import React from 'react';
import styled from "styled-components";
import FlexBox from "../../../../commen/FlexBox";
import {useModal, useUserInfo} from "../../../../hooks";
import {useNavigate} from "react-router";
import ExchangeModal from "./ExchangeModal";

const Container = styled.div`
    width: 17.79rem;
    background: #FFFFFF;
    border-radius: 0.83rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
`
const Text = styled.div`
    font-size: 0.87rem;
    color: #181818;
    width: 15rem;
    margin-left: 1rem;
`
const Title = styled.div`
    font-size: 1.13rem;
    color: #181818;
`


const MCount = styled.div`
    font-size: .876rem;
    color: #181818;
    margin-top: 0.7rem;
`
const Name = styled.div`
    font-size: 0.876rem;
    color: #7A57EB;
    margin-top: 0.7rem;
	max-width: 12rem;
	word-break: break-all;
	word-wrap: break-word;
`
const Btn = styled.div`
    width: 7.48rem;
    height: 2.583rem;
    background: #7A57EB;
    border-radius: 1.3rem;
    font-size: 1rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`
const ConfirmAddressModal = ({data}) => {
	const {setOpenModal, setModalContent} = useModal()
	const navigate = useNavigate()
	const {userInfo} = useUserInfo()
	const {default_address} = userInfo
	const address = default_address
	return (
		<Container>
			<Title>信息确认</Title>
			<FlexBox style={{marginTop: "1rem", marginLeft: "-1.75rem"}}>
				<Text>请确认下方寄送信息是否正确：</Text>
			</FlexBox>
			<FlexBox style={{
				maxWidth: "15rem",
				alignItems: "flex-start",
				marginTop: "0.5rem",
				justifyContent: "flex-end",
				backgroundColor: '#F5F5F5',
				borderRadius: '0.21rem',
				padding: "0.5rem"
				
			}}>
				<FlexBox style={{alignItems: "flex-end", flexDirection: "column"}}>
					<MCount>收货人：</MCount>
					<MCount>手机号：</MCount>
					<MCount>收货地址：</MCount>
				</FlexBox>
				<FlexBox style={{alignItems: "flex-start", flexDirection: "column", flex: 1,}}>
					<Name>{address.name}</Name>
					<Name>{address.phone}</Name>
					<Name>{address.address_area}{address.address_more}</Name>
				</FlexBox>
			</FlexBox>
			<FlexBox style={{justifyContent: "center", marginTop: '1.04rem', marginLeft: "0.75rem"}}>
				<Btn  onClick={() => {
					setOpenModal(false);
					navigate('/mine/address')
				}}>修改</Btn>
				<Btn  onClick={() => {
					setModalContent(<ExchangeModal data={data}/>)
				}} style={{marginLeft: "1rem"}}>{"确认"}</Btn>
			</FlexBox>
		</Container>
	);
};

export default ConfirmAddressModal;
