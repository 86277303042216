import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Dialog, InfiniteScroll, PullToRefresh, SpinLoading} from 'antd-mobile';
import {useNavigate,} from 'react-router';
import {useWindowSize} from '../../../utils/useWindowSize';
import ForumItem from "./ForumItem";
import {forumGetList, getTopicList} from "../../../services/forum";
import {useRefresh, useUserInfo} from "../../../hooks";
import CheckTab from "./components/CheckTab";
import styled from "styled-components";
import DefaultBanner from '../../../assets/images/forum/lt_img_zhuti_default.png'
import {clearToken, getToken} from "../../../utils/token";
import {useLocation} from "react-router-dom";
import FlexBox from "../../../commen/FlexBox";

const VoteContainer = styled.div`
    width: 20.5rem;
    height: 8.416rem;
    position: relative;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0.75rem;
    border-radius: 0.75rem;
`
const VoteTitle = styled.div`
    font-size: 1.02rem;
    color: #FFFFFF;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
`


const Forum = () => {
	const localPage = localStorage.getItem('page')
	const localList = localStorage.getItem('list')
	const localTotal = localStorage.getItem('total')
	const localTabIndex = localStorage.getItem('com_tab_index')
	const localTopsId = localStorage.getItem('topsId')
	const {state} = useLocation()
	const [list, setList] = useState(localList ? JSON.parse(localList) : [])
	const [page, setPage] = useState(localPage ? Number(localPage) : 0)
	const [total, setTotal] = useState(localTotal ? Number(localTotal) : 1)
	const [topsId, setTopsId] = useState(localTopsId?JSON.parse(localTopsId):[])
	const [tabIndex, setTabIndex] = useState(localTabIndex ? Number(localTabIndex) : 0)
	const [topicList, setTopicList] = useState([])
	const [type] = useState(3)
	const {userInfo} = useUserInfo()
	const {refresh,setRefresh} = useRefresh()
	const navigate = useNavigate()
	const {height, width} = useWindowSize()
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('xkToken')
	const checkData = ['热门', '新闻', '活动', '话题', '最新']
	const isAndroid = /Android/i.test(navigator.userAgent);
	const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
	
	useEffect(() => {
		localStorage.setItem('list', JSON.stringify(list))
		localStorage.setItem('topsId',JSON.stringify(topsId))
	}, [list,topsId])
	const getType = () => {
		if (tabIndex === 0) {
			return 3
		} else if (tabIndex === 1) {
			return 4
		} else if (tabIndex === 2) {
			return 5
		} else if (tabIndex === 4) {
			return 6
		}
	}
	const contentRef = useRef(null)
	useEffect(() => {
		if (list.length > 0 && contentRef.current){
			if ( contentRef.current.querySelectorAll('video').length > 0) {
				const videos = contentRef.current.querySelectorAll('video');
				videos.forEach((video, index) => {
					video.addEventListener('play', (e) => {
						if (videos.length > 1 ) {
							videos.forEach(i => {
								if (i !== e.target){
									i.pause()
								}
							})
						}else {
							e.target.play();
						}
						
					});
				});
				
				return () => {
					videos.forEach(video => {
						video.removeEventListener('play', () => {
							// handleVideoClick(video)
						});
					});
				};
			}
		}
	}, [contentRef,list.length]);
	useEffect(() => {
		if (!getToken() && !isAndroid && !isiOS) {
			navigate('/community/com_login', {replace: true})
			setPage(0)
			setTotal(0)
			return;
		}
		if (list.length === 0 && localList === '[]' && getToken()) {
			getList()
		}
	}, [getToken(), refresh, tabIndex, localList, type])
	
	useEffect(() => {
		if (!getToken()){
			return;
		}
		if (tabIndex === 3) {
			getTopicList().then(res => {
				if (res.data.isSucc) {
					setTopicList(res.data.res.list)
				}
			})
		}
	}, [tabIndex,getToken()])
	
	function getList() {
		if (tabIndex !== 3) {
			forumGetList({type: getType(), page: page, limit: 10}).then(res => {
				if (res.data.isSucc) {
					if (res.data.res.tops && Array.isArray(res.data.res.tops)) {
						setTopsId(res.data.res.tops.map(item => item.id))
					}else {
						setTopsId([res.data.res.tops.id])
					}
					if (page === 0) {
						setTotal(res.data.res.length)
						
					}
					localStorage.setItem('total', String(res.data.res.length))
					if (page > 0) {
						setList(value => [...value, ...res.data.res.list])
					} else {
						setList(res.data.res.list)
					}
					
				} else {
					setPage(0)
					setTotal(0)
					
				}
			}).catch(err => {
			
			})
		}
	}
	
	const onRefresh = useCallback(() => {
		if (page === 0) {
			getList()
		}
	}, [])
	function loadMore() {
		if (page * 10 > total) {
			return;
		}
		if (list.length < total) {
			let nextPage = page + 1
			setPage(nextPage)
			localStorage.setItem('page', String(nextPage))
			
			return getList()
		}
	}
	
	const statusRecord = {
		pulling: '下拉刷新',
		canRelease: <SpinLoading color={"#7A57EB"} style={{'--size': '1rem'}}/>,
		refreshing: <SpinLoading color={"#7A57EB"} style={{'--size': '1rem'}}/>,
		complete: '刷新成功',
	}
	const logout = () => {
		Dialog.confirm({
			content: '是否退出登录',
			cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
			confirmText:<div style={{fontSize:16,color:"#7A57EB"}}>确定</div>,
			onConfirm: () => {
				clearToken()
				// localStorage.removeItem('page')
				localStorage.clear()
				// localStorage.setItem('list', '[]')
				navigate('/community/com_login', {replace: true})
			},
		})
		
	}
	return (
		
		<div style={{paddingBottom: "2rem", minHeight: height}}>
			<CheckTab defaultIndex={tabIndex} data={checkData} style={{position: "fixed", top: "3rem",marginTop:29,width: '100%'}}
			          callback={(index) => {
						  if (tabIndex === index) {
							  return;
						  }
				          localStorage.setItem('com_tab_index',String(index))
				          setTabIndex(index);
				          setPage(0)
				          setTotal(1)
				          setList([])
				          window.scrollTo(0, 0)
				          localStorage.setItem('list', '[]')
			          }}/>
			
			
			<div  style={{marginTop: "7.65rem"}}>
				<PullToRefresh renderText={status => {
					return <div>{statusRecord[status]}</div>
				}} onRefresh={() => {
					setPage(0)
					setTotal(1)
					setList([])
					localStorage.setItem('list', '[]')
					setRefresh(!refresh)
				}}>
					{
						tabIndex === 3 ?
							<div style={{marginLeft: "1rem", marginTop: "1rem"}}>
								{
									topicList.map((item, index) => {
										return (
											<VoteContainer
												onClick={() => navigate('/community/vote_detail/' + item.title)}
												img={item.banner ? item.banner : DefaultBanner} key={index}>
												<VoteTitle>#{item.title}</VoteTitle>
											</VoteContainer>
										)
									})
								}
							</div> : <div ref={contentRef} >
								{list.map((item, index) => {
									return (
										<ForumItem type={0} topsId={topsId} onRefresh={onRefresh} data={item} key={index}/>
									)
								})}
							</div>
					}
				
				
				</PullToRefresh>
				{(!isAndroid && !isiOS) &&
				<FlexBox onClick={logout} style={{
					position: "fixed",
					bottom: "5rem",
					right: "1.625rem",
					width: "2.625rem",
					height: "2.625rem",
					borderRadius: "2.625rem",
					zIndex: 6, background: "#7A57EB",
					cursor:'pointer'
				}}>
					<img style={{width: "1.5rem", height: "1.5rem"}}
					     src={require('../../../assets/images/forum/exit_com_icon.png')} alt=""/>
				</FlexBox>}
				{tabIndex !== 3 &&
				<img
					style={{
						position: "fixed",
						bottom: "1.5rem",
						right: "1.5rem",
						width: "3rem",
						height: "3rem",
						borderRadius: "3rem",
						zIndex: 6,
						cursor:'pointer'
					}}
					onClick={() => navigate('/community/topic/post')}
					src={require("../../../assets/images/forum/lt_btn_new_post.png")}
					alt=''
				/>}
				{(userInfo.server_leve > 0 && tabIndex === 3) &&
				<img
					style={{
						position: "fixed",
						bottom: "1.5rem",
						right: "1.5rem",
						width: "3rem",
						height: "3rem",
						borderRadius: "3rem",
						zIndex: 6,
						cursor:'pointer'
					}}
					src={require("../../../assets/images/forum/lt_btn_new_huati.png")}
					onClick={() => navigate('/community/topic/vote')}
					alt={''}
				/>}
				{tabIndex !== 3 && <InfiniteScroll loadMore={loadMore} threshold={50} hasMore={list.length < total }/>}
			</div>
		
		</div>
	
	)
}
export default Forum
