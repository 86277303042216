import React from 'react';
import styled from "styled-components";
import {Outlet} from "react-router";
import Vconsole from 'vconsole'
// 所有环境均使用

const Container = styled.div`
`

const Help = () => {
	// new Vconsole()
	return (
		<Container>
			<Outlet/>
		</Container>
	);
};

export default Help;
