import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import React, {useRef} from "react";
import Uploader from "../../../../commen/Uploader";
import upload from "../../../../utils/upload";
import {getStrUrl, getVideoSrc} from "../../../../utils";
import {Toast} from "antd-mobile";
import Video from './Video'
import CustomImageBlot from "./Image";
import {checkImage} from "../../../../services";
import {useIsPermission} from "../../../../hooks";


const Quill = require('quill');
Quill.register( Video);
Quill.register(CustomImageBlot)
const CustomToolbar = ({callback,callVideo,type}) => {
	const fileInputRef = useRef(null);
	const {isPermission,setIsPermission} = useIsPermission()
	const handleFileInputChange = (e) => {
		// window.location.href = "uniwebview://useCamera"
		// if (isPermission === 0) {
		// 	return Toast.show({icon:'fail',content:'没有相机与相册权限,请前往系统设置授权本应用权限'})
		// }
		const file = e.target.files[0];
		if (file) {
			// 处理上传逻辑
			callVideo(file);
		}
	};
	
	const handleButtonClick = (e) => {
		if (isPermission === 0) {
			e.preventDefault()
			Toast.show({icon:'fail',content:'没有相机与相册权限,请前往应用权限设置授权'})
			return setTimeout(() => {
				window.location.href = "uniwebview://useCamera"
			},1500);
		}
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};
	return (
		<div style={{display:"flex",justifyContent:'flex-start',alignItems:"center",flexWrap:"wrap"}} id="toolbar">
			<select  style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}}  defaultValue={"#000000"} className="ql-background">
				<option value="#000000"/>
				<option value="red"/>
				<option value="green"/>
				<option value="blue"/>
				<option value="orange"/>
				<option value="violet"/>
				<option value="#d0d1d2"/>
			</select>
			<select style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} defaultValue={'#000000'} className="ql-color">
				<option value="#000000"/>
				<option value="red"/>
				<option value="green"/>
				<option value="blue"/>
				<option value="orange"/>
				<option value="violet"/>
				<option value="#d0d1d2"/>
			</select>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-bold"/>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-italic"/>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-align" />
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-align" value={'center'}/>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-align" value={'right'}/>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-indent" value={'+1'}/>
			<button style={{width:"2rem",height:"2rem",margin:"0 0.25rem"}} className="ql-link"/>
			{type === 'post' &&
			<button style={{marginRight:"1rem",marginBottom:"0.625rem"}}>
				<Uploader accept={'image/*'} id={'upload-img'} style={{width:"1.75rem",height:"1.75rem"}} onSuccess={(file) => {
					callback(file)}}>
					<img style={{width:"1.75rem",height:"1.75rem"}} src={require('../../../../assets/images/base/upload_img_icon.png')} alt=""/>
				</Uploader>
			</button>}
			{type === 'post' &&
			<input
				type="file"
				accept="video/*"
				style={{ display: 'none' }}
				onChange={handleFileInputChange}
				ref={fileInputRef}
			/>}
			{type === 'post' &&
			<button style={{marginBottom:"0.55rem"}}   className={'video2'} onClick={(e) => handleButtonClick(e)}>
				<img style={{width:"1.75rem",height:"1.75rem",}} src={require('../../../../assets/images/base/video.png')} alt=""/>
			</button>}
		</div>
	);
}



class Editor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {editorHtml: ""};
		this.quillRef = null; // 用于存储 Quill 实例的引用
		this.handleChange = this.handleChange.bind(this);
		this.handleUploadImage = this.handleUploadImage.bind(this);
		this.handleUploadVideo = this.handleUploadVideo.bind(this)
	}
	componentDidMount() {
		const snow1 = document.getElementsByClassName('ql-snow')[0]
		const snow2 = document.getElementsByClassName('ql-snow')[1]
		snow1.style.border = '1px solid #eeeeee'
		snow2.style.border = '1px solid #eeeeee'
		snow1.style.borderTopLeftRadius = '0.5rem'
		snow1.style.borderTopRightRadius = '0.5rem'
		snow2.style.borderBottomLeftRadius = '0.5rem'
		snow2.style.borderBottomRightRadius = '0.5rem'
		snow2.style.minHeight = '10rem'
	}
	
	handleUploadImage = async (file) => {
		if (!file.type.includes('image')){
			return  Toast.show({
				icon: 'fail',
				content: '格式错误,请上传图片文件',
			})
		}
		Toast.show({
			icon: 'loading',
			content: '上传中...',
			duration: 5000
		})
		
		// 将文件上传到服务器并获取图片 URL
		const imageUrl = await upload(file,(url) => {},'img');
		if (imageUrl.url !== '') {
			let res = await checkImage({url:imageUrl.url})
			if (res.data.isSucc){
				if (res.data.res.jude){
					Toast.show({
						icon: 'success',
						content: '上传成功',
					})
				}else{
					 return Toast.show({
						icon: 'fail',
						content: '图片带有违规信息',
					})
				}
				
			}else {
				return Toast.show({
					icon: 'fail',
					content: '校验失败',
				})
			}
			
		}else {
			return Toast.show({
				icon: 'error',
				content: imageUrl.state,
			})
		}
		if (this.quillRef) {
			const editor = this.quillRef.getEditor();
			const index = editor.getLength();  // 获取当前编辑器内容长度
			editor.insertEmbed(index, "image", imageUrl.url,'user');  // 在末尾位置插入图片
			// 获取插入的图片元素
			editor.setSelection(index+1)
		}
	};
	
	
	//上传视频
	handleUploadVideo = async (file) => {
		if (!file.type.includes('video')){
			return  Toast.show({
				icon: 'fail',
				content: '格式错误,请上传视频文件',
			})
		}
		// 压缩图片函数
		function compressImage(imageDataUrl, maxWidth, maxHeight) {
			return new Promise((resolve, reject) => {
				let image = new Image();
				image.onload = function() {
					const canvas = document.createElement("canvas");
					let width = image.width;
					let height = image.height;
					
					// 计算目标尺寸
					if (width > maxWidth) {
						height *= maxWidth / width;
						width = maxWidth;
					}
					if (height > maxHeight) {
						width *= maxHeight / height;
						height = maxHeight;
					}
					
					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(image, 0, 0, width, height);
					
					canvas.toBlob(
						function(blob) {
							resolve(blob);
						},
						"image/jpeg",
						0.25 // 图片质量，可根据需要调整
					);
				};
				
				image.onerror = function(error) {
					reject(error);
				};
				
				image.src = imageDataUrl;
			});
		}
		Toast.show({
			icon: 'loading',
			content: '上传中...',
			duration: 0
		})
		// 将文件上传到服务器并获取图片 URL
		const videoUrl = await upload(file,(url) => {},'video');
		if (videoUrl.url) {
			const res = await new Promise((resolve,reject) => {
				let video = document.createElement("video");
				video.src = videoUrl.url
				video.crossOrigin = 'anonymous';
				video.currentTime = 1;
				video.addEventListener('canplay',async () => {
					let canvas = document.createElement("canvas");
					canvas.width = video.videoWidth
					canvas.height = video.videoHeight
					canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
					let dataUrl = canvas.toDataURL("image/png");
					video.remove();
					canvas.remove();
					video.pause();
					// 压缩图片
					const compressedBlob = await compressImage(dataUrl, video.videoWidth, video.videoHeight); // 指定压缩后的最大宽度和最大高度
					const reader = new FileReader();
					reader.onloadend = function() {
						resolve({ dataUrl: reader.result, width: video.videoWidth, height: video.videoHeight });
					};
					reader.onerror = function(error) {
						reject(error);
					};
					reader.readAsDataURL(compressedBlob);
				})
			})
			if (res){
				if (this.quillRef) {
					const quill = this.quillRef.getEditor();
					if (quill) {
						const range = quill.getSelection();
						quill.insertEmbed(range?range.index:0, 'video', {
							url:videoUrl.url,
							poster:res.dataUrl
						}); // 在光标位置插入视频
					}
					
				}
			}
			
			Toast.show({
				icon: 'success',
				content: '上传成功',
			})
		}else {
			return Toast.show({
				icon: 'error',
				content: videoUrl.state,
			})
		}
	}
	
	handleChange(html) {
		this.setState({editorHtml: html});
		
		const regex = /<p[^>]*>(https?:\/\/\S+)<\/p>/g;
		const replacedText = html.replace(regex, '<a href="$1" target="_blank">$1</a>');
		const urlArray = getStrUrl(html)
		this.props.callback({html:replacedText,url:urlArray})
	}
	
	
	render() {
		return (
			<div  className="text-editor">
				<CustomToolbar type={this.props.type} callVideo={(file) => {this.handleUploadVideo(file);}} callback={(file) => {this.handleUploadImage(file)}}/>
				<ReactQuill
					ref={(ref) => {this.quillRef = ref}}
					style={{minHeight:"10rem",borderRadius:"0.25rem"}}
					onChange={this.handleChange}
					placeholder={this.props.placeholder}
					modules={Editor.modules}
					formats={Editor.formats}
				/>
			</div>
		);
	}
}


Editor.modules = {
	toolbar: {
		container: "#toolbar",
		handlers: {
			video2: Editor.prototype.handleUploadVideo,
		}
	},
	clipboard: {
		matchVisual: false,
	}
};




Editor.formats = [
	"header",
	"font",
	"size",
	"bold",
	"background",
	"italic",
	"underline",
	"align",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
	"color",
	"align-center",
	"video",
	"video2"
];

export default Editor
