const tokenKey = 'xk_forum_token'

export const setFToken = (token) => {
	localStorage.setItem(tokenKey, token)
}

export const getFToken = () => {
	const token = localStorage.getItem(tokenKey)
	return token ? token : ''
}

export const clearFToken = () => {
	localStorage.removeItem(tokenKey)
}
