import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import CheckTab from "../../community/forum/components/CheckTab";
import {useWindowSize} from "../../../utils/useWindowSize";
import {coinLogs} from "../../../services";
import ReItem from "./components/ReItem";
import GiftItem from "./components/GiftItem";
import NoData from "./components/NoData";
import {useNavigate} from "react-router";
import {beansTotal, getWalletInfo, giftEarningDetails, transactionDetails} from "../../../services/wallet";
import {getDayStartAndEnd, getMonthStartAndEnd} from "../../../utils";
import {Toast} from "antd-mobile";

const Container = styled.div`
	//padding-top: 5rem;
	overflow: hidden;
`
const WBox = styled.div`
	width: 20.5rem;
	background-color: #FFFFFF;
	padding: 1rem;
	margin-top: 0.5rem;
`
const Btn = styled.div`
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 4.5rem;
    height: 1.75rem;
    border-radius: 1.15rem;
    font-size: 1rem;
    text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Title = styled.div`
    font-size: 1rem;
    color: #091023;
	font-weight: bold;
`
const CountBox = styled.div`
	width: 20.5rem;
	height: 3.13rem;
    border: 1px solid #F8F8F8;
	border-radius: 0.75rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0.5rem;
`
const CountIcon = styled.img`
    width: 2rem;
    height: 2rem;
	object-fit: cover;
	margin-left: 0.25rem;
`
const Count = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
	margin-left: 0.5rem;
`
const TipsText = styled.div`
    font-size: 0.81rem;
    color: #181818;
	font-weight: bold;
`
const MoreText = styled.div`
    font-size: 0.81rem;
    color: #7A57EB;
`
const ListIcon = styled.img`
    width: 1rem;
    height: 1rem;
	object-fit: cover;
`
const ListText = styled.div`
    font-size:0.875rem;
    color: #181818;
`
const Wallet = () => {
	const [tabIndex, setTabIndex] = useState( 0)
	const [list, setList] = useState([])
	const [data, setData] = useState({})
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [beansCount, setBeansCount] = useState(0)
	const {height} = useWindowSize()
	useEffect(() => {
		beansTotal({start:getDayStartAndEnd(new Date()),end:getDayStartAndEnd(new Date())}).then(res => {
			if (res.data.code === 0) {
				setBeansCount(res.data.data.total?res.data.data.total:0)
			}
		})
	},[])
	
	useEffect(() => {
		getWalletInfo().then(res => {
			if (res.data.code === 0) {
				setData(res.data.data)
			}
		})
		if (tabIndex === 0){
			transactionDetails({page:page,limit:30}).then(res => {
				if (res.data.code === 0) {
					setList(res.data.data.list)
					setTotal(res.data.data.total)
				}
				
			})
		}else {
			const [dayStart, dayEnd] = getDayStartAndEnd(new Date())
			giftEarningDetails({page:page,limit:1000,start:dayStart,end:dayEnd}).then(res => {
				if (res.data.code === 0) {
					setList(res.data.data.list)
					setTotal(res.data.data.total)
				}
			})
		}
		
		
	}, [tabIndex])
	const checkData = ['收支明细', '礼物明细']
	const navigate = useNavigate()
	const isAndroid = /Android/i.test(navigator.userAgent);
	const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
    return (
        <Container style={{maxHeight: height}}>
	        <WBox style={{marginTop:"5rem"}}>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>我的点卡</Title>
			        <Btn onClick={() => navigate('/mine/coin_exchange',{state:{data}})}>兑换</Btn>
		        </FlexBox>
		        <CountBox>
			        <CountIcon src={require('../../../assets/images/mine/gr_icon_dianka_l.png')}/>
			        <Count>{data.cardBalance}</Count>
		        </CountBox>
	        </WBox>
	        <WBox>
		        <FlexBox style={{justifyContent:"space-between"}}>
			        <Title>我的金豆</Title>
			        <Btn onClick={() => {
						if (!isAndroid && !isiOS) {
					        return Toast.show({icon: 'fail', content: '请在APP内充值'})
				        }
				        navigate('/mine/gb_recharge',{state:{data}})
			        }}>充值</Btn>
		        </FlexBox>
		        <CountBox>
			        <CountIcon src={require('../../../assets/images/mine/gr_icon_jindou_l.png')}/>
			        <Count>{data.beansBalance}</Count>
		        </CountBox>
	        </WBox>
	        <CheckTab style={{justifyContent:"center",width:"20.5rem",marginTop:"0.5rem"}} itemStyle={{margin:"0 3rem"}} data={checkData} defaultIndex={tabIndex} callback={(index) => setTabIndex(index)}/>
	        <WBox style={{marginTop:0}}>
		        <FlexBox style={{justifyContent:"space-between",paddingBottom:"0.5rem",borderBottom:"1px solid #F8F8F8"}}>
			        <FlexBox style={{justifyContent:"flex-start"}}>
				        <TipsText>{tabIndex ===0? "近期30条收支明细" :"今日礼物明细:"}</TipsText>
				        {
							tabIndex === 1 &&
							<FlexBox style={{justifyContent:"flex-start"}}>
								<ListIcon src={require('../../../assets/images/mine/gr_icon_jindou_l.png')}/>
								<ListText >{beansCount}</ListText>
							</FlexBox>
				        }
				        
			        </FlexBox>
			       
			        <MoreText onClick={() => {tabIndex === 0?navigate('/mine/re_details'):navigate('/mine/gift_details')}}>查看更多</MoreText>
		        </FlexBox>
		        {
					tabIndex === 0 &&
						<div style={{height:"19rem",paddingBottom:"2rem",overflowY:"scroll"}}>
							{
								list.map((item, index) => {
									return (
										<ReItem   data={item} key={index}/>
									)
								})
							}
							{
								list.length === 0 && <NoData text={'没有任何收支记录'}/>
							}
						</div>
		        }
		        {
			        tabIndex === 1 &&
			        <div style={{height:"19rem",paddingBottom:"2rem",overflowY:"hidden"}}>
				        {
					        list.map((item, index) => {
						        return (
							        <GiftItem data={item}  key={index}/>
						        )
					        })
				        }
			        {
				        list.length === 0 && <NoData text={'今天没有礼物收益哦'}/>
			        }
			        </div>
		        }
	        </WBox>
		</Container>
    );
};

export default Wallet;
