import React from 'react';
import styled from "styled-components";
import YJSImg from "../../../../assets/images/exchangelogs/wzz_icon_send.png"
import RefundFailed from "../../../../assets/images/exchangelogs/wzz_icon_send_tip_4.png"
import Refunding from "../../../../assets/images/exchangelogs/wzz_icon_send_tip_3.png"
import Refunded from "../../../../assets/images/exchangelogs/wzz_icon_send_tip_2.png"
import FlexBox from "../../../../commen/FlexBox";
import {copyText, formatDate} from "../../../../utils";
import {Dialog, Popover, Toast} from "antd-mobile";
import {postSetTop} from "../../../../services/forum";
import {backCoverShop} from "../../../../services";
import {useRefresh} from "../../../../hooks";

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 21.208rem;
    height: 6.604rem;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 0.86rem;
	margin-bottom: 0.5rem;
`
const CarBg = styled.div`

`
const CarImage = styled.img`
    width: 6.21rem;
    height: 4.173rem;
    border-radius: 0.93rem;
    object-fit: cover;
`
const Title = styled.div`
    font-size: 0.876rem;
    color: #091023;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
`

const MkIcon = styled.img`
    width: 1.163rem;
    height: 1.01rem;
    object-fit: cover;
`
const MCount = styled.div`
    font-size: 0.81rem;
    color: #091023;
    margin-left: 0.5rem;
`
const Time = styled.div`
    font-size: 0.5rem;
    color: #828388;
    margin-left: 0.74rem;
    margin-top: 0.25rem;
`

const YJSIcon = styled.img`
    width: 2.583rem;
    height: 2.666rem;
    position: absolute;
    bottom: 0.56rem;
    right: 0.33rem;

`
const NumberText = styled.div`
    font-size: 0.5rem;
    color: #828388;
    margin-left: 0.74rem;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	line-height: 0.75rem;
`
const TkBtn = styled.div`
    width: 3.35rem;
    height: 1.52rem;
    background: #7A57EB;
    border-radius: 0.72rem;
    font-size: 0.65rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left:0.5rem;position: absolute;
    bottom: 1rem;
    right:1rem;
	
    :hover {
        filter: opacity(0.8);
    }
`
const ELItem = ({data}) => {
	const {refresh, setRefresh} = useRefresh()
	
	let pressTimer;
// 长按开始时触发的函数
	function startLongPress() {
		pressTimer = setTimeout(function () {
			// 这里是长按事件触发后要执行的代码
			copyText(data.kd_order_id)
		}, 1000); // 长按的时间阈值，单位为毫秒（这里设置为1秒）
	}

// 长按结束时触发的函数
	function endLongPress() {
		clearTimeout(pressTimer);
	}
	const refund = () => {
		Dialog.confirm({
			cancelText:<div style={{fontSize:16,color:"#B3B3B3"}}>取消</div>,
			confirmText:<div style={{fontSize:16,color:"#7A57EB"}}>确定</div>,
			content: '是否需要退款?',
			onConfirm: () => {
				backCoverShop({orderId:data.orderId}).then(res => {
					if (res.data.isSucc) {
						setRefresh(!refresh)
						Toast.show({icon: 'success', content: '已提交退款申请'})
					} else {
						Toast.show({icon: 'fail', content: '退款失败'})
					}
				}).catch(err => {
				})
			},
		})
	}
	
	return (
		<Container>
			<CarBg style={{marginLeft: "1.25rem"}}>
				<CarImage src={data.icon}/>
			</CarBg>
			<FlexBox style={{flexDirection: "column", alignItems: "flex-start",justifyContent:"flex-start",height:"4.173rem"}}>
				<div style={{marginLeft: "0.74rem", marginBottom: '0.25rem'}}>
					<Title>{data.titile}</Title>
					<FlexBox style={{justifyContent: 'flex-start', marginTop: "0.25rem"}}>
						<MkIcon src={require('../../../../assets/images/exchangelogs/wzz_icon_kCoin_1.png')}/>
						<MCount>{data.change}</MCount>
					</FlexBox>
				</div>
				{data.kd_order_id &&
				<Popover
					mode='dark'
					style={{textAlign: "center"}}
					content='长按单号可复制'
					placement='top'
					trigger='click'
				>
					<NumberText onTouchStart={startLongPress}
					            onTouchEnd={endLongPress}>快递单号：{data.kd_order_id}</NumberText>
				</Popover>}
				
				<Time>{formatDate(data.time)}</Time>
			</FlexBox>
			{data.state === 0 && <TkBtn onClick={refund}>退款</TkBtn>}
			{data.state === 1 && <YJSIcon src={YJSImg}/>}
			{data.state === 2 && <YJSIcon src={RefundFailed}/>}
			{data.state === 3 && <YJSIcon src={Refunding}/>}
			{data.state === 4 && <YJSIcon src={Refunded}/>}
		</Container>
	);
};

export default ELItem;
