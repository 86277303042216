import React, {useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {checkIdNumber} from "../../../utils";
import {Toast} from "antd-mobile";
import {readNameAuth, readNameInfo} from "../../../services";
import {useLocation} from "react-router-dom";

const Container = styled.div`
    padding-top: 5.4rem;
`
const ItemContainer = styled.div`
    background-color: #FFFFFF;
    padding: 0.5rem 1.04rem;
    height: 2.5rem;
    display: flex;
    width: 20.5rem;
    position: relative;
`
const Input = styled.input`
    background-color: #FFFFFF;
    border: none;
    outline: none;
    font-size: 1rem;
    flex: 1;
`
const Title = styled.div`
    font-size: 1rem;
    color: #020C18;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
`
const Btn = styled.div`
    background-color: #7A57EB;
    border: none;
    color: #FFFFFF;
    width: 18.043rem;
    height: 2.583rem;
    border-radius: 1.3rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.25rem;
	margin-top: 5rem;
`
const SuccessImg = styled.img`
    width: 7rem;
    height: 7rem;
	margin-top: 3.48rem;
`
const InfoBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #FFFFFF;
	flex-direction: column;
    padding: 0.75rem;
    width: 19rem;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
	margin-top: 2.27rem;
`
const Text = styled.div`
    font-size: 0.875rem;
    color: #828388;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 1; //行数
    line-clamp: 1;
    -webkit-box-orient: vertical;
	max-width: 17rem;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ServiceIcon = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 2.5rem;
	object-fit: cover;
	position: fixed;
	right: 1rem;
	bottom: 3rem;
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
`
const Tips = styled.div`
	font-size: 0.75rem;
	color: #000000;
	font-weight: bold;
	text-align: center;
	position: fixed;
	bottom: 0.5rem;
	width:100%;
`
const Certification = () => {
	const {state:{info}} =useLocation()
	
	const [name, setName] = useState('')
	const [idNumber, setIdNumber] = useState('')
	const [isCheck,setIsCheck] = useState(info.auth?info.auth:false)
	const [idInfo,setIdInfo] = useState(info?info:{})
	
	const submit = () => {
		if (name === ''){
			return Toast.show({icon:'fail',content:"请输入身份证上的姓名"})
		}
		if (idNumber === ''){
			return Toast.show({icon:'fail',content:"请输入身份证号码"})
		}
		if (!checkIdNumber(idNumber)){
			return Toast.show({icon:'fail',content:"请输入正确的身份证号"})
		}
		readNameAuth({name:name,idcard:idNumber}).then(res => {
			if (res.data.isSucc){
				setIsCheck(true)
				readNameInfo({}).then(res => {
					if (res.data.isSucc){
						setIdInfo(res.data.res)
					}
				})
				return Toast.show({icon:'success',content:"认证成功"})
			}else {
				return Toast.show({icon:'fail',content:"认证失败"})
			}
		})
	}
	return (
		<Container>
			{!isCheck?
			<div>
				<Title>真实姓名</Title>
				<ItemContainer>
					<Input maxLength={10} value={name} onChange={(e) => setName(e.target.value)} placeholder={'请填写身份证上的姓名'}
					       type={'tel'}/>
				</ItemContainer>
				<Title style={{marginTop: "1.75rem"}}>身份证号</Title>
				<ItemContainer>
					<Input maxLength={18} value={idNumber} onChange={(e) => setIdNumber(e.target.value)}
					       placeholder={'请填写真实的身份证号码'} type={'tel'}/>
				</ItemContainer>
				<Btn onClick={submit}>确认认证</Btn>
			</div>:
			<FlexBox style={{flexDirection:'column'}}>
				<SuccessImg src={require('../../../assets/images/mine/gr_icon_shiming_success.png')}/>
				<Title style={{marginTop: "2.08rem"}}>实名认证成功</Title>
				<InfoBox>
					<FlexBox style={{justifyContent:"flex-start"}}>
						<Text>真实姓名：</Text>
						<Text style={{marginLeft:'0.5rem'}}>{idInfo.name}</Text>
					</FlexBox>
					<FlexBox style={{justifyContent:"flex-start"}}>
						<Text>证件类型：</Text>
						<Text style={{marginLeft:'0.5rem'}}>身份证</Text>
					</FlexBox>
					<FlexBox style={{justifyContent:"flex-start"}}>
						<Text>身份证号：</Text>
						<Text style={{marginLeft:'0.5rem'}}>{idInfo.id}</Text>
					</FlexBox>
				</InfoBox>
			</FlexBox>}
				<ServiceIcon onClick={() => {window.location.href = "uniwebview://openServiceModal"}} >
					<img style={{height:"1.75rem",width:"1.75rem"}}  src={require('../../../assets/images/mine/wzz_icon_service.png')} alt=""/>
				</ServiceIcon>
			<Tips>您遇到问题或需要帮助请联系客服</Tips>
		</Container>
	);
};

export default Certification;
