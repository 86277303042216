import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const Container = styled.div`
    width: 20.5rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #F3F3F3;
	background-color: #FFFFFF;
`
const Item = styled.div`
	padding: 0.25rem 0;
	margin:0 0.5rem ;
	font-size: 1rem;
	border-bottom:${props => props.active === 'active' ? '0.15rem solid #7A57EB':''};
`
let index
const CheckTab = ({callback,style,data,itemStyle,defaultIndex}) => {
	const [checkIndex,setCheckIndex] = useState(defaultIndex?defaultIndex:(index?index:0))
	useEffect(() => {
		if (callback){
			callback(checkIndex)
		}
		index = checkIndex
		
	},[checkIndex])
    return (
        <Container style={{zIndex:100,...style}}>
	        {data.map((item,index) => {
				return (
					<Item style={{...itemStyle}} key={index} active={checkIndex === index ? 'active':''} onClick={() => {setCheckIndex(index)}}>{item}</Item>
				)
	        })}
        </Container>
    );
};

export default CheckTab;
