import Emj1 from "../assets/images/emote/emj_001.png"
import Emj2 from "../assets/images/emote/emj_002.png"
import Emj3 from "../assets/images/emote/emj_003.png"
import Emj4 from "../assets/images/emote/emj_004.png"
import Emj5 from "../assets/images/emote/emj_005.png"
import Emj6 from "../assets/images/emote/emj_006.png"
import Emj7 from "../assets/images/emote/emj_007.png"
import Emj8 from "../assets/images/emote/emj_008.png"
import Emj9 from "../assets/images/emote/emj_009.png"
import Emj10 from "../assets/images/emote/emj_010.png"
import Emj11 from "../assets/images/emote/emj_011.png"
import Emj12 from "../assets/images/emote/emj_012.png"
import Emj13 from "../assets/images/emote/emj_013.png"
import Emj14 from "../assets/images/emote/emj_014.png"
import Emj15 from "../assets/images/emote/emj_015.png"
import Emj16 from "../assets/images/emote/emj_016.png"
import Emj17 from "../assets/images/emote/emj_017.png"
import Emj18 from "../assets/images/emote/emj_018.png"
import Emj19 from "../assets/images/emote/emj_019.png"
import Emj20 from "../assets/images/emote/emj_020.png"
import Emj21 from "../assets/images/emote/emj_021.png"
import Emj22 from "../assets/images/emote/emj_022.png"
import Emj23 from "../assets/images/emote/emj_023.png"
import Emj24 from "../assets/images/emote/emj_024.png"
import Emj25 from "../assets/images/emote/emj_025.png"
import Emj26 from "../assets/images/emote/emj_026.png"
import Emj27 from "../assets/images/emote/emj_027.png"
import Emj28 from "../assets/images/emote/emj_028.png"
import Emj29 from "../assets/images/emote/emj_029.png"
import Emj30 from "../assets/images/emote/emj_030.png"
import Emj31 from "../assets/images/emote/emj_031.png"
import Emj32 from "../assets/images/emote/emj_032.png"
import Emj33 from "../assets/images/emote/emj_033.png"
import Emj34 from "../assets/images/emote/emj_034.png"
import Emj35 from "../assets/images/emote/emj_035.png"
import Emj36 from "../assets/images/emote/emj_036.png"
import Emj37 from "../assets/images/emote/emj_037.png"
import Emj38 from "../assets/images/emote/emj_038.png"
import Emj39 from "../assets/images/emote/emj_039.png"
import Emj40 from "../assets/images/emote/emj_040.png"
import Emj41 from "../assets/images/emote/emj_041.png"
import Emj42 from "../assets/images/emote/emj_042.png"
import Emj43 from "../assets/images/emote/emj_043.png"
import Emj44 from "../assets/images/emote/emj_044.png"
import Emj45 from "../assets/images/emote/emj_045.png"
import Emj46 from "../assets/images/emote/emj_046.png"
import Emj47 from "../assets/images/emote/emj_047.png"
import Emj48 from "../assets/images/emote/emj_048.png"
import Emj49 from "../assets/images/emote/emj_049.png"
import Emj50 from "../assets/images/emote/emj_050.png"
import Emj51 from "../assets/images/emote/emj_051.png"
import Emj52 from "../assets/images/emote/emj_052.png"
import Emj53 from "../assets/images/emote/emj_053.png"
import Emj54 from "../assets/images/emote/emj_054.png"
import Emj55 from "../assets/images/emote/emj_055.png"
import Emj56 from "../assets/images/emote/emj_056.png"
import Emj57 from "../assets/images/emote/emj_057.png"
import Emj58 from "../assets/images/emote/emj_058.png"
import Emj59 from "../assets/images/emote/emj_059.png"
import Emj60 from "../assets/images/emote/emj_060.png"
import Emj61 from "../assets/images/emote/emj_061.png"
import Emj62 from "../assets/images/emote/emj_062.png"
import Emj63 from "../assets/images/emote/emj_063.png"
import Emj64 from "../assets/images/emote/emj_064.png"
import Emj65 from "../assets/images/emote/emj_065.png"
import Emj66 from "../assets/images/emote/emj_066.png"
import Emj67 from "../assets/images/emote/emj_067.png"
import Emj68 from "../assets/images/emote/emj_068.png"
import Emj69 from "../assets/images/emote/emj_069.png"
import Emj70 from "../assets/images/emote/emj_070.png"
import Emj71 from "../assets/images/emote/emj_071.png"
import Emj72 from "../assets/images/emote/emj_072.png"
import Emj73 from "../assets/images/emote/emj_073.png"
import Emj74 from "../assets/images/emote/emj_074.png"
import Emj75 from "../assets/images/emote/emj_075.png"
import Emj76 from "../assets/images/emote/emj_076.png"
import Emj77 from "../assets/images/emote/emj_077.png"
import Emj78 from "../assets/images/emote/emj_078.png"
import Emj79 from "../assets/images/emote/emj_079.png"
import Emj80 from "../assets/images/emote/emj_080.png"
import Emj81 from "../assets/images/emote/emj_081.png"
import Emj82 from "../assets/images/emote/emj_082.png"
import Emj83 from "../assets/images/emote/emj_083.png"
import Emj84 from "../assets/images/emote/emj_084.png"
import Emj85 from "../assets/images/emote/emj_085.png"
import Emj86 from "../assets/images/emote/emj_086.png"
import Emj87 from "../assets/images/emote/emj_087.png"
import Emj88 from "../assets/images/emote/emj_088.png"
import Emj89 from "../assets/images/emote/emj_089.png"
import Emj90 from "../assets/images/emote/emj_090.png"
import Emj91 from "../assets/images/emote/emj_091.png"
import Emj92 from "../assets/images/emote/emj_092.png"
import Emj93 from "../assets/images/emote/emj_093.png"
import Emj94 from "../assets/images/emote/emj_094.png"
import Emj95 from "../assets/images/emote/emj_095.png"
import Emj96 from "../assets/images/emote/emj_096.png"
import Emj98 from "../assets/images/emote/emj_098.png"
import Emj99 from "../assets/images/emote/emj_099.png"
import Emj100 from "../assets/images/emote/emj_100.png"
import Emj101 from "../assets/images/emote/emj_101.png"
import Emj102 from "../assets/images/emote/emj_102.png"
import Emj103 from "../assets/images/emote/emj_103.png"
import Emj104 from "../assets/images/emote/emj_104.png"
import Emj105 from "../assets/images/emote/emj_105.png"
import Emj106 from "../assets/images/emote/emj_106.png"
import Emj107 from "../assets/images/emote/emj_107.png"
export const getEmote = (name) => {
	switch (name) {
		case 'Emj1':
			return Emj1
		case 'Emj2':
			return Emj2
		case 'Emj3':
			return Emj3
		case 'Emj4':
			return Emj4
		case 'Emj5':
			return Emj5
		case 'Emj6':
			return Emj6
		case 'Emj7':
			return Emj7
		case 'Emj8':
			return Emj8
		case 'Emj9':
			return Emj9
		case 'Emj10':
			return Emj10
		case 'Emj11':
			return Emj11
		case 'Emj12':
			return Emj12
		case 'Emj13':
			return Emj13
		case 'Emj14':
			return Emj14
		case 'Emj15':
			return Emj15
		case 'Emj16':
			return Emj16
		case 'Emj17':
			return Emj17
		case 'Emj18':
			return Emj18
		case 'Emj19':
			return Emj19
		case 'Emj20':
			return Emj20
		case 'Emj21':
			return Emj21
		case 'Emj22':
			return Emj22
		case 'Emj23':
			return Emj23
		case 'Emj24':
			return Emj24
		case 'Emj25':
			return Emj25
		case 'Emj26':
			return Emj26
		case 'Emj27':
			return Emj27
		case 'Emj28':
			return Emj28
		case 'Emj29':
			return Emj29
		case 'Emj30':
			return Emj30
		case 'Emj31':
			return Emj31
		case 'Emj32':
			return Emj32
		case 'Emj33':
			return Emj33
		case 'Emj34':
			return Emj34
		case 'Emj35':
			return Emj35
		case 'Emj36':
			return Emj36
		case 'Emj37':
			return Emj37
		case 'Emj38':
			return Emj38
		case 'Emj39':
			return Emj39
		case 'Emj40':
			return Emj40
		case 'Emj41':
			return Emj41
		case 'Emj42':
			return Emj42
		case 'Emj43':
			return Emj43
		case 'Emj44':
			return Emj44
		case 'Emj45':
			return Emj45
		case 'Emj46':
			return Emj46
		case 'Emj47':
			return Emj47
		case 'Emj48':
			return Emj48
		case 'Emj49':
			return Emj49
		case 'Emj50':
			return Emj50
		case 'Emj51':
			return Emj51
		case 'Emj52':
			return Emj52
		case 'Emj53':
			return Emj53
		case 'Emj54':
			return Emj54
		case 'Emj55':
			return Emj55
		case 'Emj56':
			return Emj56
		case 'Emj57':
			return Emj57
		case 'Emj58':
			return Emj58
		case 'Emj59':
			return Emj59
		case 'Emj60':
			return Emj60
		case 'Emj61':
			return Emj61
		case 'Emj62':
			return Emj62
		case 'Emj63':
			return Emj63
		case 'Emj64':
			return Emj64
		case 'Emj65':
			return Emj65
		case 'Emj66':
			return Emj66
		case 'Emj67':
			return Emj67
		case 'Emj68':
			return Emj68
		case 'Emj69':
			return Emj69
		case 'Emj70':
			return Emj70
		case 'Emj71':
			return Emj71
		case 'Emj72':
			return Emj72
		case 'Emj73':
			return Emj73
		case 'Emj74':
			return Emj74
		case 'Emj75':
			return Emj75
		case 'Emj76':
			return Emj76
		case 'Emj77':
			return Emj77
		case 'Emj78':
			return Emj78
		case 'Emj79':
			return Emj79
		case 'Emj80':
			return Emj80
		case 'Emj81':
			return Emj81
		case 'Emj82':
			return Emj82
		case 'Emj83':
			return Emj83
		case 'Emj84':
			return Emj84
		case 'Emj85':
			return Emj85
		case 'Emj86':
			return Emj86
		case 'Emj87':
			return Emj87
		case 'Emj88':
			return Emj88
		case 'Emj89':
			return Emj89
		case 'Emj90':
			return Emj90
		case 'Emj91':
			return Emj91
		case 'Emj92':
			return Emj92
		case 'Emj93':
			return Emj93
		case 'Emj94':
			return Emj94
		case 'Emj95':
			return Emj95
		case 'Emj96':
			return Emj96
		case 'Emj98':
			return Emj98
		case 'Emj99':
			return Emj99
		case 'Emj100':
			return Emj100
		case 'Emj101':
			return Emj101
		case 'Emj102':
			return Emj102
		case 'Emj103':
			return Emj103
		case 'Emj104':
			return Emj104
		case 'Emj105':
			return Emj105
		case 'Emj106':
			return Emj106
		case 'Emj107':
			return Emj107
		case '' :
			return ''
	}
}
